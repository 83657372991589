import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateName from "./UpdateName";
import UpdateProfilePic from "./UpdateProfilePic";
import UpdateEmail from "./UpdateEmail";
import VerifyEmail from "./VerifyEmail";
import UpdatePassword from "./UpdatePassword";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

function AccountActions(props) {
  const { user, s3url, setUserState } = props;

  const [toggle, setToggle] = useState("off");

  const [modal, setModal] = useState({
    show: false,
    type: null,
  });

  const [newEmail, setNewEmail] = useState(user.attributes.email);

  const renderModal = () => {
    return (
      <Modal show={modal.show} backdrop={false} contentClassName="bg-dark">
        <Modal.Body className="text-white text-center">
          You have successfully updated your {modal.type}.
        </Modal.Body>
      </Modal>
    );
  };

  const handleBackClick = () => {
    setToggle("off");
  };

  const showToggle = () => {
    switch (toggle) {
      case "name":
        return (
          <UpdateName
            user={user}
            setModal={setModal}
            handleBackClick={handleBackClick}
            setToggle={setToggle}
          />
        );
      case "profilePic":
        return (
          <UpdateProfilePic
            setToggle={setToggle}
            setModal={setModal}
            handleBackClick={handleBackClick}
            s3url={s3url}
            picture={user.attributes.picture}
          />
        );
      case "email":
        return (
          <UpdateEmail
            user={user}
            handleBackClick={handleBackClick}
            setToggle={setToggle}
            setUserState={setUserState}
            newEmail={newEmail}
            setNewEmail={setNewEmail}
          />
        );
      case "verifyEmail":
        return (
          <VerifyEmail
            setToggle={setToggle}
            setModal={setModal}
            newEmail={newEmail}
            setUserState={setUserState}
          />
        );
      case "password":
        return (
          <UpdatePassword
            setToggle={setToggle}
            setModal={setModal}
            handleBackClick={handleBackClick}
          />
        );
      case "forgotPassword":
        return (
          <ForgotPassword
            email={user.attributes.email}
            handleBackClick={handleBackClick}
            setToggle={setToggle}
            username={user.username}
          />
        );
      case "resetPassword":
        return (
          <ResetPassword
            username={user.username}
            setToggle={setToggle}
            setModal={setModal}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {renderModal()}
      {toggle === "off" ? (
        <div className="d-grid">
          {renderModal()}
          <Button variant="action" onClick={() => setToggle("name")}>
            <div className="d-flex justify-content-between align-items-center py-2">
              <div className="text-start">
                <p className="mb-0 fw-bold">Name</p>
                <p className="mb-0">{user.attributes.name}</p>
              </div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Button>
          <hr />
          <Button variant="action" onClick={() => setToggle("profilePic")}>
            <div className="d-flex justify-content-between align-items-center py-2">
              <div className="text-start">
                <p className="mb-0 fw-bold">Profile Picture</p>
                <Image
                  className="mt-2"
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                    border: "3px solid #FFFFFF",
                    width: "5rem",
                    height: "5rem",
                  }}
                  src={`${s3url}${user.attributes.picture}`}
                />
              </div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Button>
          <hr />
          <Button variant="action" onClick={() => setToggle("email")}>
            <div className="d-flex justify-content-between align-items-center py-2">
              <div className="text-start">
                <p className="mb-0 fw-bold">Email</p>
                <p className="mb-0">{user.attributes.email}</p>
              </div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Button>
          <hr />
          <Button variant="action" onClick={() => setToggle("password")}>
            <div className="d-flex justify-content-between align-items-center py-2">
              <div className="text-start">
                <p className="mb-0 fw-bold">Change Password</p>
              </div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Button>
          <hr />
        </div>
      ) : (
        showToggle()
      )}
    </>
  );
}

export default AccountActions;
