import React from "react";
import SpecialFeatureVideo from "./SpecialFeatureVideo";
import SpecialFeatureImage from "./SpecialFeatureImage/SpecialFeatureImage";
import SpecialFeatureText from "./SpecialFeatureText/SpecialFeatureText";

function SpecialFeatures(props) {
  const {
    specialFeatureVideos,
    specialFeatureImages,
    s3url,
    specialFeatureText,
  } = props;

  return (
    <>
      <div className="d-inline-block w-auto mb-3">
        <h1 className="d-inline-block" style={{ color: "#9f1f63" }}>
          Special Features
        </h1>
        <hr
          className="mt-1 mb-0 opacity-100 w-75"
          style={{ color: "#9f1f63", borderTop: "8px solid" }}
        />
      </div>
      <SpecialFeatureVideo specialFeatureVideos={specialFeatureVideos} />
      <SpecialFeatureImage
        specialFeatureImages={specialFeatureImages}
        s3url={s3url}
      />
      <SpecialFeatureText specialFeatureText={specialFeatureText} />
    </>
  );
}

export default SpecialFeatures;
