import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NotificationContext from "../../../../context/NotificationContext";
import Notification from "../../../Notification/Notification";

function UpdatePassword(props) {
  const { setToggle, setModal, handleBackClick } = props;

  const notificationCtx = useContext(NotificationContext);

  const [password, setPassword] = useState({
    old: "",
    new: "",
  });

  const [checkState, setCheckState] = useState({
    old: "password",
    new: "password",
  });

  const [loading, setLoading] = useState(false);

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    const regex =
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

    if (password.new !== "") {
      if (password.new.match(regex)) {
        setInvalid(false);
      } else {
        setInvalid(true);
      }
    } else {
      setInvalid(false);
    }
  }, [password.new]);

  useEffect(() => {
    return () => {
      notificationCtx.clear();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();

    const user = await Auth.currentAuthenticatedUser();

    setLoading(true);

    await Auth.changePassword(user, password.old, password.new)
      .then(() => {
        setToggle("off");
        setModal({
          show: true,
          type: "password",
        });
        setTimeout(() => {
          setModal((prevState) => ({
            ...prevState,
            show: false,
          }));
        }, 2000);
      })
      .catch((e) => {
        const str = e.toString();

        notificationCtx.error(str.substring(str.indexOf(":") + 2));
      });
  };

  const handleChange = (event) => {
    setPassword({
      ...password,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheck = (event) => {
    if (event.target.checked) {
      setCheckState({
        ...checkState,
        [event.target.name]: "text",
      });
    } else {
      setCheckState({
        ...checkState,
        [event.target.name]: "password",
      });
    }
  };

  return (
    <>
      <Notification />
      <Form onSubmit={handlePasswordSubmit}>
        <div className="d-flex justify-content-start align-items-center mb-1">
          <Button variant="action" onClick={handleBackClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Form.Label className="ms-1 mb-0 fw-bold">Change Password</Form.Label>
        </div>
        <Form.Group>
          <div className="d-flex justify-content-start">
            <Form.Label className="fw-bold">Old Password</Form.Label>
          </div>
          <Form.Control
            type={checkState.old}
            name="old"
            onChange={handleChange}
          />
          <div className="d-flex justify-content-between">
            <Form.Check
              className="iris-check"
              type="checkbox"
              label="Show Password"
              name="old"
              onChange={(e) => handleCheck(e)}
            />
            <Form.Text
              as="a"
              onClick={() => setToggle("forgotPassword")}
              className="iris-nav"
            >
              Forgot Password?
            </Form.Text>
          </div>
        </Form.Group>
        <Form.Group className="mt-4">
          <div className="d-flex justify-content-start">
            <Form.Label className="fw-bold">New Password</Form.Label>
          </div>
          <Form.Control
            type={checkState.new}
            name="new"
            onChange={handleChange}
            isInvalid={invalid}
          />
          <Form.Control.Feedback className="text-start" type="invalid">
            Invalid Password
          </Form.Control.Feedback>
          <Form.Check
            className="text-start iris-check"
            type="checkbox"
            label="Show Password"
            name="new"
            onChange={(e) => handleCheck(e)}
          />
          <div className="text-start">
            <Form.Text className="text-secondary">
              Password must be at least 8 characters long, and contain at least
              one uppercase letter, one lowercase character, one number, and one
              special character.
            </Form.Text>
          </div>
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            variant="grad"
            size="lg"
            className="mt-2"
            disabled={
              password.new === "" || password.old === "" || invalid || loading
            }
          >
            {loading ? "Loading..." : "Update"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default UpdatePassword;
