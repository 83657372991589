import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getVodAsset } from "../../../../graphql/queries";
import awsvideo from "../../../../aws-video-exports";
import VideoPlayer from "../../Video/VideoPlayer/VideoPlayer";

function SpecialFeatureVideo(props) {
  const { specialFeatureVideos } = props;

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (specialFeatureVideos) {
      specialFeatureVideos.forEach(async (item) => {
        await API.graphql(
          graphqlOperation(getVodAsset, {
            id: item,
          })
        ).then((res) => {
          const newObject = res.data.getVodAsset;
          newObject.sources = [
            {
              src: `https://${awsvideo.awsOutputVideo}/${newObject.video.id}/${newObject.video.id}.m3u8`,
              type: "application/x-mpegURL",
            },
          ];

          setVideos([...videos, newObject]);
        });
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showSpecialFeatureVideos = () => {
    if (videos.length > 0) {
      return (
        <>
          <h4 className="text-start pb-2">Videos</h4>
          {videos.map((element, index) => {
            const options = {
              controls: true,
              sources: element.sources,
              controlBar: {
                children: {
                  playToggle: true,
                  volumeControl: true,
                  progressControl: true,
                  fullscreenToggle: true,
                },
              },
              bigPlayButton: true,
              fluid: true,
            };

            return (
              <div key={index}>
                <VideoPlayer options={options} />
                <p className="text-start mt-1">{element.title}</p>
              </div>
            );
          })}
        </>
      );
    }
  };
  return <>{showSpecialFeatureVideos()}</>;
}

export default SpecialFeatureVideo;
