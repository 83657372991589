import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  InputGroup,
} from "react-bootstrap";
import NavbarLogo from "../NavBar/NavbarLogo.png";
import NotificationContext from "../../context/NotificationContext";
import Notification from "../Notification/Notification";

function AccessPage(props) {
  const { signIn, signInLoading } = props;

  const [code, setCode] = useState("");

  const notificationCtx = useContext(NotificationContext);

  useEffect(() => {
    return () => {
      notificationCtx.clear();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (code.toUpperCase().trim() === "IRIS2024") {
      signIn(
        process.env.REACT_APP_TEMP_EMAIL,
        process.env.REACT_APP_TEMP_PASSWORD
      );
    } else {
      notificationCtx.error("Incorrect Access Code");
      setCode("");
    }
  };

  return (
    <div className="d-flex min-vh-100 min-vw-100 h-100 ">
      <Container className="my-auto">
        <Row>
          <Col className="mx-auto">
            <Image
              className="mt-0 mb-2"
              src={NavbarLogo}
              style={{ width: "10rem" }}
              alt="Logo"
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-md-4 mx-auto">
            <Notification />
            <Form onSubmit={handleSubmit}>
              <Form.Label className="fs-5">Enter Access Code</Form.Label>
              <InputGroup>
                <Form.Control onChange={handleChange} value={code} />
                <Button
                  type="submit"
                  disabled={code === "" || signInLoading}
                  variant="grad"
                >
                  {signInLoading ? "Loading..." : "Submit"}
                </Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AccessPage;
