/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "a499b69f418c45b7b6e5fc65e5a5db1c",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a499b69f418c45b7b6e5fc65e5a5db1c",
            "region": "us-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "apic5c1ed0b",
            "endpoint": "https://2qyjaq0myf.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://pkazwrikrbahpgx72bnkba6xsq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:883d38c4-b236-4c41-a953-85d5fd435188",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_zirZeDc2m",
    "aws_user_pools_web_client_id": "642h38fr7pjfc0ja3i3e8g4223",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "irisapp83dbb4e4b1244daa93ff44b308b16567124246-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "geo": {
        "amazon_location_service": {
            "region": "us-west-2",
            "maps": {
                "items": {
                    "mapf229c1e2-dev": {
                        "style": "VectorEsriDarkGrayCanvas"
                    }
                },
                "default": "mapf229c1e2-dev"
            },
            "search_indices": {
                "items": [
                    "placeIndexb0934737-dev"
                ],
                "default": "placeIndexb0934737-dev"
            }
        }
    }
};


export default awsmobile;
