import React, { useState, useEffect, useContext } from "react";
import {
  Image,
  Row,
  Col,
  Container,
  Card,
  Form,
  Button,
} from "react-bootstrap";
import NavbarLogo from "../../NavBar/NavbarLogo.png";
import { Auth } from "aws-amplify";

import Notification from "../../Notification/Notification";
import NotificationContext from "../../../context/NotificationContext";

function EmailVerify(props) {
  const { email, signIn } = props;

  const [form, setForm] = useState({
    code: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const notificationCtx = useContext(NotificationContext);

  useEffect(() => {
    return () => {
      notificationCtx.clear();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const verify = async () => {
    setLoading(true);

    await Auth.confirmSignUp(email, form.code)
      .then(async () => {
        await signIn(email, form.password);
      })
      .catch((err) => {
        const str = err.toString();
        notificationCtx.error(str.substring(str.indexOf(":") + 2));
        setLoading(false);
      });
  };

  const signInWithPassword = async () => {
    setLoading(true);
    await signIn(email, form.password);
  };

  const handleLinkClick = async () => {
    await Auth.resendSignUp(email).then(() => {
      setShowMessage(true);
    });
  };

  return (
    <div className="d-flex flex-column min-vh-100 min-vw-100">
      <Container>
        <Row>
          <Col className="mx-auto">
            <Image
              className="mt-0 mb-2"
              src={NavbarLogo}
              style={{ width: "10rem" }}
              alt="Logo"
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-md-5 mx-auto">
            <Card bg="dark" className="text-white">
              <Card.Title className="mt-2">Verify Email Address</Card.Title>
              <Card.Body className="text-start">
                <Card.Text>A verification code was sent to {email}.</Card.Text>
                <Card.Text>Please enter it below.</Card.Text>
                <Notification />
                <Form>
                  <Form.Group>
                    <Form.Label>Code</Form.Label>
                    <Form.Control
                      name="code"
                      onChange={handleChange}
                      value={form.code}
                      placeholder="Enter Code"
                      {...(notificationCtx.notification && {
                        className: "text-secondary",
                      })}
                      disabled={notificationCtx.notification}
                      readOnly={notificationCtx.notification}
                      plaintext={notificationCtx.notification}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Re-Enter Password</Form.Label>
                    <Form.Control
                      name="password"
                      onChange={handleChange}
                      value={form.password}
                      type="password"
                      placeholder="Enter Password"
                    />
                  </Form.Group>
                </Form>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={
                      !notificationCtx.notification
                        ? verify
                        : signInWithPassword
                    }
                    className="mt-2"
                    variant="grad"
                    size="lg"
                    disabled={
                      form.code === "" ||
                      form.code.length !== 6 ||
                      form.password === "" ||
                      loading
                    }
                  >
                    {loading ? "Loading..." : "Verify"}
                  </Button>
                </div>
                <div className="d-flex justify-content-start">
                  <Card.Text className="me-2">
                    Didn't receive the code?
                  </Card.Text>
                  {showMessage ? (
                    <Card.Text>Code Sent!</Card.Text>
                  ) : (
                    <Card.Text
                      as="a"
                      onClick={handleLinkClick}
                      className="iris-nav"
                    >
                      Send New Code
                    </Card.Text>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EmailVerify;
