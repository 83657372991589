import React from "react";
import { Modal } from "react-bootstrap";
import PauseSubscription from "./PauseSubscription";
import CancelSubscription from "./CancelSubscription";
import ChangeSubscription from "./ChangeSubscription";

function ManageSubscriptionModal(props) {
  const { type, show, hideModal, user, setToggle } = props;

  const showModal = () => {
    switch (type) {
      case "pause":
        return <PauseSubscription user={user} setToggle={setToggle} />;
      case "cancel":
        return <CancelSubscription hideModal={hideModal} />;
      case "changePlan":
        return <ChangeSubscription user={user} hideModal={hideModal} />;
      default:
        break;
    }
  };

  return (
    <Modal show={show} onHide={hideModal} contentClassName="bg-dark" centered>
      {showModal()}
    </Modal>
  );
}

export default ManageSubscriptionModal;
