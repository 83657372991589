import React, { useState, useEffect } from "react";
import { Form, Button, Figure } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Auth, Storage } from "aws-amplify";

function UpdateProfilePic(props) {
  const { setToggle, setModal, handleBackClick, s3url, picture } = props;

  const [uploading, setUploading] = useState(false);

  const [imageState, setImageState] = useState({
    file: null,
    fileName: null,
  });

  const [preview, setPreview] = useState(
    "https://via.placeholder.com/150.png?text=New+Pic+Appears+Here"
  );

  useEffect(() => {
    if (imageState.file) {
      const objectUrl = URL.createObjectURL(imageState.file);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [imageState.file]);

  const imageCallback = (event) => {
    if (event.target.files.length > 0) {
      const imageFile = event.target.files[0];

      setImageState({
        file: imageFile,
        fileName: imageFile.name,
      });
      setPreview(imageFile);
    } else if (event.target.files.length === 0) {
      setImageState({
        file: null,
        fileName: null,
      });
      setPreview(
        "https://via.placeholder.com/150.png?text=New+Pic+Appears+Here"
      );
    }
  };

  const handleProfilePicSubmit = async (event) => {
    event.preventDefault();
    setUploading(true);

    const user = await Auth.currentAuthenticatedUser();

    await Storage.put(imageState.fileName, imageState.file).then(() => {
      Auth.updateUserAttributes(user, {
        picture: imageState.fileName,
      }).then(() => {
        setToggle("off");
        setModal({
          show: true,
          type: "profile picture",
        });
        setTimeout(() => {
          setModal((prevState) => ({
            ...prevState,
            show: false,
          }));
        }, 2000);
      });
    });
  };

  return (
    <Form onSubmit={handleProfilePicSubmit}>
      <div className="d-flex justify-content-start align-items-center mb-1">
        <Button onClick={handleBackClick} variant="action">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Form.Label className="mb-0 ms-1 fw-bold">
          Change Profile Picture
        </Form.Label>
      </div>
      <div className="d-flex justify-content-evenly">
        <Figure>
          <Figure.Image
            style={{
              objectFit: "cover",
              borderRadius: "50%",
              border: "6px solid #FFFFFF",
              width: "10rem",
              height: "10rem",
            }}
            src={`${s3url}${picture}`}
          />
          <Figure.Caption className="text-white">Old Picture</Figure.Caption>
        </Figure>
        <Figure>
          <Figure.Image
            style={{
              objectFit: "cover",
              borderRadius: "50%",
              border: "6px solid #FFFFFF",
              width: "10rem",
              height: "10rem",
            }}
            src={preview}
          />
          <Figure.Caption className="text-white">New Picture</Figure.Caption>
        </Figure>
      </div>
      <Form.Control onChange={imageCallback} type="file" accept="image/*" />
      <div className="d-flex justify-content-end">
        <Button
          size="lg"
          type="submit"
          variant="grad"
          className="mt-2"
          disabled={imageState.file === null || uploading}
        >
          {uploading ? "Uploading..." : "Save"}
        </Button>
      </div>
    </Form>
  );
}

export default UpdateProfilePic;
