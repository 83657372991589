import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { updateDirector, updateVodAsset } from "../../../../graphql/mutations";

function InfoSelect(props) {
  const { items, directors } = props;

  const filmArray = items.filter(
    (element) => element.homePage !== true && element.specialFeature !== true
  );

  const directorArray = directors.filter(
    (element) => element.homePage !== true
  );

  const [state, setState] = useState({
    chosenDirector: directorArray[0].id,
    chosenFilm: filmArray[0].id,
  });

  const existingFilm = items.find((element) => element.homePage === true);

  const existingDirector = directors.find(
    (element) => element.homePage === true
  );

  const setDirector = (event) => {
    setState({
      ...state,
      chosenDirector: event.target.value,
    });
  };

  const setFilm = (event) => {
    setState({
      ...state,
      chosenFilm: event.target.value,
    });
  };

  const submitDirectorFormHandler = (event) => {
    event.preventDefault();

    const oldDirector = {
      input: {
        id: existingDirector.id,
        homePage: null,
      },
    };

    const newDirector = {
      input: {
        id: state.chosenDirector,
        homePage: true,
      },
    };

    API.graphql(graphqlOperation(updateDirector, oldDirector)).catch((err) =>
      console.log(err)
    );
    API.graphql(graphqlOperation(updateDirector, newDirector))
      .then(() => {
        setState({
          ...state,
          chosenDirector: directorArray[0].id,
        });

        console.log(
          `Successfully Added ${state.chosenDirector.name} to the Home Page.`
        );
      })
      .catch((err) => console.log(err));
  };

  const submitFilmFormHandler = (event) => {
    event.preventDefault();

    const oldFilm = {
      input: {
        id: existingFilm.id,
        homePage: null,
      },
    };

    const newFilm = {
      input: {
        id: state.chosenFilm,
        homePage: true,
      },
    };

    API.graphql(graphqlOperation(updateVodAsset, oldFilm));
    API.graphql(graphqlOperation(updateVodAsset, newFilm))
      .then(() => {
        setState({
          ...state,
          chosenFilm: filmArray[0].id,
        });

        console.log(
          `Successfully Added ${state.chosenFilm.title} to the Home Page.`
        );
      })
      .catch((err) => console.log(err));
  };

  const showSelect = () => {
    if (existingDirector && existingFilm) {
      return (
        <div className="text-white pt-4">
          <h4>Home Page Info</h4>
          <Form onSubmit={submitDirectorFormHandler} className="mt-4">
            <Form.Group>
              <Form.Label>Select a Director</Form.Label>
              <Form.Control
                as="select"
                onChange={setDirector}
                value={state.chosenDirector}
              >
                {directorArray.map((option, index) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Text>Current Director: {existingDirector.name}</Form.Text>
            </Form.Group>
            <Button className="mt-2" variant="dark" type="submit">
              Update Director
            </Button>
          </Form>
          <Form onSubmit={submitFilmFormHandler} className="mt-4">
            <Form.Group>
              <Form.Label>Select a Film</Form.Label>
              <Form.Control
                as="select"
                onChange={setFilm}
                value={state.chosenFilm}
              >
                {filmArray.map((option, index) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.title} - {option.director}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Text>
                Current Film: {existingFilm.title} - {existingFilm.director}
              </Form.Text>
            </Form.Group>
            <Button className="mt-2" variant="dark" type="submit">
              Update Film
            </Button>
          </Form>
        </div>
      );
    }
  };

  return <>{showSelect()}</>;
}

export default InfoSelect;
