import React, { useState, useEffect } from "react";
import FilePicker from "../../../FilePicker/FilePicker";
import {
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Spinner,
  Card,
  Image,
} from "react-bootstrap";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateCarouselImage } from "../../../../../graphql/mutations";

function Image2Upload(props) {
  const { findDirector, findFilm, image2, films, directors, s3url } = props;
  const [image2State, setImage2State] = useState({
    newImage: {},
    image2Select: "1",
  });

  useEffect(() => {
    if (image2State.image2Select === "1") {
      setImage2State((prevState) => ({
        ...prevState,
        newImage: {
          ...image2State.newImage,
          directorID: directors[0].id,
          filmID: null,
        },
      }));
    } else if (image2State.image2Select === "2") {
      setImage2State((prevState) => ({
        ...prevState,
        newImage: {
          ...image2State.newImage,
          filmID: films[0].id,
          directorID: null,
        },
      }));
    }
  }, [image2State.image2Select]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.preventDefault();
    setImage2State({
      ...image2State,
      [event.target.name]: event.target.value,
    });
  };

  const imageCallback = (dataFromChild) => {
    setImage2State({
      ...image2State,
      newImage: {
        file: dataFromChild,
        fileName: dataFromChild.name,
      },
    });
  };

  const renderOption = (item, object) => {
    if (item === "1") {
      return (
        <Form.Control
          as="select"
          className="mb-4"
          onChange={(event) => setDirector(event, object)}
        >
          {directors.map((option, index) => {
            return (
              <option key={index} value={index}>
                {option.name}
              </option>
            );
          })}
        </Form.Control>
      );
    } else if (item === "2") {
      return (
        <Form.Control
          as="select"
          className="mb-4"
          onChange={(event) => setFilm(event, object)}
        >
          {films.map((option, index) => {
            return (
              !option.specialFeature && (
                <option key={index} value={index}>
                  {option.title} - {option.director}
                </option>
              )
            );
          })}
        </Form.Control>
      );
    }
  };

  const setDirector = (event, object) => {
    const newObject = {
      ...image2State[object],
      ...(image2State[object].filmID
        ? {
            filmID: undefined,
            directorID: directors[event.target.value].id,
          }
        : { directorID: directors[event.target.value].id }),
    };

    setImage2State({
      ...image2State,
      [object]: newObject,
    });
  };

  const setFilm = (event, object) => {
    const newObject = {
      ...image2State[object],
      ...(image2State[object].directorID
        ? {
            directorID: undefined,
            filmID: films[event.target.value].id,
          }
        : { filmID: films[event.target.value].id }),
    };

    setImage2State({
      ...image2State,
      [object]: newObject,
    });
  };

  const submitFormHandler = (event, number) => {
    event.preventDefault();
    const updatedImage = {
      input: {
        id: image2.id,
        ...(image2State.newImage.fileName && {
          image: image2State.newImage.fileName,
        }),
        ...(image2State.newImage.directorID && {
          directorID: image2State.newImage.directorID,
          filmID: null,
        }),
        ...(image2State.newImage.filmID && {
          filmID: image2State.newImage.filmID,
          directorID: null,
        }),
        order: number,
      },
    };

    if (image2State.file) {
      Storage.put(
        image2State.newImage.fileName,
        image2State.newImage.file
      ).then(() => {
        API.graphql(graphqlOperation(updateCarouselImage, updatedImage));
      });
    }
    API.graphql(graphqlOperation(updateCarouselImage, updatedImage));
  };

  const renderImage2 = () => {
    if (image2 !== undefined) {
      return (
        <div className="mt-4">
          <p>Image 2</p>
          <Image fluid src={`${s3url}${image2.image}`} />
          <p>
            Links to:{" "}
            {image2.directorID ? findDirector(image2) : findFilm(image2)}
          </p>

          <Card bg="dark">
            <Card.Header>Update Image 2</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => submitFormHandler(e, 2)}>
                <Form.Group>
                  <FilePicker type="image" callbackFromParent={imageCallback} />
                  <Form.Label>Choose a Link</Form.Label>
                  <br />
                  <ToggleButtonGroup
                    name="image2Select"
                    defaultValue={1}
                    className="mb-2"
                  >
                    <ToggleButton
                      id="toggle3"
                      value={1}
                      onChange={handleChange}
                      variant="secondary"
                    >
                      Directors
                    </ToggleButton>
                    <ToggleButton
                      id="toggle4"
                      value={2}
                      onChange={handleChange}
                      variant="secondary"
                    >
                      Films
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {renderOption(image2State.image2Select, "newImage")}
                  <Button variant="secondary" type="submit">
                    Update Image 2
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      );
    }
    <Spinner animation="border" variant="light" />;
  };

  return <div>{renderImage2()}</div>;
}

export default Image2Upload;
