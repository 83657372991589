/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDirector = /* GraphQL */ `
  subscription OnCreateDirector($filter: ModelSubscriptionDirectorFilterInput) {
    onCreateDirector(filter: $filter) {
      id
      name
      bio
      location
      image
      homePage
      videos {
        items {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDirector = /* GraphQL */ `
  subscription OnUpdateDirector($filter: ModelSubscriptionDirectorFilterInput) {
    onUpdateDirector(filter: $filter) {
      id
      name
      bio
      location
      image
      homePage
      videos {
        items {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDirector = /* GraphQL */ `
  subscription OnDeleteDirector($filter: ModelSubscriptionDirectorFilterInput) {
    onDeleteDirector(filter: $filter) {
      id
      name
      bio
      location
      image
      homePage
      videos {
        items {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateVodAsset = /* GraphQL */ `
  subscription OnCreateVodAsset($filter: ModelSubscriptionVodAssetFilterInput) {
    onCreateVodAsset(filter: $filter) {
      id
      title
      director
      directorID
      description
      year
      runtime
      genre
      star1
      star2
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      headerImage
      cardImage
      poster
      specialFeature
      specialFeatureVideo
      specialFeatureText {
        items {
          id
          filmID
          filmTitle
          title
          author
          body
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      specialFeaturePhoto
      homePage
      playlists {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vodAssetVideoId
      __typename
    }
  }
`;
export const onUpdateVodAsset = /* GraphQL */ `
  subscription OnUpdateVodAsset($filter: ModelSubscriptionVodAssetFilterInput) {
    onUpdateVodAsset(filter: $filter) {
      id
      title
      director
      directorID
      description
      year
      runtime
      genre
      star1
      star2
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      headerImage
      cardImage
      poster
      specialFeature
      specialFeatureVideo
      specialFeatureText {
        items {
          id
          filmID
          filmTitle
          title
          author
          body
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      specialFeaturePhoto
      homePage
      playlists {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vodAssetVideoId
      __typename
    }
  }
`;
export const onDeleteVodAsset = /* GraphQL */ `
  subscription OnDeleteVodAsset($filter: ModelSubscriptionVodAssetFilterInput) {
    onDeleteVodAsset(filter: $filter) {
      id
      title
      director
      directorID
      description
      year
      runtime
      genre
      star1
      star2
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      headerImage
      cardImage
      poster
      specialFeature
      specialFeatureVideo
      specialFeatureText {
        items {
          id
          filmID
          filmTitle
          title
          author
          body
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      specialFeaturePhoto
      homePage
      playlists {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vodAssetVideoId
      __typename
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag($filter: ModelSubscriptionTagFilterInput) {
    onCreateTag(filter: $filter) {
      id
      label
      films {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      directors {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag($filter: ModelSubscriptionTagFilterInput) {
    onUpdateTag(filter: $filter) {
      id
      label
      films {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      directors {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag($filter: ModelSubscriptionTagFilterInput) {
    onDeleteTag(filter: $filter) {
      id
      label
      films {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      directors {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFilmText = /* GraphQL */ `
  subscription OnCreateFilmText($filter: ModelSubscriptionFilmTextFilterInput) {
    onCreateFilmText(filter: $filter) {
      id
      filmID
      filmTitle
      title
      author
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFilmText = /* GraphQL */ `
  subscription OnUpdateFilmText($filter: ModelSubscriptionFilmTextFilterInput) {
    onUpdateFilmText(filter: $filter) {
      id
      filmID
      filmTitle
      title
      author
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFilmText = /* GraphQL */ `
  subscription OnDeleteFilmText($filter: ModelSubscriptionFilmTextFilterInput) {
    onDeleteFilmText(filter: $filter) {
      id
      filmID
      filmTitle
      title
      author
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onCreateComment(filter: $filter, owner: $owner) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onUpdateComment(filter: $filter, owner: $owner) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onDeleteComment(filter: $filter, owner: $owner) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateVideoObject = /* GraphQL */ `
  subscription OnCreateVideoObject(
    $filter: ModelSubscriptionVideoObjectFilterInput
  ) {
    onCreateVideoObject(filter: $filter) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateVideoObject = /* GraphQL */ `
  subscription OnUpdateVideoObject(
    $filter: ModelSubscriptionVideoObjectFilterInput
  ) {
    onUpdateVideoObject(filter: $filter) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteVideoObject = /* GraphQL */ `
  subscription OnDeleteVideoObject(
    $filter: ModelSubscriptionVideoObjectFilterInput
  ) {
    onDeleteVideoObject(filter: $filter) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCarouselImage = /* GraphQL */ `
  subscription OnCreateCarouselImage(
    $filter: ModelSubscriptionCarouselImageFilterInput
  ) {
    onCreateCarouselImage(filter: $filter) {
      id
      image
      directorID
      directorName
      filmID
      title
      type
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCarouselImage = /* GraphQL */ `
  subscription OnUpdateCarouselImage(
    $filter: ModelSubscriptionCarouselImageFilterInput
  ) {
    onUpdateCarouselImage(filter: $filter) {
      id
      image
      directorID
      directorName
      filmID
      title
      type
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCarouselImage = /* GraphQL */ `
  subscription OnDeleteCarouselImage(
    $filter: ModelSubscriptionCarouselImageFilterInput
  ) {
    onDeleteCarouselImage(filter: $filter) {
      id
      image
      directorID
      directorName
      filmID
      title
      type
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePlaylist = /* GraphQL */ `
  subscription OnCreatePlaylist(
    $filter: ModelSubscriptionPlaylistFilterInput
    $owner: String
  ) {
    onCreatePlaylist(filter: $filter, owner: $owner) {
      id
      name
      films {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePlaylist = /* GraphQL */ `
  subscription OnUpdatePlaylist(
    $filter: ModelSubscriptionPlaylistFilterInput
    $owner: String
  ) {
    onUpdatePlaylist(filter: $filter, owner: $owner) {
      id
      name
      films {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePlaylist = /* GraphQL */ `
  subscription OnDeletePlaylist(
    $filter: ModelSubscriptionPlaylistFilterInput
    $owner: String
  ) {
    onDeletePlaylist(filter: $filter, owner: $owner) {
      id
      name
      films {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateTimeCode = /* GraphQL */ `
  subscription OnCreateTimeCode(
    $filter: ModelSubscriptionTimeCodeFilterInput
    $owner: String
  ) {
    onCreateTimeCode(filter: $filter, owner: $owner) {
      id
      filmID
      time
      type
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onUpdateTimeCode = /* GraphQL */ `
  subscription OnUpdateTimeCode(
    $filter: ModelSubscriptionTimeCodeFilterInput
    $owner: String
  ) {
    onUpdateTimeCode(filter: $filter, owner: $owner) {
      id
      filmID
      time
      type
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onDeleteTimeCode = /* GraphQL */ `
  subscription OnDeleteTimeCode(
    $filter: ModelSubscriptionTimeCodeFilterInput
    $owner: String
  ) {
    onDeleteTimeCode(filter: $filter, owner: $owner) {
      id
      filmID
      time
      type
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion(
    $filter: ModelSubscriptionQuestionFilterInput
    $owner: String
  ) {
    onCreateQuestion(filter: $filter, owner: $owner) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      answer {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      questionAnswerId
      owner
      __typename
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion(
    $filter: ModelSubscriptionQuestionFilterInput
    $owner: String
  ) {
    onUpdateQuestion(filter: $filter, owner: $owner) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      answer {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      questionAnswerId
      owner
      __typename
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion(
    $filter: ModelSubscriptionQuestionFilterInput
    $owner: String
  ) {
    onDeleteQuestion(filter: $filter, owner: $owner) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      answer {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      questionAnswerId
      owner
      __typename
    }
  }
`;
export const onCreateAnswer = /* GraphQL */ `
  subscription OnCreateAnswer(
    $filter: ModelSubscriptionAnswerFilterInput
    $owner: String
  ) {
    onCreateAnswer(filter: $filter, owner: $owner) {
      id
      content
      picture
      name
      vodAssetId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAnswer = /* GraphQL */ `
  subscription OnUpdateAnswer(
    $filter: ModelSubscriptionAnswerFilterInput
    $owner: String
  ) {
    onUpdateAnswer(filter: $filter, owner: $owner) {
      id
      content
      picture
      name
      vodAssetId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAnswer = /* GraphQL */ `
  subscription OnDeleteAnswer(
    $filter: ModelSubscriptionAnswerFilterInput
    $owner: String
  ) {
    onDeleteAnswer(filter: $filter, owner: $owner) {
      id
      content
      picture
      name
      vodAssetId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateDirectorTags = /* GraphQL */ `
  subscription OnCreateDirectorTags(
    $filter: ModelSubscriptionDirectorTagsFilterInput
  ) {
    onCreateDirectorTags(filter: $filter) {
      id
      directorID
      tagID
      director {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDirectorTags = /* GraphQL */ `
  subscription OnUpdateDirectorTags(
    $filter: ModelSubscriptionDirectorTagsFilterInput
  ) {
    onUpdateDirectorTags(filter: $filter) {
      id
      directorID
      tagID
      director {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDirectorTags = /* GraphQL */ `
  subscription OnDeleteDirectorTags(
    $filter: ModelSubscriptionDirectorTagsFilterInput
  ) {
    onDeleteDirectorTags(filter: $filter) {
      id
      directorID
      tagID
      director {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePlaylistFilms = /* GraphQL */ `
  subscription OnCreatePlaylistFilms(
    $filter: ModelSubscriptionPlaylistFilmsFilterInput
    $owner: String
  ) {
    onCreatePlaylistFilms(filter: $filter, owner: $owner) {
      id
      vodAssetID
      playlistID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      playlist {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePlaylistFilms = /* GraphQL */ `
  subscription OnUpdatePlaylistFilms(
    $filter: ModelSubscriptionPlaylistFilmsFilterInput
    $owner: String
  ) {
    onUpdatePlaylistFilms(filter: $filter, owner: $owner) {
      id
      vodAssetID
      playlistID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      playlist {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePlaylistFilms = /* GraphQL */ `
  subscription OnDeletePlaylistFilms(
    $filter: ModelSubscriptionPlaylistFilmsFilterInput
    $owner: String
  ) {
    onDeletePlaylistFilms(filter: $filter, owner: $owner) {
      id
      vodAssetID
      playlistID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      playlist {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateFilmTags = /* GraphQL */ `
  subscription OnCreateFilmTags($filter: ModelSubscriptionFilmTagsFilterInput) {
    onCreateFilmTags(filter: $filter) {
      id
      vodAssetID
      tagID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFilmTags = /* GraphQL */ `
  subscription OnUpdateFilmTags($filter: ModelSubscriptionFilmTagsFilterInput) {
    onUpdateFilmTags(filter: $filter) {
      id
      vodAssetID
      tagID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFilmTags = /* GraphQL */ `
  subscription OnDeleteFilmTags($filter: ModelSubscriptionFilmTagsFilterInput) {
    onDeleteFilmTags(filter: $filter) {
      id
      vodAssetID
      tagID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
