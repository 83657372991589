import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../Pages/Home/Home";
import Filmmakers from "../Pages/Filmmakers/Filmmakers";
import Filmmaker from "../Pages/Filmmaker/Filmmaker";
import Film from "../Pages/Film/Film";
import Playlists from "../Pages/Playlists/Playlists";
import Admin from "../Admin/Admin";
import Account from "../Pages/Account/Account";
import About from "../Pages/About/About";
import Contact from "../Pages/Contact/Contact";

function Router(props) {
  const { signOut, user, s3url, setUserState } = props;

  return (
    <Routes>
      <Route path="/*" element={<Home s3url={s3url} user={user} />} />
      <Route path="filmmakers" element={<Filmmakers s3url={s3url} />} />
      <Route
        path="filmmaker/:filmmakerName"
        element={<Filmmaker user={user} s3url={s3url} />}
      />
      <Route
        path="/filmmaker/:filmmakerName/film/:filmTitle"
        element={<Film user={user} s3url={s3url} />}
      />
      <Route
        path="playlists"
        element={<Playlists user={user} s3url={s3url} />}
      />
      <Route path="admin" element={<Admin s3url={s3url} />} />
      <Route
        path="account"
        element={
          <Account
            user={user}
            signOut={signOut}
            s3url={s3url}
            setUserState={setUserState}
          />
        }
      />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
  );
}

export default Router;
