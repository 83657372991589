import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

function FilmmakersBreadcrumbs() {
  return (
    <div>
      <Breadcrumb className="d-flex justify-content-center">
        <Breadcrumb.Item
          className="breadcrumb-color"
          linkAs={Link}
          linkProps={{ to: "/" }}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active style={{ color: "#9f1f63" }}>
          Filmmakers
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default FilmmakersBreadcrumbs;
