import React, { useEffect, useReducer } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import PopoverProgress from "../PopoverProgress/PopoverProgress";
import SpecialFeaturePopover from "./SpecialFeaturePopover/SpecialFeaturePopover";
import SpecialFeatureVideo from "./SpecialFeatureVideo/SpecialFeatureVideo";
import SpecialFeatureText from "./SpecialFeatureText/SpecialFeatureText";
import SpecialFeatureImage from "./SpecialFeatureImage/SpecialFeatureImage";
import SpecialFeatureTable from "./SpecialFeatureTable";

const initialState = {
  titleVal: "",
  nextToken: "",
  displayingForm: false,
  progress: 0,
  chosenFilm: {},
  id: null,
  chosenItem: null,
  chosenType: "",
  showComponent: false,
  specialFeatureType: "video",
  textID: "",
  videos: null,
};

function SpecialFeatureUploadReducer(state, action) {
  switch (action.type) {
    case "displayForm": {
      return {
        ...state,
        displayingForm: true,
      };
    }
    case "hideForm": {
      return {
        ...state,
        displayingForm: false,
      };
    }
    case "field": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "setProgress": {
      return {
        ...state,
        progress: (action.loaded / action.total) * 100,
      };
    }
    case "showComponent": {
      return {
        ...state,
        showComponent: true,
        chosenItem: action.chosenItem,
        chosenType: action.chosenType,
        videos: action.videos,
      };
    }
    case "hideComponent": {
      return {
        ...state,
        showComponent: false,
      };
    }
    case "setSpecialFeatureType": {
      return {
        ...state,
        specialFeatureType: action.payload,
      };
    }
    case "updateChosenItem": {
      return {
        ...state,
        chosenItem: action.payload,
        videos: action.updatedVideos,
      };
    }
    default:
      return state;
  }
}

function SpecialFeatureUpload(props) {
  const { items, s3url } = props;

  const [state, dispatch] = useReducer(
    SpecialFeatureUploadReducer,
    initialState
  );
  const {
    displayingForm,
    progress,
    chosenItem,
    chosenType,
    showComponent,
    specialFeatureType,
    videos,
  } = state;

  useEffect(() => {
    if (chosenItem) {
      const chosenItemIndex = items.findIndex(
        (element) => element.id === chosenItem.id
      );

      if (specialFeatureType !== "video") {
        dispatch({
          type: "updateChosenItem",
          payload: items[chosenItemIndex],
        });
      }

      dispatch({
        type: "updateChosenItem",
        payload: items[chosenItemIndex],
        updatedVideos: getSpecialFeatureVideos(items[chosenItemIndex]),
      });
    }
  }, [items, chosenItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const displayForm = () => {
    dispatch({
      type: "displayForm",
    });
  };

  const hideForm = () => {
    dispatch({
      type: "hideForm",
    });
  };

  const setSpecialFeatureType = (event) => {
    dispatch({
      type: "setSpecialFeatureType",
      payload: event.target.value,
    });
  };

  const setProgress = (progressObject) => {
    const { loaded, total } = progressObject;
    dispatch({
      type: "setProgress",
      loaded: loaded,
      total: total,
    });
  };

  const handleSpecialFeatureType = () => {
    if (specialFeatureType === "video") {
      return <SpecialFeatureVideo items={items} setProgress={setProgress} />;
    } else if (specialFeatureType === "text") {
      return <SpecialFeatureText items={items} hideForm={hideForm} />;
    } else if (specialFeatureType === "photo") {
      return <SpecialFeatureImage items={items} hideForm={hideForm} />;
    }
  };

  const overlayForm = () => {
    return (
      <Modal show={displayingForm} onHide={hideForm}>
        <Modal.Header closeButton onHide={hideForm}>
          Create New Special Feature
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Special Feature Type</Form.Label>
              <Form.Control
                as="select"
                custom
                value={specialFeatureType}
                onChange={setSpecialFeatureType}
              >
                <option value="video">New Video</option>
                <option value="text">New Text</option>
                <option value="photo">New Images</option>
              </Form.Control>
            </Form.Group>
          </Form>
          {handleSpecialFeatureType()}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="flex-grow-1">
            <PopoverProgress progress={progress} />
          </div>
          <Button
            variant="dark"
            size="lg"
            type="submit"
            form={specialFeatureType}
          >
            Create Asset
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const popoverOnHide = () => {
    dispatch({
      type: "hideComponent",
    });
  };

  const getSpecialFeatureVideos = (film) => {
    if (film.specialFeatureVideo) {
      return film.specialFeatureVideo.map((element) => {
        return items.find((e) => e.id === element);
      });
    }
  };

  const onButtonClick = (item, chosenType) => {
    dispatch({
      type: "showComponent",
      chosenItem: item,
      chosenType: chosenType,
      videos: getSpecialFeatureVideos(item),
    });
  };

  return (
    <div className="pb-3">
      <SpecialFeaturePopover
        chosenItem={chosenItem}
        popoverOnHide={popoverOnHide}
        showComponent={showComponent}
        chosenType={chosenType}
        videos={videos}
        s3url={s3url}
      />
      <SpecialFeatureTable items={items} onButtonClick={onButtonClick} />
      {overlayForm()}
      <Button
        className="mt-4 mb-2"
        onClick={() => displayForm()}
        variant="dark"
        size="lg"
      >
        Create New Special Feature
      </Button>
    </div>
  );
}

export default SpecialFeatureUpload;
