import React, { useState } from "react";
import { MapView } from "@aws-amplify/ui-react-geo";
import {
  Marker,
  Popup,
  GeolocateControl,
  NavigationControl,
} from "react-map-gl";
import { Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCapitalize from "../../../../Hooks/useCapitalize";
import "@aws-amplify/ui-react-geo/styles.css";
import "./FilmmakerMapView.css";

function FilmmakerMapView(props) {
  const { filmmakers, s3url } = props;

  const caps = useCapitalize;

  const [showPopup, setShowPopup] = useState(null);

  const handleMarkerClick = (event, id) => {
    event.originalEvent.stopPropagation();
    setShowPopup(id);
  };

  return (
    <MapView
      initialViewState={{ latitude: 39.0997, longitude: -94.578331, zoom: 3 }}
    >
      <GeolocateControl />
      <NavigationControl showCompass={false} />
      {filmmakers.map((filmmaker) => {
        return (
          <div key={filmmaker.id}>
            <Marker
              latitude={filmmaker.latitude}
              longitude={filmmaker.longitude}
              onClick={(e) => handleMarkerClick(e, filmmaker.id)}
              color="#9f1f63"
            />
            {filmmaker.id === showPopup && (
              <Popup
                latitude={filmmaker.latitude}
                longitude={filmmaker.longitude}
                offset={{ bottom: [0, -40] }}
                onClose={() => setShowPopup(null)}
                closeButton={false}
              >
                <div className="director-image">
                  <Image
                    src={`${s3url}${filmmaker.image}`}
                    style={{ width: "80%" }}
                  />
                </div>
                <h5 className="mb-0">{caps(filmmaker.name)}</h5>
                <p className="mb-2">{filmmaker.location}</p>
                <p className="mb-2">{filmmaker.bio}</p>
                <Button
                  as={Link}
                  state={{
                    name: filmmaker.name,
                    id: filmmaker.id,
                  }}
                  to={`/filmmaker/${filmmaker.name
                    .replaceAll("#", "")
                    .replaceAll(" ", "-")
                    .toLowerCase()}`}
                  variant="grad"
                >
                  Learn More
                </Button>
              </Popup>
            )}
          </div>
        );
      })}
    </MapView>
  );
}

export default FilmmakerMapView;
