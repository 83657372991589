import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCapitalize from "../../../Hooks/useCapitalize";

function FilmBreadcrumbs(props) {
  const { title, director, directorId } = props;

  const caps = useCapitalize;
  return (
    <Breadcrumb listProps={{ className: "d-flex justify-content-center mx-2" }}>
      <Breadcrumb.Item
        className="breadcrumb-color-muted text-center"
        linkAs={Link}
        linkProps={{ to: "/" }}
      >
        Home
      </Breadcrumb.Item>
      <Breadcrumb.Item
        className="breadcrumb-color-muted"
        linkAs={Link}
        linkProps={{ to: `/filmmakers/` }}
      >
        Filmmakers
      </Breadcrumb.Item>
      <Breadcrumb.Item
        className="breadcrumb-color"
        linkAs={Link}
        linkProps={{
          to: `/filmmaker/${director.replaceAll(" ", "-").toLowerCase()}`,
          state: {
            name: director,
            id: directorId,
          },
        }}
      >
        {caps(director)}
      </Breadcrumb.Item>
      <Breadcrumb.Item active style={{ color: "#9f1f63" }}>
        {caps(title)}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default FilmBreadcrumbs;
