import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import SpecialFeatureTextModal from "./SpecialFeatureTextModal";

function SpecialFeatureText(props) {
  const { specialFeatureText } = props;

  const [showModal, setShowModal] = useState(false);

  const displayText = () => {
    setShowModal(true);
  };

  const hideText = () => {
    setShowModal(false);
  };

  const showSpecialFeatureText = () => {
    if (specialFeatureText.length > 0) {
      return (
        <>
          <h4 className="text-start pb-2">Essays</h4>
          {specialFeatureText.map((element, index) => {
            return (
              <div key={index}>
                <SpecialFeatureTextModal
                  show={showModal}
                  text={element}
                  hideText={hideText}
                />
                <Card className="bg-dark text-white">
                  <Card.Body className="d-flex justify-content-between">
                    <div className="text-start">
                      <Card.Title>{element.title}</Card.Title>
                      <Card.Text className="fw-light">
                        An essay about {element.filmTitle} by {element.author}
                      </Card.Text>
                    </div>
                    <Button variant="grad" onClick={() => displayText()}>
                      Read
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </>
      );
    }
  };
  return <div className="mb-3">{showSpecialFeatureText()}</div>;
}

export default SpecialFeatureText;
