import React, { useState, useEffect } from "react";
import FilePicker from "../../../FilePicker/FilePicker";
import {
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Spinner,
  Card,
  Image,
} from "react-bootstrap";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateCarouselImage } from "../../../../../graphql/mutations";

function Image3Upload(props) {
  const { findDirector, findFilm, image3, films, directors, s3url } = props;
  const [image3State, setImage3State] = useState({
    newImage: {},
    image3Select: "1",
  });

  useEffect(() => {
    if (image3State.image3Select === "1") {
      setImage3State((prevState) => ({
        ...prevState,
        newImage: {
          ...image3State.newImage,
          directorID: directors[0].id,
          filmID: null,
        },
      }));
    } else if (image3State.image3Select === "2") {
      setImage3State((prevState) => ({
        ...prevState,
        newImage: {
          ...image3State.newImage,
          filmID: films[0].id,
          directorID: null,
        },
      }));
    }
  }, [image3State.image3Select]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.preventDefault();
    setImage3State({
      ...image3State,
      [event.target.name]: event.target.value,
    });
  };

  const imageCallback = (dataFromChild) => {
    setImage3State({
      ...image3State,
      newImage: {
        file: dataFromChild,
        fileName: dataFromChild.name,
      },
    });
  };

  const renderOption = (item, object) => {
    if (item === "1") {
      return (
        <Form.Control
          as="select"
          className="mb-4"
          onChange={(event) => setDirector(event, object)}
        >
          {directors.map((option, index) => {
            return (
              <option key={index} value={index}>
                {option.name}
              </option>
            );
          })}
        </Form.Control>
      );
    } else if (item === "2") {
      return (
        <Form.Control
          as="select"
          className="mb-4"
          onChange={(event) => setFilm(event, object)}
        >
          {films.map((option, index) => {
            return (
              !option.specialFeature && (
                <option key={index} value={index}>
                  {option.title} - {option.director}
                </option>
              )
            );
          })}
        </Form.Control>
      );
    }
  };

  const setDirector = (event, object) => {
    const newObject = {
      ...image3State[object],
      ...(image3State[object].filmID
        ? {
            filmID: undefined,
            directorID: directors[event.target.value].id,
          }
        : { directorID: directors[event.target.value].id }),
    };

    setImage3State({
      ...image3State,
      [object]: newObject,
    });
  };

  const setFilm = (event, object) => {
    const newObject = {
      ...image3State[object],
      ...(image3State[object].directorID
        ? {
            directorID: undefined,
            filmID: films[event.target.value].id,
          }
        : { filmID: films[event.target.value].id }),
    };

    setImage3State({
      ...image3State,
      [object]: newObject,
    });
  };

  const submitFormHandler = (event, number) => {
    event.preventDefault();
    const updatedImage = {
      input: {
        id: image3.id,
        ...(image3State.newImage.fileName && {
          image: image3State.newImage.fileName,
        }),
        ...(image3State.newImage.directorID && {
          directorID: image3State.newImage.directorID,
          filmID: null,
        }),
        ...(image3State.newImage.filmID && {
          filmID: image3State.newImage.filmID,
          directorID: null,
        }),
        order: number,
      },
    };

    if (image3State.file) {
      Storage.put(
        image3State.newImage.fileName,
        image3State.newImage.file
      ).then(() => {
        API.graphql(graphqlOperation(updateCarouselImage, updatedImage));
      });
    }
    API.graphql(graphqlOperation(updateCarouselImage, updatedImage));
  };

  const renderImage3 = () => {
    if (image3 !== undefined) {
      return (
        <div className="mt-4">
          <p>Image 3</p>
          <Image src={`${s3url}${image3.image}`} fluid />
          <p>
            Links to:{" "}
            {image3.directorID ? findDirector(image3) : findFilm(image3)}
          </p>
          <Card bg="dark">
            <Card.Header>Update Image 3</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => submitFormHandler(e, 3)}>
                <Form.Group>
                  <FilePicker type="image" callbackFromParent={imageCallback} />
                  <Form.Label>Choose a Link</Form.Label>
                  <br />
                  <ToggleButtonGroup
                    name="image3Select"
                    defaultValue={1}
                    className="mb-2"
                  >
                    <ToggleButton
                      id="toggle5"
                      value={1}
                      onChange={handleChange}
                      variant="secondary"
                    >
                      Directors
                    </ToggleButton>
                    <ToggleButton
                      id="toggle6"
                      value={2}
                      onChange={handleChange}
                      variant="secondary"
                    >
                      Films
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {renderOption(image3State.image3Select, "newImage")}
                  <Button variant="secondary" type="submit">
                    Update Image 3
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      );
    }
    return (
      <div>
        <Spinner animation="border" variant="light" />
      </div>
    );
  };

  return <div>{renderImage3()}</div>;
}

export default Image3Upload;
