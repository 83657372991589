import React from "react";
import { Modal } from "react-bootstrap";

function SpecialFeatureTextModal(props) {
  const { show, text, hideText } = props;

  return (
    <Modal
      size="xl"
      show={show}
      onHide={hideText}
      contentClassName="bg-dark"
      className="text-white"
      scrollable
    >
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>{text.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="text-center fw-light mb-0 ms-2">{`An essay about ${text.filmTitle} by ${text.author}`}</h5>
        <br />
        {text.body
          .toString()
          .split("#")
          .map((str, index) => (
            <p key={index}>{str}</p>
          ))}
      </Modal.Body>
    </Modal>
  );
}

export default SpecialFeatureTextModal;
