import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import {
  getDirector,
  getTag,
  listPlaylists,
  getPlaylist,
} from "../../../graphql/queries";
import { createPlaylistFilms } from "../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  DropdownButton,
  Dropdown,
  Image,
  Fade,
} from "react-bootstrap";
import FilmmakerBreadcrumbs from "./FilmmakerBreadcrumbs/FilmmakerBreadcrumbs";
import { Helmet } from "react-helmet";
import useCapitalize from "../../Hooks/useCapitalize";

function Filmmaker(props) {
  const location = useLocation();
  const { filmmakerName } = useParams();
  const caps = useCapitalize;
  const { name, id } = location.state;
  const { s3url } = props;

  const [director, setDirector] = useState(null);
  const [playlists, setPlaylists] = useState(null);
  const [open, setOpen] = useState(false);
  const [similarDirectors, setSimilarDirectors] = useState([]);

  useEffect(() => {
    setPlaylists([]);
    setSimilarDirectors([]);
    setOpen(false);

    Promise.all([
      API.graphql(graphqlOperation(getDirector, { id: id })),
      API.graphql(graphqlOperation(listPlaylists)),
    ]).then(async (values) => {
      setDirector(values[0].data.getDirector);

      const directorIDs = await Promise.all(
        values[0].data.getDirector.tags.items.map(async (item) => {
          const tag = await API.graphql(
            graphqlOperation(getTag, { id: item.tagID })
          );

          return tag.data.getTag.directors.items.map((t) => t.directorID);
        })
      );

      const randomIDs = directorIDs
        .flat()
        .filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        )
        .filter((item) => item !== id)
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);

      randomIDs.forEach(async (id) => {
        await API.graphql(graphqlOperation(getDirector, { id: id })).then(
          (res) => {
            setSimilarDirectors((prevState) => {
              return [...prevState, res.data.getDirector];
            });
          }
        );
      });

      Promise.all(
        values[1].data.listPlaylists.items.map(async (p) => {
          return await API.graphql(graphqlOperation(getPlaylist, { id: p.id }));
        })
      )
        .then((res) => {
          const playlistData = res.map((item) => item.data.getPlaylist);
          setPlaylists(playlistData);
        })
        .catch((err) => console.log(err));

      setOpen(true);
    });
  }, [filmmakerName]); // eslint-disable-line react-hooks/exhaustive-deps

  const addToPlaylist = async (film, playlist) => {
    const newPlaylistFilms = {
      input: {
        vodAssetID: film.id,
        playlistID: playlist.id,
      },
    };

    try {
      await API.graphql(
        graphqlOperation(createPlaylistFilms, newPlaylistFilms)
      ).then((res) => {
        setPlaylists(
          playlists.map((list) => {
            if (list.id === playlist.id) {
              const { films, ...nofilms } = list;
              return {
                ...nofilms,
                films: {
                  items: films.items.concat(res.data.createPlaylistFilms),
                },
              };
            }
            return list;
          })
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showDirector = () => {
    const films = director.videos.items.filter((video) => {
      return !video.specialFeature;
    });

    return (
      <>
        <Row className="my-5 d-flex flex-column flex-fill flex-sm-row">
          <Col>
            <div className="my-auto director-image">
              <Image
                src={`${s3url}${director.image}`}
                style={{ width: "80%" }}
              />
            </div>
          </Col>
          <Col className="my-auto text-start">
            <div className="text-start mt-2 d-inline-block my-auto">
              <h1 style={{ color: "#9f1f63" }}>{caps(director.name)}</h1>
              <hr
                className="mt-1 opacity-100 w-75"
                style={{ color: "#9f1f63", borderTop: "8px solid" }}
              />
            </div>
            <h5 className="text-start">{director.bio}</h5>
          </Col>
        </Row>
        <h3 className="text-white my-3 text-start">FILMS BY THIS DIRECTOR</h3>
        <Row className="d-flex flex-column flex-fill flex-md-row">
          {films.length !== 0 &&
            films.map((value) => {
              return (
                <Col className="col-md-4 my-4" key={value.id}>
                  <Card className="h-100">
                    <Card.Img
                      variant="top"
                      src={`${s3url}${value.cardImage}`}
                      style={{ "--width": "100%" }}
                    />

                    <Card.Body>
                      <Card.Title style={{ fontSize: "30px" }}>
                        {caps(value.title)}
                      </Card.Title>
                      <Card.Text style={{ fontSize: "20px" }}>
                        <b>Genre:</b> {value.genre}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        as={Link}
                        state={{
                          id: value.id,
                          title: value.title,
                          name: value.director,
                          directorId: value.directorID,
                        }}
                        to={`/filmmaker/${value.director
                          .replaceAll("#", "")
                          .replaceAll(" ", "-")
                          .toLowerCase()}/film/${value.title
                          .replaceAll(" ", "-")
                          .toLowerCase()}`}
                        variant="grad"
                      >
                        Watch Film
                      </Button>
                      <p style={{ opacity: "80%" }} className="mt-3 mb-1">
                        Add to Playlist
                      </p>
                      <DropdownButton
                        size="sm"
                        variant="outline-dark"
                        title="Select Playlist"
                        drop="up"
                      >
                        {playlists && (
                          <Dropdown.Item
                            as={Link}
                            to={"/playlists"}
                            key={playlists.length + 1}
                            className="dropdown-dark"
                          >
                            Create Playlist
                          </Dropdown.Item>
                        )}
                        {playlists &&
                          playlists.map((playlist, index) => {
                            if (
                              playlist.films.items.some(
                                (element) => element.vodAssetID === value.id
                              )
                            ) {
                              return null;
                            }
                            return (
                              <Dropdown.Item
                                as={Button}
                                key={index}
                                variant="black"
                                onClick={() => {
                                  addToPlaylist(value, playlist);
                                }}
                              >
                                {playlist.name}
                              </Dropdown.Item>
                            );
                          })}
                      </DropdownButton>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </>
    );
  };

  return (
    <div className="min-vh-100">
      <Helmet>
        <title>Iris - {caps(name)}</title>
      </Helmet>
      <FilmmakerBreadcrumbs name={name} />
      <Fade in={open}>
        <div>
          <Container>{director && showDirector()}</Container>
          {similarDirectors.length > 0 && (
            <div className="pt-3 pb-5" style={{ backgroundColor: "#151515" }}>
              <Container>
                <Row>
                  <div className="text-start w-auto">
                    <h1 className="d-inline-block" style={{ color: "#9f1f63" }}>
                      Similar Directors
                    </h1>
                    <hr
                      className="mt-1 opacity-100 w-75"
                      style={{ color: "#9f1f63", borderTop: "8px solid" }}
                    />
                  </div>
                </Row>
                <Row className="d-flex flex-column flex-fill flex-md-row">
                  {similarDirectors.map((director, index) => {
                    return (
                      <Col key={index} className="col-md-4 my-4">
                        <Card className="h-100" text="dark">
                          <Card.Img
                            variant="top"
                            src={`${s3url}${director.image}`}
                            style={{ "--width": "100%" }}
                          />
                          <Card.Body>
                            <Card.Title style={{ fontSize: "30px" }}>
                              {caps(director.name)}
                            </Card.Title>
                            <Card.Subtitle>{director.location}</Card.Subtitle>
                            <Card.Text className="mt-4">
                              {director.bio}
                            </Card.Text>
                          </Card.Body>
                          <Card.Footer>
                            <Button
                              as={Link}
                              state={{
                                name: director.name,
                                id: director.id,
                              }}
                              to={`/filmmaker/${director.name
                                .replaceAll("#", "")
                                .replaceAll(" ", "-")
                                .toLowerCase()}`}
                              variant="grad"
                            >
                              Learn More
                            </Button>
                          </Card.Footer>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
}

export default Filmmaker;
