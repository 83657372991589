import React from "react";
import Image1Upload from "./Image1Upload/Image1Upload";
import Image2Upload from "./Image2Upload/Image2Upload";
import Image3Upload from "./Image3Upload/Image3Upload";

function CarouselImageUpload(props) {
  const { carouselImages, directors, items, s3url } = props;

  const findOrder = (number) => {
    const orderImage = carouselImages.find(
      (element) => element.order === number
    );
    return orderImage;
  };

  const image1 = findOrder(1);
  const image2 = findOrder(2);
  const image3 = findOrder(3);

  const findDirector = (image) => {
    const directorValue = directors.find(
      (element) => element.id === image.directorID
    );
    return directorValue.name;
  };

  const findFilm = (image) => {
    const filmValue = items.find((element) => element.id === image.filmID);
    return `${filmValue.title} - ${filmValue.director}`;
  };

  const renderForm = () => {
    if (
      carouselImages.length !== 0 &&
      directors.length !== 0 &&
      items.length !== 0
    ) {
      return (
        <div className="text-white py-4">
          <h4>Carousel Image Upload</h4>
          <Image1Upload
            directors={directors}
            films={items}
            image1={image1}
            findDirector={findDirector}
            findFilm={findFilm}
            s3url={s3url}
          />
          <Image2Upload
            directors={directors}
            films={items}
            image2={image2}
            findDirector={findDirector}
            findFilm={findFilm}
            s3url={s3url}
          />
          <Image3Upload
            directors={directors}
            films={items}
            image3={image3}
            findDirector={findDirector}
            findFilm={findFilm}
            s3url={s3url}
          />
        </div>
      );
    }
  };

  return <div>{renderForm()}</div>;
}

export default CarouselImageUpload;
