import React from "react";
import InfoSelect from "./InfoSelect/InfoSelect";
import CarouselImageUpload from "./CarouselImageUpload/CarouselImageUpload";
import { Container, Row, Col } from "react-bootstrap";

function HomePage(props) {
  const { carouselImages, items, directors, s3url } = props;

  return (
    <div>
      <Container>
        <Row>
          <Col className="mx-auto border-end pe-5">
            <CarouselImageUpload
              carouselImages={carouselImages}
              directors={directors}
              items={items}
              s3url={s3url}
            />
          </Col>
          <Col className="ps-5">
            {items.length !== 0 && directors.length !== 0 && (
              <InfoSelect items={items} directors={directors} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomePage;
