import React, { useContext } from "react";
import { Alert } from "react-bootstrap";

import NotificationContext from "../../context/NotificationContext";

function Notification() {
  const notificationCtx = useContext(NotificationContext);

  return (
    <Alert
      show={notificationCtx.notification}
      {...(notificationCtx.notification === "success"
        ? { variant: "success" }
        : { variant: "danger" })}
    >
      <p className="text-start mb-0">{notificationCtx.notificationText}</p>
    </Alert>
  );
}

export default Notification;
