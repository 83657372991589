import React, { useState, useEffect } from "react";

import FilePicker from "../../../FilePicker/FilePicker";
import {
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Spinner,
  Card,
  Image,
} from "react-bootstrap";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateCarouselImage } from "../../../../../graphql/mutations";

function Image1Upload(props) {
  const { findDirector, findFilm, image1, films, directors, s3url } = props;
  const [image1State, setImage1State] = useState({
    newImage: {},
    image1Select: "1",
  });

  useEffect(() => {
    if (image1State.image1Select === "1") {
      setImage1State((prevState) => ({
        ...prevState,
        newImage: {
          ...image1State.newImage,
          directorID: directors[0].id,
          filmID: null,
        },
      }));
    } else if (image1State.image1Select === "2") {
      setImage1State((prevState) => ({
        ...prevState,
        newImage: {
          ...image1State.newImage,
          filmID: films[0].id,
          directorID: null,
        },
      }));
    }
  }, [image1State.image1Select]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    event.preventDefault();
    setImage1State({
      ...image1State,
      [event.target.name]: event.target.value,
    });
  };

  const imageCallback = (dataFromChild) => {
    setImage1State({
      ...image1State,
      newImage: {
        file: dataFromChild,
        fileName: dataFromChild.name,
        ...(image1State.newImage.directorID && {
          directorID: image1State.newImage.directorID,
        }),
        ...(image1State.newImage.filmID && {
          filmID: image1State.newImage.filmID,
        }),
      },
    });
  };

  const renderOption = (item) => {
    if (item === "1") {
      return (
        <Form.Control as="select" className="mb-4" onChange={setDirector}>
          {directors.map((option, index) => {
            return (
              <option key={index} value={index}>
                {option.name}
              </option>
            );
          })}
        </Form.Control>
      );
    } else if (item === "2") {
      return (
        <Form.Control as="select" className="mb-4" onChange={setFilm}>
          {films.map((option, index) => {
            return (
              !option.specialFeature && (
                <option key={index} value={index}>
                  {option.title} - {option.director}
                </option>
              )
            );
          })}
        </Form.Control>
      );
    }
  };

  const setDirector = (event) => {
    const newObject = {
      ...image1State.newImage,
      ...(image1State.newImage.filmID
        ? {
            filmID: null,
            directorID: directors[event.target.value].id,
          }
        : { directorID: directors[event.target.value].id }),
    };

    setImage1State({
      ...image1State,
      newImage: newObject,
    });
  };

  const setFilm = (event) => {
    const newObject = {
      ...image1State.newImage,
      ...(image1State.newImage.directorID
        ? {
            directorID: null,
            filmID: films[event.target.value].id,
          }
        : { filmID: films[event.target.value].id }),
    };

    setImage1State({
      ...image1State,
      newImage: newObject,
    });
  };

  const submitFormHandler = (event) => {
    event.preventDefault();
    const updatedImage = {
      input: {
        id: image1.id,
        ...(image1State.newImage.fileName && {
          image: image1State.newImage.fileName,
        }),
        ...(image1State.newImage.directorID && {
          directorID: image1State.newImage.directorID,
          filmID: null,
        }),
        ...(image1State.newImage.filmID && {
          filmID: image1State.newImage.filmID,
          directorID: null,
        }),
      },
    };

    if (image1State.newImage.file) {
      Storage.put(
        image1State.newImage.fileName,
        image1State.newImage.file
      ).then(() => {
        API.graphql(graphqlOperation(updateCarouselImage, updatedImage));
      });
    }
    API.graphql(graphqlOperation(updateCarouselImage, updatedImage));
  };

  const renderImage1 = () => {
    if (image1.image !== undefined) {
      return (
        <div className="mt-4">
          <p>Image 1</p>
          <Image fluid src={`${s3url}${image1.image}`} />
          <p>
            Links to:{" "}
            {image1.directorID ? findDirector(image1) : findFilm(image1)}
          </p>
          <Card bg="dark" className="text-white">
            <Card.Header>Update Image 1</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => submitFormHandler(e)}>
                <Form.Group>
                  <FilePicker type="image" callbackFromParent={imageCallback} />
                  <Form.Label>Choose a Link</Form.Label>
                  <br />
                  <ToggleButtonGroup
                    type="checkbox"
                    name="image1Select"
                    className="mb-2"
                  >
                    <ToggleButton
                      id="toggle1"
                      value={1}
                      onChange={handleChange}
                      variant="secondary"
                    >
                      Directors
                    </ToggleButton>
                    <ToggleButton
                      id="toggle2"
                      value={2}
                      onChange={handleChange}
                      variant="secondary"
                    >
                      Films
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {renderOption(image1State.image1Select)}
                  <Button variant="secondary" type="submit">
                    Update Image 1
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      );
    }
    <Spinner animation="border" variant="light" />;
  };

  return <div>{renderImage1()}</div>;
}

export default Image1Upload;
