import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { Modal, Button } from "react-bootstrap";

function ChangeSubscription(props) {
  const { user, hideModal } = props;

  const [loading, setLoading] = useState(false);

  const interval = user.attributes["custom:interval"];

  const opts = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const date = new Date(
    user.attributes["custom:expireTime"]
  ).toLocaleDateString("en-us", opts);

  const reverseSubscription = (interval) => {
    if (interval === "Monthly") {
      return "yearly";
    } else {
      return "monthly";
    }
  };

  const handleChangeSubscription = async () => {
    setLoading(true);
    const body = {
      subscriptionId: user.attributes["custom:SubscriptionId"],
      ...(interval === "Monthly" && {
        priceId: "price_1MN1lkIgpGwPK9agUnvvB20x",
      }),
      ...(interval === "Yearly" && {
        priceId: "price_1MM1zpIgpGwPK9agSAQK8q0D",
      }),
    };

    await API.post("apic5c1ed0b", "/change-subscription", { body }).then(
      async () => {
        const user = await Auth.currentAuthenticatedUser();

        await Auth.updateUserAttributes(user, {
          ...(interval === "Monthly" && {
            "custom:interval": "Yearly",
          }),
          ...(interval === "Yearly" && {
            "custom:interval": "Monthly",
          }),
        }).then(() => {
          hideModal();
        });
      }
    );
  };

  return (
    <div className="text-white">
      <Modal.Header closeButton closeVariant="white" onHide={hideModal}>
        <Modal.Title>Change Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>{`Your subscription will change to a ${reverseSubscription(
            interval
          )} plan on:`}</p>
          <h5>{date}</h5>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <p className="fw-bold ps-4 mb-0">Current Price</p>
          <p className="pe-4 mb-0">
            {interval === "Yearly" ? "$49.99" : "$5.99"}
          </p>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <p className="fw-bold ps-4">New Price</p>
          <p className="pe-4 ">{interval === "Yearly" ? "$5.99" : "$49.99"}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="lg"
          variant="grad"
          onClick={handleChangeSubscription}
          disabled={loading}
        >
          {loading ? "Loading..." : "Confirm"}
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default ChangeSubscription;
