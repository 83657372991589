import React from "react";
import { Container, Row } from "react-bootstrap";

function About() {
  return (
    <div>
      <Container style={{ height: "70vh" }}>
        <Row>
          <h1 style={{ color: "#9f1f63" }}>About</h1>
        </Row>
        <Row>
          <div className="d-flex justify-content-center">
            <hr
              className="py-1 w-25 opacity-100"
              style={{ color: "#9f1f63", borderTop: "8px solid" }}
            />
          </div>
        </Row>
        <Row>
          <h5 className="text-white fw-light">
            Imagine a subscription service like Netflix that allows you to
            continuously discover the new Quentin Tarantino or Steven Spielberg
            and talk about them with other film buffs. Iris is a community that
            exclusively features new, independent filmmakers from all around the
            world. You’d be able to discover hidden gem artists who make
            professional-level, personal films using their own resources.
          </h5>
        </Row>
      </Container>
    </div>
  );
}

export default About;
