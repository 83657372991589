import React, { useState, useEffect } from "react";
import "./Account.css";
import { Container, Row, Col, ListGroup, Tab, Fade } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AccountActions from "./AccountActions/AccountActions";
import BillingActions from "./BillingActions/BillingActions";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Account(props) {
  const { s3url, user, setUserState } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className="min-vh-100">
      <Helmet>
        <title>Iris - Account - {user.attributes.name}</title>
      </Helmet>
      <Fade in={open}>
        <Container>
          <Row>
            <Tab.Container defaultActiveKey="#account-actions">
              <Col className="offset-sm-2" sm={3}>
                <h5 className="fw-bold text-start">Settings</h5>
                <ListGroup>
                  <ListGroup.Item
                    className="py-3"
                    variant="secondary"
                    action
                    href="#account-actions"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="my-0">Account</p>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="py-3"
                    variant="secondary"
                    action
                    href="#billing-actions"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="my-0"> Billing and Subscription</p>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col sm={5}>
                <Tab.Content>
                  <Tab.Pane eventKey="#account-actions">
                    <AccountActions
                      user={user}
                      s3url={s3url}
                      setUserState={setUserState}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#billing-actions">
                    <BillingActions user={user} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </Fade>
    </div>
  );
}

export default Account;
