import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Container, Image } from "react-bootstrap";
import { Amplify, API, graphqlOperation, Storage } from "aws-amplify";
import {
  updateVodAsset,
  deleteVodAsset,
  deleteVideoObject,
  createVideoObject,
  createFilmTags,
  updateTag,
  deleteFilmTags,
} from "../../../../graphql/mutations";
import FilePicker from "../../FilePicker/FilePicker";
import VideoPlayer from "../../../Pages/Video/VideoPlayer/VideoPlayer";
import awsvideo from "../../../../aws-video-exports";
import { v4 as uuidv4 } from "uuid";
import awsvideoconfig from "../../../../aws-video-exports";
import PopoverProgress from "../../PopoverProgress/PopoverProgress";

function FilmPopover(props) {
  const {
    chosenItem,
    action,
    showComponent,
    popoverOnHide,
    directors,
    s3url,
    tags,
  } = props;

  const [posterState, setPosterState] = useState(null);

  const [headerImageState, setHeaderImageState] = useState(null);

  const [cardImageState, setCardImageState] = useState(null);

  const [updatedFields, setUpdatedFields] = useState({});

  const [newVideo, setNewVideo] = useState(null);

  const [sources, setSources] = useState([]);

  const [progress, setProgress] = useState(0);

  const [director, setDirector] = useState(null);

  const [selectedTags, setSelectedTags] = useState({
    previous: [],
    new: [],
  });

  const [checked, setChecked] = useState({});

  useEffect(() => {
    const handleSources = () => {
      if (chosenItem) {
        setSources([
          {
            src: `https://${awsvideo.awsOutputVideo}/${chosenItem.video.id}/${chosenItem.video.id}.m3u8`,
            type: "application/x-mpegURL",
          },
        ]);
      }
    };
    handleSources();
  }, [chosenItem]);

  const initializeItems = () => {
    tags.forEach((tag) => {
      if (chosenItem.tags.items.some((item) => item.tag.id === tag.id)) {
        setChecked((prevState) => ({
          ...prevState,
          [tag.label]: true,
        }));
        setSelectedTags((prevState) => ({
          ...prevState,
          previous: prevState.previous.concat(tag),
        }));
      } else {
        setChecked((prevState) => ({
          ...prevState,
          [tag.label]: false,
        }));
      }
    });
  };

  const resetItems = () => {
    setSelectedTags({
      previous: [],
      new: [],
    });
    setChecked({});
  };

  const deleteItem = async () => {
    await API.graphql(
      graphqlOperation(deleteVideoObject, {
        input: {
          id: chosenItem.video.id,
        },
      })
    )
      .then((response, error) => {
        if (error === undefined) {
          API.graphql(
            graphqlOperation(deleteVodAsset, {
              input: {
                id: chosenItem.id,
              },
            })
          );
        }
        popoverOnHide();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const posterCallback = (dataFromChild) => {
    setPosterState({
      file: dataFromChild,
      fileName: dataFromChild.name,
    });

    setUpdatedFields({
      ...updatedFields,
      poster: dataFromChild.name,
    });
  };

  const headerImageCallback = (dataFromChild) => {
    setHeaderImageState({
      file: dataFromChild,
      fileName: dataFromChild.name,
    });

    setUpdatedFields({
      ...updatedFields,
      headerImage: dataFromChild.name,
    });
  };

  const cardImageCallback = (dataFromChild) => {
    setCardImageState({
      file: dataFromChild,
      fileName: dataFromChild.name,
    });

    setUpdatedFields({
      ...updatedFields,
      cardImage: dataFromChild.name,
    });
  };

  const myCallback = (dataFromChild) => {
    setNewVideo({
      file: dataFromChild,
      fileName: dataFromChild.name,
    });
  };

  const handleChange = (event) => {
    setUpdatedFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();

    if (posterState) {
      await Storage.put(posterState.fileName, posterState.file).then(
        (response) => {
          console.log(`Successfully Uploaded: ${response.key}`);
        }
      );
    }

    if (cardImageState) {
      await Storage.put(cardImageState.fileName, cardImageState.file).then(
        (response) => {
          console.log(`Successfully Uploaded: ${response.key}`);
        }
      );
    }

    if (headerImageState) {
      await Storage.put(headerImageState.fileName, headerImageState.file).then(
        (response) => {
          console.log(`Successfully Uploaded: ${response.key}`);
        }
      );
    }

    if (newVideo) {
      const region = Amplify._config.aws_project_region;
      const uuid = uuidv4();
      const videoObject = {
        input: {
          id: uuid,
        },
      };
      const fileExtension = newVideo.fileName.toLowerCase().split(".");

      await API.graphql(graphqlOperation(createVideoObject, videoObject));
      await API.graphql(
        graphqlOperation(deleteVideoObject, {
          input: { id: chosenItem.video.id },
        })
      );
      await API.graphql(
        graphqlOperation(updateVodAsset, {
          input: { id: chosenItem.id, vodAssetVideoId: uuid },
        })
      );
      await Storage.put(
        `${uuid}.${fileExtension[fileExtension.length - 1]}`,
        newVideo.file,
        {
          progressCallback(progress) {
            const { loaded, total } = progress;
            setProgress((loaded / total) * 100);
          },
          contentType: "video/*",
          bucket: awsvideoconfig.awsInputVideo,
          region,
          customPrefix: {
            public: "",
          },
        }
      )
        .then(() => console.log(`Successfully Uploaded: ${uuid}`))
        .catch((err) => console.log(`Error: ${err}`));
    }

    const updatedVideo = {
      input: {
        id: chosenItem.id,
        ...(director && { director: director }),
        ...updatedFields,
      },
    };

    if (selectedTags.new.length > 0) {
      for await (const t of selectedTags.new) {
        const filmTagParams = {
          input: {
            vodAssetID: chosenItem.id,
            tagID: t.id,
          },
        };

        await API.graphql(graphqlOperation(createFilmTags, filmTagParams));
      }
    }

    if (selectedTags.previous.length > 0) {
      for await (const t of selectedTags.previous) {
        if (!checked[t.label]) {
          const filmTagParams = {
            input: {
              id: chosenItem.id,
            },
          };

          await API.graphql(
            graphqlOperation(deleteFilmTags, filmTagParams)
          ).then((res) => {
            console.log(res);
          });
        }
      }
    }

    await API.graphql(graphqlOperation(updateVodAsset, updatedVideo))
      .then(() => {
        console.log(`Successfully Updated ${chosenItem.title}`);
        popoverOnHide();
      })
      .catch((err) => console.log(err));
  };

  const setChosenDirector = (event) => {
    setDirector(directors[event.target.value].name);
  };

  const handleCheck = (event) => {
    setChecked((prevState) => ({
      ...prevState,
      [event.target.name]: !prevState[event.target.name],
    }));

    const selectedTag = tags[event.target.value];

    if (event.target.checked) {
      setSelectedTags((prevState) => ({
        ...prevState,
        new: prevState.new.concat(selectedTag),
      }));
    } else {
      if (
        selectedTags.new.length > 0 &&
        selectedTags.new.some((item) => item.id === selectedTag.id)
      ) {
        setSelectedTags((prevState) => ({
          ...prevState,
          new: selectedTags.new.filter((item) => item.id !== selectedTag.id),
        }));
      }
    }
  };

  const overlayInfo = () => {
    if (action === "read") {
      return (
        <Modal show={showComponent} onHide={popoverOnHide} onExit={resetItems}>
          <Modal.Header closeButton>{chosenItem.title}</Modal.Header>
          <Modal.Body>
            <Container>
              <Row>Director: {chosenItem.director}</Row>
              <Row>Description: {chosenItem.description} </Row>
              <Row>Genre: {chosenItem.genre} </Row>
              <Row>Year: {chosenItem.year} </Row>
              <Row>Runtime: {chosenItem.runtime} Minutes </Row>
              <Row>Star 1: {chosenItem.star1} </Row>
              <Row>Star 2: {chosenItem.star2} </Row>
              Video:
              <VideoPlayer
                controls
                sources={sources}
                fluid
                bigPlayButton={true}
                controlBar={{
                  children: {
                    playToggle: true,
                    volumeControl: true,
                    progressControl: true,
                    fullscreenToggle: true,
                  },
                }}
              />
              <Row> Poster: </Row>
              <Row>
                <Image src={`${s3url}${chosenItem.poster}`} />
              </Row>
              <Row>
                Card Image: <Image src={`${s3url}${chosenItem.cardImage}`} />
              </Row>
              <Row>
                Header Image:{" "}
                <Image src={`${s3url}${chosenItem.headerImage}`} />
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      );
    } else if (action === "update") {
      return (
        <Modal
          show={showComponent}
          onHide={popoverOnHide}
          onEnter={initializeItems}
          onExit={resetItems}
        >
          <Modal.Header closeButton>{chosenItem.title}</Modal.Header>
          <Modal.Body>
            <Form onSubmit={submitFormHandler} id="film-update-form">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={updatedFields.title || chosenItem.title}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Director</Form.Label>
                <Form.Control as="select" onChange={setChosenDirector}>
                  {directors.map((option, index) => {
                    if (option.name === chosenItem.director) {
                      return (
                        <option key={index} selected value={index}>
                          {option.name}
                        </option>
                      );
                    }
                    return (
                      <option key={index} value={index}>
                        {option.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={4}
                  name="description"
                  value={updatedFields.description || chosenItem.description}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Genre</Form.Label>
                <Form.Control
                  type="text"
                  name="genre"
                  value={updatedFields.genre || chosenItem.genre}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Year</Form.Label>
                <Form.Control
                  type="text"
                  name="year"
                  value={updatedFields.year || chosenItem.year}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Runtime (in minutes)</Form.Label>
                <Form.Control
                  type="text"
                  name="runtime"
                  value={updatedFields.runtime || chosenItem.runtime}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Star 1</Form.Label>
                <Form.Control
                  type="text"
                  name="star1"
                  value={updatedFields.star1 || chosenItem.star1}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Star 2</Form.Label>
                <Form.Control
                  type="text"
                  name="star2"
                  value={updatedFields.star2 || chosenItem.star2}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tags</Form.Label>
                {tags.map((tag, index) => {
                  return (
                    <Form.Check
                      key={index}
                      label={tag.label}
                      checked={checked[tag.label]}
                      onClick={handleCheck}
                      name={tag.label}
                      value={index}
                    />
                  );
                })}
              </Form.Group>
              <Container>
                Video:
                <VideoPlayer
                  controls
                  sources={sources}
                  fluid
                  bigPlayButton={true}
                  controlBar={{
                    children: {
                      playToggle: true,
                      volumeControl: true,
                      progressControl: true,
                      fullscreenToggle: true,
                    },
                  }}
                />
                <Form.Group>
                  <Form.Label>Upload New Video File</Form.Label>
                  <FilePicker type="video" callbackFromParent={myCallback} />
                </Form.Group>
                <Row>Images:</Row>
                <Row>
                  <Image fluid src={`${s3url}${chosenItem.poster}`} />
                  <Form.Group className="mb-3">
                    <Form.Label>Upload New Poster:</Form.Label>
                    <FilePicker
                      type="image"
                      callbackFromParent={posterCallback}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Image fluid src={`${s3url}${chosenItem.headerImage}`} />
                  <Form.Group className="mb-3">
                    <Form.Label>Upload New Header Image:</Form.Label>
                    <FilePicker
                      type="image"
                      callbackFromParent={headerImageCallback}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group>
                    <Image fluid src={`${s3url}${chosenItem.cardImage}`} />
                    <Form.Label>Upload New Card Image:</Form.Label>
                    <FilePicker
                      type="image"
                      callbackFromParent={cardImageCallback}
                    />
                  </Form.Group>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex-grow-1">
              <PopoverProgress progress={progress} />
            </div>
            <Button type="submit" form="film-update-form" variant="dark">
              Update Film
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else if (action === "delete") {
      return (
        <Modal show={showComponent} onHide={popoverOnHide}>
          <Modal.Header closeButton>{chosenItem.title}</Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <div className="d-flex justify-content-center">
                  <p>Are you sure you want to delete this film?</p>
                </div>
              </Row>
              <Row>
                <div className="d-flex justify-content-around">
                  <Button onClick={deleteItem} variant="dark" size="sm">
                    Yes
                  </Button>
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => popoverOnHide()}
                  >
                    No
                  </Button>
                </div>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      );
    }
  };
  return <>{overlayInfo()}</>;
}

export default FilmPopover;
