import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import NotificationContext from "../../../../context/NotificationContext";
import Notification from "../../../Notification/Notification";

function VerifyEmail(props) {
  const { setToggle, setModal, setUserState, newEmail } = props;

  const notificationCtx = useContext(NotificationContext);

  const [code, setCode] = useState("");

  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    return () => {
      notificationCtx.clear();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const verifyEmailValidationCode = async (event) => {
    event.preventDefault();

    setDisableButton(true);

    await Auth.verifyCurrentUserAttributeSubmit("email", code)
      .then(() => {
        setUserState((prevState) => ({
          ...prevState,
          attributes: {
            ...prevState.attributes,
            email: newEmail,
          },
        }));
        setToggle("off");
        setModal({
          show: true,
          type: "email",
        });
        setTimeout(() => {
          setModal((prevState) => ({
            ...prevState,
            show: false,
          }));
        }, 2000);
      })
      .catch((e) => {
        setDisableButton(false);
        const str = e.toString();

        notificationCtx.error(str.substring(str.indexOf(":") + 2));
      });
  };

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  return (
    <>
      <Notification />
      <Form onSubmit={verifyEmailValidationCode}>
        <div className="d-flex justify-content-start align-items-center mb-3">
          <Form.Label className="mb-0 fw-bold">Verify Email</Form.Label>
        </div>
        <Form.Group className="text-start">
          <div className="d-flex justify-content-start align-items-center">
            <Form.Label className="mb-0 fw-bold">Verification Code</Form.Label>
          </div>
          <Form.Control value={code} onChange={handleChange} />
          <Form.Text className="text-white">
            Enter the code that was sent to the email you provided.
          </Form.Text>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button
            size="lg"
            type="submit"
            variant="grad"
            className="mt-2"
            disabled={code.length < 6 || disableButton}
          >
            {disableButton ? "Loading..." : "Verify"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default VerifyEmail;
