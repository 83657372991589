import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import FilePicker from "../../FilePicker/FilePicker";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateVodAsset } from "../../../../graphql/mutations";

function SpecialFeatureImage(props) {
  const { items, hideForm } = props;

  const [state, setState] = useState({
    chosenFilm: null,
    files: [],
  });

  useEffect(() => {
    const firstItem = items.find((element) => element.specialFeature !== true);

    setState((prevState) => ({
      ...prevState,
      chosenFilm: firstItem,
    }));
  }, [items]);

  const imageCallback = (dataFromChild) => {
    setState({
      ...state,
      files: dataFromChild,
    });
  };

  const setChosenFilm = (event) => {
    setState({
      ...state,
      chosenFilm: items[event.target.value],
    });
  };

  const submitFormHandler = (event) => {
    event.preventDefault();

    if (state.files) {
      state.files.forEach((file) => {
        Storage.put(file.name, file).then((response) => {
          console.log(`Successfully Uploaded: ${response.key}`);
        });
      });

      const newArray = state.files.map((element) => element.name);

      const updatedVideo = {
        input: {
          id: state.chosenFilm.id,
          specialFeaturePhoto: [
            ...(state.chosenFilm.specialFeaturePhoto !== null
              ? state.chosenFilm.specialFeaturePhoto
              : []),
            ...newArray,
          ],
        },
      };

      API.graphql(graphqlOperation(updateVodAsset, updatedVideo)).then(() =>
        hideForm()
      );
    }
  };

  return (
    <div>
      <Form onSubmit={submitFormHandler} id="photo">
        <Form.Group>
          <Form.Label>Image Files</Form.Label>
          <FilePicker
            type="specialFeatureImage"
            callbackFromParent={imageCallback}
          />
          <Form.Label>Select a Film</Form.Label>
          <Form.Control as="select" custom onChange={setChosenFilm}>
            {items.map((option, index) => {
              if (!option.specialFeature) {
                return (
                  <option key={index} value={index}>
                    {option.title} - {option.director}
                  </option>
                );
              }
              return null;
            })}
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
}

export default SpecialFeatureImage;
