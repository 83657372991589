import React, { useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { Geo } from "aws-amplify";
import {
  deleteDirector,
  updateDirector,
  deleteVodAsset,
  deleteVideoObject,
  createDirectorTags,
} from "../../../../graphql/mutations";
import FilePicker from "../../FilePicker/FilePicker";
import { Modal, Form, Container, Row, Button, Image } from "react-bootstrap";

function DirectorPopover(props) {
  const {
    chosenItem,
    action,
    popoverOnHide,
    showComponent,
    items,
    s3url,
    tags,
  } = props;

  const [updatedFields, setUpdatedFields] = useState({});

  const [imageState, setImageState] = useState(null);

  const [selectedTags, setSelectedTags] = useState({
    previous: [],
    new: [],
  });

  const [checked, setChecked] = useState({});

  const initializeItems = () => {
    // tags.forEach((tag) => {
    //   if (chosenItem.tags.items.some((item) => item.tag.id === tag.id)) {
    //     setChecked((prevState) => ({
    //       ...prevState,
    //       [tag.label]: true,
    //     }));
    //     setSelectedTags((prevState) => ({
    //       ...prevState,
    //       previous: prevState.previous.concat(tag),
    //     }));
    //   } else {
    //     setChecked((prevState) => ({
    //       ...prevState,
    //       [tag.label]: false,
    //     }));
    //   }
    // });
  };

  const resetItems = () => {
    setSelectedTags({
      previous: [],
      new: [],
    });
    setChecked({});
  };

  const deleteItem = () => {
    API.graphql(
      graphqlOperation(deleteDirector, {
        input: {
          id: chosenItem.id,
        },
      })
    )
      .then(() => {
        items.forEach((video) => {
          if (video.directorID === chosenItem.id) {
            API.graphql(
              graphqlOperation(deleteVideoObject, {
                input: { id: video.video.id },
              })
            ).then(() => {
              API.graphql(
                graphqlOperation(deleteVodAsset, { input: { id: video.id } })
              );
            });
          }
        });
      })
      .finally(() => {
        popoverOnHide();
      });
  };

  const handleChange = (event) => {
    setUpdatedFields((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();

    if (imageState) {
      Storage.put(imageState.fileName, imageState.file).then((response) => {
        console.log(`Successfully Uploaded: ${response.key}`);
      });
    }

    const updatedDirector = {
      input: {
        id: chosenItem.id,
        ...updatedFields,
      },
    };

    await API.graphql(graphqlOperation(updateDirector, updatedDirector))
      .then(() => {
        console.log(`Successfully Updated ${chosenItem.name}`);
        popoverOnHide();
      })
      .catch((err) => console.log(err));

    await Geo.searchByText(chosenItem.location).then(async (res) => {
      const coordinates = {
        input: {
          id: chosenItem.id,
          latitude: res[0].geometry.point[1],
          longitude: res[0].geometry.point[0],
        },
      };

      await API.graphql(graphqlOperation(updateDirector, coordinates)).then(
        () => console.log(`Successfully updated ${chosenItem.name}`)
      );
    });

    if (selectedTags.new.length > 0) {
      for await (const t of selectedTags.new) {
        const directorTagParams = {
          input: {
            directorID: chosenItem.id,
            tagID: t.id,
          },
        };

        await API.graphql(
          graphqlOperation(createDirectorTags, directorTagParams)
        );
      }
    }
  };

  const imageCallback = (dataFromChild) => {
    setImageState({
      file: dataFromChild,
      fileName: dataFromChild.name,
    });

    setUpdatedFields({
      ...updatedFields,
      image: dataFromChild.name,
    });
  };

  const handleCheck = (event) => {
    setChecked((prevState) => ({
      ...prevState,
      [event.target.name]: !prevState[event.target.name],
    }));

    const selectedTag = tags[event.target.value];

    if (event.target.checked) {
      setSelectedTags((prevState) => ({
        ...prevState,
        new: prevState.new.concat(selectedTag),
      }));
    } else {
      if (
        selectedTags.new.length > 0 &&
        selectedTags.new.some((item) => item.id === selectedTag.id)
      ) {
        setSelectedTags((prevState) => ({
          ...prevState,
          new: selectedTags.new.filter((item) => item.id !== selectedTag.id),
        }));
      }
    }
  };

  const overlayInfo = () => {
    if (action === "read") {
      return (
        <Modal show={showComponent} onHide={popoverOnHide}>
          <Modal.Header closeButton>{chosenItem.name}</Modal.Header>
          <Modal.Body>
            <Container>
              <Row>Bio: {chosenItem.bio}</Row>
              <Row>Location: {chosenItem.location}</Row>
              <Row>
                Image:
                <Image src={`${s3url}${chosenItem.image}`} />
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      );
    } else if (action === "update") {
      return (
        <Modal
          show={showComponent}
          onHide={popoverOnHide}
          onEnter={initializeItems}
          onExit={resetItems}
        >
          <Modal.Header closeButton>{chosenItem.name}</Modal.Header>
          <Modal.Body>
            <Form onSubmit={submitFormHandler} id="director-update-form">
              <Form.Group>
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={updatedFields.name || chosenItem.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Bio:</Form.Label>
                <Form.Control
                  type="text"
                  name="bio"
                  as="textarea"
                  rows={4}
                  value={updatedFields.bio || chosenItem.bio}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Location:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={updatedFields.location || chosenItem.location}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tags</Form.Label>
                {tags.map((tag, index) => {
                  return (
                    <Form.Check
                      key={index}
                      label={tag.label}
                      checked={checked[tag.label]}
                      onClick={handleCheck}
                      name={tag.label}
                      value={index}
                    />
                  );
                })}
              </Form.Group>
              <Container>
                <Row>Images</Row>
                <Row>
                  <Image src={``} />
                </Row>
                <Row>
                  <Form.Group>
                    <Form.Label>Upload New Image:</Form.Label>
                    <FilePicker
                      type="image"
                      callbackFromParent={imageCallback}
                    />
                  </Form.Group>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" form="director-update-form" variant="dark">
              Update Filmmaker
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else if (action === "delete") {
      return (
        <Modal show={showComponent} onHide={popoverOnHide}>
          <Modal.Header closeButton>{chosenItem.name}</Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <div className="d-flex justify-content-center">
                  <p>Are you sure you want to delete this filmmaker?</p>
                </div>
              </Row>
              <Row>
                <div className="d-flex justify-content-around">
                  <Button onClick={deleteItem} variant="dark" size="sm">
                    Yes
                  </Button>
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => popoverOnHide()}
                  >
                    No
                  </Button>
                </div>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      );
    }
  };

  return <>{overlayInfo()}</>;
}

export default DirectorPopover;
