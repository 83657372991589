import React, { useState, useEffect } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { Form, Button } from "react-bootstrap";
import { createQuestion } from "../../../../graphql/mutations";
import Paginate from "../Paginate/Paginate";
import { questionsByDate } from "../../../../graphql/queries";
import Question from "./Question";
import {
  onCreateQuestion,
  onDeleteQuestion,
  onUpdateAnswer,
  onUpdateQuestion,
} from "../../../../graphql/subscriptions";
import useCapitalize from "../../../Hooks/useCapitalize";

function AskTheFilmmaker(props) {
  const { filmID, height, user, s3url, director } = props;

  const caps = useCapitalize;

  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [groups, setGroups] = useState(null);

  const recordsPerPage = Math.ceil(height / 145);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = questions.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(questions.length / recordsPerPage);

  useEffect(() => {
    const getQuestions = async () => {
      return await API.graphql({
        query: questionsByDate,
        variables: {
          limit: recordsPerPage,
          vodAssetId: filmID,
          sortDirection: "ASC",
        },
      });
    };

    getQuestions().then((result) => {
      setQuestions(result.data.questionsByDate.items);
      if (result.data.questionsByDate.nextToken) {
        setNextToken(result.data.questionsByDate.nextToken);
      }
    });

    const getGroups = async () => {
      return await Auth.currentSession();
    };

    getGroups().then((data) => {
      const sessionGroups = data.idToken.payload["cognito:groups"];
      if (sessionGroups) {
        setGroups(sessionGroups);
      }
    });

    const variables = {
      filter: {
        vodAssetId: { eq: filmID },
      },
    };

    const listenForNewQuestions = async () => {
      await API.graphql(
        graphqlOperation(onCreateQuestion, variables)
      ).subscribe({
        next: (data) => {
          if (!nextToken) {
            setQuestions((prevState) => {
              return prevState.concat(data.value.data.onCreateQuestion);
            });
          }
        },
      });
    };

    listenForNewQuestions();

    const listenForUpdatedQuestions = async () => {
      await API.graphql(
        graphqlOperation(onUpdateQuestion, variables)
      ).subscribe({
        next: (data) => {
          const newObject = data.value.data.onUpdateQuestion;

          setQuestions((prevState) => {
            if (prevState.some(({ id }) => id === newObject.id)) {
              return prevState.map((item) =>
                item.id === newObject.id ? newObject : item
              );
            } else {
              return prevState;
            }
          });
        },
      });
    };

    listenForUpdatedQuestions();

    const listenForDeletedQuestions = async () => {
      await API.graphql(
        graphqlOperation(onDeleteQuestion, variables)
      ).subscribe({
        next: (data) => {
          setQuestions((prevState) => {
            const dataObject = data.value.data.onDeleteQuestion;

            if (prevState.some(({ id }) => id === dataObject.id)) {
              return prevState.filter(({ id }) => id !== dataObject.id);
            } else {
              return prevState;
            }
          });
        },
      });
    };

    listenForDeletedQuestions();

    const listenForUpdatedAnswers = async () => {
      await API.graphql(graphqlOperation(onUpdateAnswer, variables)).subscribe({
        next: (data) => {
          const newObject = data.value.data.onUpdateAnswer;

          setQuestions((prevState) => {
            if (prevState.some((item) => item.answer.id === newObject.id)) {
              return prevState.map((item) => {
                return item.answer.id === newObject.id
                  ? { ...item, answer: newObject }
                  : item;
              });
            } else {
              return prevState;
            }
          });
        },
      });
    };

    listenForUpdatedAnswers();
  }, [filmID]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (questions.length > 0 && currentRecords.length === 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentRecords, currentPage, questions]);

  const handleNext = async () => {
    await API.graphql({
      query: questionsByDate,
      variables: {
        limit: recordsPerPage,
        vodAssetId: filmID,
        sortDirection: "ASC",
        nextToken: nextToken,
      },
    }).then((result) => {
      setQuestions((prevState) =>
        prevState.concat(result.data.questionsByDate.items)
      );
      if (nextToken) {
        setNextToken(result.data.questionsByDate.nextToken);
      } else {
        setNextToken(null);
      }
      setCurrentPage(currentPage + 1);
    });
  };

  const handleQuestionSubmit = async (event) => {
    event.preventDefault();

    if (question) {
      const questionAsset = {
        input: {
          vodAssetId: filmID,
          content: question,
          name: user.attributes.name,
          picture: user.attributes.picture,
        },
      };

      await API.graphql(graphqlOperation(createQuestion, questionAsset))
        .then(() => {
          setQuestion("");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChange = (event) => {
    setQuestion(event.target.value);
  };

  const showQuestions = () => {
    if (questions.length !== 0) {
      return (
        <div className="mt-4">
          <div className="d-flex justify-content-between">
            <Paginate
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              nextToken={nextToken}
              handleNext={handleNext}
            />
            <p className="text-start mb-0 align-self-center">
              {currentRecords.length === 1
                ? `${currentRecords.length} Question`
                : `${currentRecords.length} Questions`}
            </p>
          </div>
          {currentRecords.map((value, index) => {
            return (
              <div
                {...(index === currentRecords.length - 1 && {
                  className: "mb-3",
                })}
                key={index}
              >
                <Question
                  s3url={s3url}
                  data={value}
                  username={user.username}
                  fullName={user.attributes.name}
                  groups={groups}
                  director={director}
                  filmID={filmID}
                />
              </div>
            );
          })}
        </div>
      );
    }
    return <p className="mt-4">No Questions Yet!</p>;
  };

  return (
    <div className="mt-2">
      <h3 className="text-start">Ask the Filmmaker</h3>
      {user.attributes.name !== caps(director) && (
        <Form onSubmit={handleQuestionSubmit} id="question-submit">
          <Form.Control
            as="textarea"
            rows={4}
            name="question"
            value={question}
            onChange={handleChange}
            placeholder="Ask question"
            className="mt-2"
          />
          <div className="text-end pt-3">
            <Button
              variant="grad"
              form="question-submit"
              type="submit"
              disabled={question === ""}
            >
              Post Question
            </Button>
          </div>
        </Form>
      )}

      {showQuestions()}
    </div>
  );
}

export default AskTheFilmmaker;
