import React, { useRef, useEffect } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-playlist-ui/dist/videojs-playlist-ui.css";
import "videojs-playlist";
import "videojs-playlist-ui";
import "./PlayAllPlayer.css";

function PlayAllPlayer(props) {
  const { settings, sources } = props;

  const player = useRef(null);
  const videoNode = useRef(null);

  useEffect(() => {
    player.current = videojs(videoNode.current, settings);

    player.current.playlist(sources);

    player.current.playlistUi();

    player.current.playlist.autoadvance(0);

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main-preview-player">
      <div data-vjs-player>
        <video ref={videoNode} className="video-js" />
      </div>
      <div className="playlist-container vjs-fluid">
        <ol className="vjs-playlist"></ol>
      </div>
    </div>
  );
}

export default PlayAllPlayer;
