import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Button, Form, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import NotificationContext from "../../../context/NotificationContext";
import Notification from "../../Notification/Notification";

function Contact() {
  const notificationCtx = useContext(NotificationContext);

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [sending, setSending] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      notificationCtx.clear();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const sendEmail = async (event) => {
    event.preventDefault();

    const templateParams = {
      to_name: "Anthony",
      from_name: formState.name,
      reply_to: formState.email,
      message: formState.message,
    };

    setSending(true);

    await emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          setSuccess(true);
        },
        (error) => {
          notificationCtx.error(error.text);
          setSending(false);
        }
      );
  };

  return (
    <div>
      <Container style={{ height: "70vh" }}>
        <Row>
          <h1 style={{ color: "#9f1f63" }}>Contact</h1>
        </Row>
        <Row>
          <div className="d-flex justify-content-center">
            <hr
              className="py-1 w-25 opacity-100"
              style={{ color: "#9f1f63", borderTop: "8px solid" }}
            />
          </div>
        </Row>
        {success ? (
          <>
            <Row>
              <h5 className="text-white fw-light">
                Email Sent Successfully! You will receive a response as soon as
                possible.
              </h5>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <h5 className="text-white fw-light">
                For any questions or comments, please fill out the form below:
              </h5>
            </Row>
            <Row>
              <Col className="col-md-6 mx-auto">
                <Form onSubmit={sendEmail}>
                  <Notification />
                  <Form.Group className="text-start text-white mb-2">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      placeholder="Enter Name"
                      name="name"
                      value={formState.name}
                      onChange={handleChange}
                      disabled={sending}
                      readOnly={sending}
                    />
                  </Form.Group>
                  <Form.Group className="text-start text-white mb-2">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email Address"
                      name="email"
                      value={formState.email}
                      onChange={handleChange}
                      disabled={sending}
                      readOnly={sending}
                    />
                  </Form.Group>
                  <Form.Group className="text-start text-white mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter Message"
                      name="message"
                      value={formState.message}
                      onChange={handleChange}
                      disabled={sending}
                      readOnly={sending}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      variant="grad"
                      size="lg"
                      value="Send"
                      disabled={
                        formState.name === "" ||
                        formState.email === "" ||
                        formState.message === "" ||
                        sending
                      }
                    >
                      {sending ? "Sending..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}

export default Contact;
