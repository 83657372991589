import React from "react";
import { Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCapitalize from "../../../Hooks/useCapitalize";

function FilmmakerCardView(props) {
  const { s3url, filmmakers } = props;

  const caps = useCapitalize;

  return filmmakers.map((value) => {
    return (
      <Col key={value.id} className="col-md-4 my-4">
        <Card className="h-100" text="dark">
          <Card.Img
            variant="top"
            src={`${s3url}${value.image}`}
            style={{ "--width": "100%" }}
          />
          <Card.Body>
            <Card.Title style={{ fontSize: "30px" }}>
              {caps(value.name)}
            </Card.Title>
            <Card.Subtitle>{value.location}</Card.Subtitle>
            <Card.Text className="mt-4">{value.bio}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button
              as={Link}
              state={{
                name: value.name,
                id: value.id,
              }}
              to={`/filmmaker/${value.name
                .replaceAll("#", "")
                .replaceAll(" ", "-")
                .toLowerCase()}`}
              variant="grad"
            >
              Learn More
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    );
  });
}

export default FilmmakerCardView;
