/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDirector = /* GraphQL */ `
  query GetDirector($id: ID!) {
    getDirector(id: $id) {
      id
      name
      bio
      location
      image
      homePage
      videos {
        items {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDirectors = /* GraphQL */ `
  query ListDirectors(
    $filter: ModelDirectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDirectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVodAsset = /* GraphQL */ `
  query GetVodAsset($id: ID!) {
    getVodAsset(id: $id) {
      id
      title
      director
      directorID
      description
      year
      runtime
      genre
      star1
      star2
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      headerImage
      cardImage
      poster
      specialFeature
      specialFeatureVideo
      specialFeatureText {
        items {
          id
          filmID
          filmTitle
          title
          author
          body
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      specialFeaturePhoto
      homePage
      playlists {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vodAssetVideoId
      __typename
    }
  }
`;
export const listVodAssets = /* GraphQL */ `
  query ListVodAssets(
    $filter: ModelVodAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      label
      films {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      directors {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFilmText = /* GraphQL */ `
  query GetFilmText($id: ID!) {
    getFilmText(id: $id) {
      id
      filmID
      filmTitle
      title
      author
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFilmTexts = /* GraphQL */ `
  query ListFilmTexts(
    $filter: ModelFilmTextFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilmTexts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filmID
        filmTitle
        title
        author
        body
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vodAssetId
        content
        picture
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideoObject = /* GraphQL */ `
  query GetVideoObject($id: ID!) {
    getVideoObject(id: $id) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVideoObjects = /* GraphQL */ `
  query ListVideoObjects(
    $filter: ModelVideoObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCarouselImage = /* GraphQL */ `
  query GetCarouselImage($id: ID!) {
    getCarouselImage(id: $id) {
      id
      image
      directorID
      directorName
      filmID
      title
      type
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCarouselImages = /* GraphQL */ `
  query ListCarouselImages(
    $filter: ModelCarouselImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarouselImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        directorID
        directorName
        filmID
        title
        type
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlaylist = /* GraphQL */ `
  query GetPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      name
      films {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPlaylists = /* GraphQL */ `
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTimeCode = /* GraphQL */ `
  query GetTimeCode($id: ID!) {
    getTimeCode(id: $id) {
      id
      filmID
      time
      type
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const listTimeCodes = /* GraphQL */ `
  query ListTimeCodes(
    $filter: ModelTimeCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filmID
        time
        type
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      answer {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      questionAnswerId
      owner
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vodAssetId
        content
        picture
        name
        createdAt
        answer {
          id
          content
          picture
          name
          vodAssetId
          createdAt
          updatedAt
          owner
          __typename
        }
        updatedAt
        questionAnswerId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      content
      picture
      name
      vodAssetId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDirectorTags = /* GraphQL */ `
  query GetDirectorTags($id: ID!) {
    getDirectorTags(id: $id) {
      id
      directorID
      tagID
      director {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDirectorTags = /* GraphQL */ `
  query ListDirectorTags(
    $filter: ModelDirectorTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDirectorTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        directorID
        tagID
        director {
          id
          name
          bio
          location
          image
          homePage
          latitude
          longitude
          createdAt
          updatedAt
          __typename
        }
        tag {
          id
          label
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlaylistFilms = /* GraphQL */ `
  query GetPlaylistFilms($id: ID!) {
    getPlaylistFilms(id: $id) {
      id
      vodAssetID
      playlistID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      playlist {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPlaylistFilms = /* GraphQL */ `
  query ListPlaylistFilms(
    $filter: ModelPlaylistFilmsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylistFilms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vodAssetID
        playlistID
        vodAsset {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        playlist {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFilmTags = /* GraphQL */ `
  query GetFilmTags($id: ID!) {
    getFilmTags(id: $id) {
      id
      vodAssetID
      tagID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFilmTags = /* GraphQL */ `
  query ListFilmTags(
    $filter: ModelFilmTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilmTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vodAssetID
        tagID
        vodAsset {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        tag {
          id
          label
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByDate = /* GraphQL */ `
  query CommentsByDate(
    $vodAssetId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByDate(
      vodAssetId: $vodAssetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vodAssetId
        content
        picture
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const timeCodesByDate = /* GraphQL */ `
  query TimeCodesByDate(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimeCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timeCodesByDate(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filmID
        time
        type
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionsByDate = /* GraphQL */ `
  query QuestionsByDate(
    $vodAssetId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByDate(
      vodAssetId: $vodAssetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vodAssetId
        content
        picture
        name
        createdAt
        answer {
          id
          content
          picture
          name
          vodAssetId
          createdAt
          updatedAt
          owner
          __typename
        }
        updatedAt
        questionAnswerId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
