import React from "react";
import { Button, Form } from "react-bootstrap";

function CreatePlaylist(props) {
  const { handleChange, showForm, toggleForm, saveList } = props;

  return (
    <div className="border pb-2">
      <div className="mx-2">
        <div>
          {showForm && (
            <Form className="mt-2">
              <Form.Control
                maxLength={25}
                placeholder="Name"
                onChange={handleChange}
              />
              <Form.Text className="text-white">Max: 25 Characters</Form.Text>
            </Form>
          )}
        </div>
        {showForm === false ? (
          <Button variant="grad" onClick={toggleForm} className="mt-2">
            Create New Playlist
          </Button>
        ) : (
          <div className="d-flex justify-content-between">
            <Button variant="grad" onClick={toggleForm} className="mt-2">
              Cancel
            </Button>
            <Button
              variant="grad"
              onClick={saveList}
              form="save-playlist"
              className="mt-2"
            >
              Save Playlist
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreatePlaylist;
