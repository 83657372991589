import React from "react";
import { ListGroup, Image, Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCapitalize from "../../../Hooks/useCapitalize";

function FilmmakerListView(props) {
  const { filmmakers, s3url } = props;

  const caps = useCapitalize;

  return (
    <ListGroup variant="flush" className="pe-0 mx-auto">
      {filmmakers.map((filmmaker, index) => {
        return (
          <ListGroup.Item key={index} className="bg-transparent text-white">
            <Container fluid>
              <Row>
                <Col className="my-auto" xs={6} sm={2}>
                  <div className="director-image">
                    <Image
                      src={`${s3url}${filmmaker.image}`}
                      style={{ width: "80%" }}
                    />
                  </div>
                </Col>
                <Col className="my-auto">
                  <h4>{caps(filmmaker.name)}</h4>
                  <p className="mb-0">{filmmaker.location}</p>
                </Col>
                <Col className="my-auto">
                  <div>
                    <p className="mb-0">{filmmaker.bio}</p>
                  </div>
                </Col>
                <Col className="my-auto col-sm-2">
                  <Button
                    as={Link}
                    state={{
                      name: filmmaker.name,
                      id: filmmaker.id,
                    }}
                    to={`/filmmaker/${filmmaker.name
                      .replaceAll("#", "")
                      .replaceAll(" ", "-")
                      .toLowerCase()}`}
                    variant="grad"
                  >
                    Learn More
                  </Button>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

export default FilmmakerListView;
