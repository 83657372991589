import React, { useState } from "react";
import { Modal, Form, Button, Container, Row, Image } from "react-bootstrap";
import { Amplify, API, graphqlOperation, Storage } from "aws-amplify";
import VideoPlayer from "../../../Pages/Video/VideoPlayer/VideoPlayer";
import awsvideo from "../../../../aws-video-exports";
import FilePicker from "../../FilePicker/FilePicker";
import { v4 as uuidv4 } from "uuid";
import * as mutations from "../../../../graphql/mutations";
import awsvideoconfig from "../../../../aws-video-exports";
import PopoverProgress from "../../PopoverProgress/PopoverProgress";

function SpecialFeaturePopover(props) {
  const {
    chosenItem,
    showComponent,
    popoverOnHide,
    chosenType,
    videos,
    s3url,
  } = props;

  const [state, setState] = useState({
    updatedTitle: null,
    option: null,
    progress: 0,
    newFile: null,
    textID: null,
    showDeleteAll: false,
  });

  const [isContentVisible, setIsContentVisible] = useState({});

  const handleChange = (event) => {
    setState({ ...state, updatedTitle: event.target.value });
  };

  const fileCallback = (dataFromChild) => {
    setState({
      ...state,
      newFile: dataFromChild,
    });
  };

  const updateItem = (element, event) => {
    event.preventDefault();

    if (chosenType === "video" && state.newFile) {
      const uuid = uuidv4();
      const videoObject = {
        input: {
          id: uuid,
        },
      };
      const region = Amplify._config.aws_project_region;

      API.graphql(
        graphqlOperation(mutations.deleteVideoObject, {
          input: { id: element.video.id },
        })
      );

      API.graphql(graphqlOperation(mutations.createVideoObject, videoObject))
        .then((response) => {
          API.graphql(
            graphqlOperation(mutations.updateVodAsset, {
              input: {
                id: element.id,
                ...(state.updatedTitle && { title: state.updatedTitle }),
                vodAssetVideoId: response.data.createVideoObject.id,
              },
            })
          );
        })
        .catch((err) => console.log(err));
      const fileExtension = state.newFile.name.toLowerCase().split(".");

      Storage.put(
        `${uuid}.${fileExtension[fileExtension.length - 1]}`,
        state.newFile,
        {
          progressCallback(progress) {
            setState({
              ...state,
              progress: (progress.loaded / progress.total) * 100,
            });
          },
          contentType: "video/*",
          bucket: awsvideoconfig.awsInputVideo,
          region,
          customPrefix: {
            public: "",
          },
        }
      )
        .then(() => {
          console.log(`Successfully Uploaded: ${uuid}`);
        })
        .catch((err) => console.log(`Error: ${err}`));
    } else if (chosenType === "video") {
      event.preventDefault();

      API.graphql(
        graphqlOperation(mutations.updateVodAsset, {
          input: {
            id: element.id,
            ...(state.updatedTitle && { title: state.updatedTitle }),
          },
        })
      );
    } else if (chosenType === "photo") {
      const elementIndex = chosenItem.specialFeaturePhoto.indexOf(element);
      const updatedVideo = {
        input: {
          id: chosenItem.id,
          specialFeaturePhoto: chosenItem.specialFeaturePhoto.map(
            (item, index) =>
              index === elementIndex ? state.newFile.name : item
          ),
        },
      };
      Storage.put(state.newFile.name, state.newFile).then((response) => {
        console.log(`Successfully Uploaded: ${response.key}`);
        API.graphql(graphqlOperation(mutations.updateVodAsset, updatedVideo));
      });
    } else if (chosenType === "text") {
      const elementIndex = chosenItem.specialFeatureText.indexOf(element);
      const updatedVideo = {
        input: {
          id: chosenItem.id,
          specialFeatureText: chosenItem.specialFeatureText.map((item, index) =>
            index === elementIndex ? state.textID : item
          ),
        },
      };
      API.graphql(graphqlOperation(mutations.updateVodAsset, updatedVideo));
    }
  };

  const deleteItem = (element) => {
    if (chosenType === "video") {
      API.graphql(
        graphqlOperation(mutations.deleteVideoObject, {
          input: {
            id: element.video.id,
          },
        })
      )
        .then((response, error) => {
          if (error === undefined) {
            API.graphql(
              graphqlOperation(mutations.deleteVodAsset, {
                input: {
                  id: element.id,
                },
              })
            );

            const updatedVideo = {
              input: {
                id: chosenItem.id,
                ...(chosenItem.specialFeatureVideo.length === 1
                  ? {
                      specialFeatureVideo: null,
                    }
                  : {
                      specialFeatureVideo:
                        chosenItem.specialFeatureVideo.filter(
                          (item) => item !== element.id
                        ),
                    }),
              },
            };
            API.graphql(
              graphqlOperation(mutations.updateVodAsset, updatedVideo)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (chosenType === "photo") {
      const removedPhoto = {
        input: {
          id: chosenItem.id,
          ...(chosenItem.specialFeaturePhoto.length === 1
            ? {
                specialFeaturePhoto: null,
              }
            : {
                specialFeaturePhoto: chosenItem.specialFeaturePhoto.filter(
                  (item) => item !== element
                ),
              }),
        },
      };
      API.graphql(graphqlOperation(mutations.updateVodAsset, removedPhoto));
    } else if (chosenType === "text") {
      const removedText = {
        input: {
          id: chosenItem.id,
          ...(chosenItem.specialFeatureText.length === 1
            ? {
                specialFeatureText: null,
              }
            : {
                specialFeatureText: chosenItem.specialFeatureText.filter(
                  (item) => item !== element
                ),
              }),
        },
      };
      API.graphql(graphqlOperation(mutations.updateVodAsset, removedText));
    }
  };

  const showOption = (element) => {
    if (state.option === "update") {
      if (chosenType === "video") {
        return (
          <div className="mb-3 border">
            <div className="">
              <Form.Group className="pl-3">
                <Form.Control
                  type="text"
                  value={state.updatedTitle || element.title}
                  name="title"
                  onChange={handleChange}
                  className="w-75"
                />
                <div className="pt-3">
                  <FilePicker type="video" callbackFromParent={fileCallback} />
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={(e) => updateItem(element, e)}
                    className="mt-2"
                  >
                    Submit
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>
        );
      } else if (chosenType === "photo") {
        return (
          <div className="mb-3 border">
            <div className="py-3 ps-3">
              <FilePicker type="image" callbackFromParent={fileCallback} />
              <Button
                variant="dark"
                size="sm"
                onClick={(e) => updateItem(element, e)}
              >
                Submit
              </Button>
            </div>
          </div>
        );
      } else if (chosenType === "text") {
        return (
          <div className="mb-3 border">
            <div className="py-3 px-3">
              {/* <AmplifyS3TextPicker fileToKey={fileToKey} /> */}
              <Button
                variant="dark"
                size="sm"
                onClick={(e) => updateItem(element, e)}
              >
                Submit
              </Button>
            </div>
          </div>
        );
      }
    } else if (state.option === "delete") {
      return (
        <div className="mb-3 border">
          <div className="py-3 px-3">
            <Container>
              <Row>
                <div className="d-flex justify-content-center">
                  <p>Are you sure you want to delete this {chosenType}?</p>
                </div>
              </Row>
              <Row>
                <div className="d-flex justify-content-around">
                  <Button
                    onClick={() => deleteItem(element)}
                    variant="dark"
                    size="sm"
                    className="mx-3"
                  >
                    Yes
                  </Button>
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() =>
                      setIsContentVisible({
                        ...isContentVisible,
                        [element.id]: false,
                      })
                    }
                    className="mx-3"
                  >
                    No
                  </Button>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  };

  const showContent = (element, event) => {
    if (chosenType === "video") {
      setIsContentVisible({
        ...isContentVisible,
        [element.id]: true,
      });

      if (isContentVisible[element.id]) {
        setIsContentVisible({
          ...isContentVisible,
          [element.id]: false,
        });
      }
    } else {
      setIsContentVisible({
        ...isContentVisible,
        [element]: true,
      });

      if (isContentVisible[element]) {
        setIsContentVisible({
          ...isContentVisible,
          [element]: false,
        });
      }
    }

    if (event.target.name === "update") {
      setState({ ...state, option: "update" });
    } else if (event.target.name === "delete") {
      setState({ ...state, option: "delete" });
    }
  };

  const showOptions = (element) => {
    return (
      <div>
        <Form>
          {chosenType === "video"
            ? isContentVisible[element.id] && showOption(element)
            : isContentVisible[element] && showOption(element)}
          <Form.Group>
            <Button
              name="update"
              variant="outline-dark"
              size="sm"
              onClick={(e) => showContent(element, e)}
              className="mx-1"
            >
              Update
            </Button>
            <Button
              name="delete"
              variant="outline-dark"
              size="sm"
              className="mx-1"
              onClick={(e) => showContent(element, e)}
            >
              Delete
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  };

  const deleteAll = (type) => {
    if (type === "video") {
      videos.forEach((element) => {
        API.graphql(
          graphqlOperation(
            (mutations.deleteVideoObject, { input: { id: element.video } })
          )
        ).then(() => {
          API.graphql(
            graphqlOperation(mutations.deleteVodAsset, {
              input: { id: element.id },
            })
          );
        });
      });
    }

    const updatedAsset = {
      input: {
        id: chosenItem.id,
        [`specialFeature${type}`]: null,
      },
    };

    API.graphql(graphqlOperation(mutations.updateVodAsset, updatedAsset)).then(
      () => {
        setState({
          ...state,
          showDeleteAll: false,
        });
        popoverOnHide();
      }
    );
  };

  const deleteAllOptions = (type) => {
    return (
      <div className="mb-3 border">
        <div className="py-3 px-3">
          <Container>
            <Row>
              <div className="d-flex justify-content-center">
                <p>Are you sure you want to delete all?</p>
              </div>
            </Row>
            <Row>
              <div className="d-flex justify-content-around">
                <Button
                  variant="dark"
                  size="sm"
                  className="mx-3"
                  onClick={() => deleteAll(type)}
                >
                  Yes
                </Button>
                <Button
                  variant="dark"
                  size="sm"
                  className="mx-3"
                  onClick={toggleDeleteAll}
                >
                  No
                </Button>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  const toggleDeleteAll = () => {
    setState({
      ...state,
      showDeleteAll: !state.showDeleteAll,
    });
  };

  const overlayInfo = () => {
    if (chosenType === "video") {
      return (
        <div>
          <Modal show={showComponent} onHide={popoverOnHide}>
            <Modal.Header closeButton>
              {chosenItem.title} - Special Feature Videos
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="outline-dark"
                size="sm"
                className="mb-3"
                onClick={toggleDeleteAll}
              >
                Delete All
              </Button>
              {state.showDeleteAll && deleteAllOptions("Video")}
              {videos &&
                videos.map((element, index) => {
                  return (
                    <div key={index} className="mb-4">
                      <VideoPlayer
                        controls
                        fluid
                        sources={[
                          {
                            src: `https://${awsvideo.awsOutputVideo}/${element.video.id}/${element.video.id}.m3u8`,
                            type: "application/x-mpegURL",
                          },
                        ]}
                        bigPlayButton={true}
                        pictureInPictureToggle={false}
                      />
                      <p>{element.title}</p>
                      <div className="flex-grow-1">
                        <PopoverProgress progress={state.progress} />
                      </div>
                      {showOptions(element)}
                    </div>
                  );
                })}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (chosenType === "photo") {
      return (
        <div>
          <Modal show={showComponent} onHide={popoverOnHide}>
            <Modal.Header closeButton>
              {chosenItem.title} - Special Feature Photos
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="outline-dark"
                size="sm"
                className="mb-3"
                onClick={toggleDeleteAll}
              >
                Delete All
              </Button>
              {state.showDeleteAll && deleteAllOptions("Photo")}
              {chosenItem.specialFeaturePhoto &&
                chosenItem.specialFeaturePhoto.map((element, index) => {
                  return (
                    <div key={index} className="mb-4">
                      <Image
                        src={`${s3url}${element}`}
                        style={{ "--width": "100%" }}
                      />
                      {showOptions(element)}
                    </div>
                  );
                })}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (chosenType === "text") {
      return (
        <div>
          <Modal show={showComponent} onHide={popoverOnHide} size="xl">
            <Modal.Header closeButton>
              {chosenItem.title} - Special Feature Text
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="outline-dark"
                size="sm"
                className="mb-3"
                onClick={toggleDeleteAll}
              >
                Delete All
              </Button>
              {state.showDeleteAll && deleteAllOptions("Text")}
              {chosenItem.specialFeatureText &&
                chosenItem.specialFeatureText.map((element, index) => {
                  return (
                    <div key={index} className="mb-4">
                      {/* <AmplifyS3Text textKey={element} /> */}
                      {showOptions(element)}
                    </div>
                  );
                })}
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  };

  return <div>{overlayInfo()}</div>;
}

export default SpecialFeaturePopover;
