import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { API, Auth } from "aws-amplify";

function PauseSubscription(props) {
  const { user, setToggle } = props;

  const [selection, setSelection] = useState(1);

  const [pausing, setPausing] = useState(false);

  const subscriptionId = user.attributes["custom:SubscriptionId"];
  const expireTime = new Date(user.attributes["custom:expireTime"]);

  const handleSelect = (event) => {
    setSelection(event.target.value);
  };

  const addMonths = (date, months) => {
    let dateCopy = new Date(date);

    dateCopy.setMonth(dateCopy.getMonth() + months);

    return dateCopy;
  };

  const handlePause = async () => {
    setPausing(true);
    const newDate = addMonths(expireTime, selection);

    const timeStamp = newDate.getTime() / 1000;

    const body = {
      subscriptionId: subscriptionId,
      timeStamp: timeStamp,
    };

    await API.post("apic5c1ed0b", "/pause-subscription", { body }).then(
      async (res) => {
        const user = await Auth.currentAuthenticatedUser();
        const resumeTime = new Date(res.pause_collection.resumes_at * 1000);
        await Auth.updateUserAttributes(user, {
          "custom:subscriptionStatus": "paused",
          "custom:resumeTime": resumeTime,
        }).then(() => {
          setToggle(false);
        });
      }
    );
  };

  return (
    <div className="text-white">
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>How long would you like to pause?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">You can choose to resume at any time.</p>
        <Form.Select onChange={handleSelect}>
          <option value={1}>1 Month</option>
          <option value={2}>2 Months</option>
          <option value={3}>3 Months</option>
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            variant="grad"
            size="lg"
            onClick={handlePause}
            disabled={pausing}
          >
            {pausing ? "Pausing..." : "Pause"}
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
}

export default PauseSubscription;
