import React from "react";
import { Carousel, Image } from "react-bootstrap";

function FilmPhotoCarousel(props) {
  const { photos, s3url } = props;

  return (
    <div className="mt-2 pb-4">
      <h4 className="text-start pb-2">Photos</h4>
      <Carousel interval={null}>
        {photos.map((element, index) => {
          return (
            <Carousel.Item
              key={index}
              className="w-100"
              style={{ height: "20rem" }}
            >
              <Image
                src={`${s3url}${element}`}
                style={{ objectFit: "contain" }}
                className="w-100 h-100"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default FilmPhotoCarousel;
