import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import {
  Container,
  Row,
  Button,
  Fade,
  ButtonToolbar,
  Dropdown,
} from "react-bootstrap";
import FilmmakersBreadcrumbs from "./FilmmakersBreadcrumbs/FilmmakersBreadcrumbs";
import FilmmakerCardView from "./FilmmakerViews/FilmmakerCardView";
import FilmmakerListView from "./FilmmakerViews/FilmmakerListView";
import FilmmakerMapView from "./FilmmakerViews/FilmmakerMapView/FilmmakerMapView";
import { Helmet } from "react-helmet";

function Filmmakers(props) {
  const { s3url } = props;
  const [state, setState] = useState({
    items: [],
    nextToken: "",
  });

  const [open, setOpen] = useState(false);

  const [view, setView] = useState("Card");

  useEffect(() => {
    const getAssets = async () => {
      return await API.graphql(graphqlOperation(queries.listDirectors));
    };

    getAssets().then((response) => {
      let { nextToken } = response.data.listDirectors;
      if (nextToken === undefined) {
        nextToken = "";
      }

      setState((prevState) => ({
        ...prevState,
        items: response.data.listDirectors.items,
        nextToken,
      }));

      setOpen(true);
    });
  }, []);

  const switchView = (view) => {
    setView(view);
  };

  const renderView = () => {
    switch (view) {
      case "Card":
        return <FilmmakerCardView filmmakers={state.items} s3url={s3url} />;
      case "List":
        return <FilmmakerListView filmmakers={state.items} s3url={s3url} />;
      case "Map":
        return <FilmmakerMapView filmmakers={state.items} s3url={s3url} />;
      default:
        break;
    }
  };

  return (
    <div className="min-vh-100">
      <Helmet>
        <title>Iris - Filmmakers</title>
      </Helmet>
      <FilmmakersBreadcrumbs />
      <Fade in={open}>
        <Container>
          <Row>
            <ButtonToolbar
              className="py-2"
              style={{ backgroundColor: "#151515" }}
            >
              <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                  {view}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {view !== "Card" && (
                    <Dropdown.Item
                      as={Button}
                      onClick={() => switchView("Card")}
                      variant="black"
                    >
                      Card
                    </Dropdown.Item>
                  )}
                  {view !== "Map" && (
                    <Dropdown.Item
                      as={Button}
                      onClick={() => switchView("Map")}
                      variant="black"
                    >
                      Map
                    </Dropdown.Item>
                  )}
                  {view !== "List" && (
                    <Dropdown.Item
                      as={Button}
                      onClick={() => switchView("List")}
                      variant="black"
                    >
                      List
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonToolbar>
          </Row>
          <Row className="d-flex flex-column flex-fill flex-md-row border">
            {renderView()}
          </Row>
        </Container>
      </Fade>
    </div>
  );
}

export default Filmmakers;
