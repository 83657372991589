import React from "react";
import { Routes, Route } from "react-router-dom";

import Landing from "./Landing/Landing";
import Subscription from "./Subscription/Subscription";

import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function SignUp(props) {
  const { signIn, error, signInLoading, setFormState, setUserState, setError } =
    props;
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Landing
            signIn={signIn}
            error={error}
            setError={setError}
            signInLoading={signInLoading}
          />
        }
      />
      <Route
        path="/subscription"
        element={
          <Subscription
            stripe={stripe}
            setFormState={setFormState}
            setUserState={setUserState}
          />
        }
      />
    </Routes>
  );
}

export default SignUp;
