import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getVodAsset,
  listTimeCodes,
  listPlaylists,
  getTag,
  getPlaylist,
} from "../../../graphql/queries";
import {
  createPlaylistFilms,
  createTimeCode,
  deleteTimeCode,
  updateTimeCode,
} from "../../../graphql/mutations";
import { API, graphqlOperation, Analytics } from "aws-amplify";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Image,
  Fade,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./Film.css";
import "video.js/dist/video-js.css";
import awsvideo from "../../../aws-video-exports";
import VideoPlayer from "../Video/VideoPlayer/VideoPlayer";
import Comments from "./Comments/Comments";
import SpecialFeatures from "./SpecialFeatures/SpecialFeatures";
import FilmBreadcrumbs from "./FilmBreadcrumbs/FilmBreadcrumbs";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCapitalize from "../../Hooks/useCapitalize";
import AskTheFilmmaker from "./AskTheFilmmaker/AskTheFilmmaker";

function Film(props) {
  const { user, s3url } = props;
  const location = useLocation();
  const caps = useCapitalize;
  const { filmTitle } = useParams();
  const { id, title, name, directorId } = location.state;

  const ref = useRef(null);
  const playerRef = useRef(null);

  const [filmState, setFilmState] = useState({
    displayingMovie: false,
    item: {},
    sources: [],
  });

  const [height, setHeight] = useState(0);

  const [playlists, setPlaylists] = useState([]);

  const [open, setOpen] = useState(false);

  const [timecode, setTimecode] = useState(null);

  const [similarFilms, setSimilarFilms] = useState([]);

  useEffect(() => {
    setSimilarFilms([]);
    setTimecode(null);
    setOpen(false);

    Promise.all([
      API.graphql(
        graphqlOperation(getVodAsset, {
          id: id,
        })
      ),
      API.graphql(graphqlOperation(listPlaylists)),
      API.graphql(
        graphqlOperation(listTimeCodes, {
          filter: {
            filmID: {
              eq: id,
            },
          },
        })
      ),
    ]).then(async (values) => {
      setFilmState((prevState) => ({
        ...prevState,
        item: values[0].data.getVodAsset,
      }));

      const filmIDs = await Promise.all(
        values[0].data.getVodAsset.tags.items.map(async (item) => {
          const tag = await API.graphql(
            graphqlOperation(getTag, { id: item.tagID })
          );

          return tag.data.getTag.films.items.map((t) => t.vodAssetID);
        })
      );

      const randomIDs = filmIDs
        .flat()
        .filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        )
        .filter((item) => item !== id)
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);

      randomIDs.forEach(async (id) => {
        await API.graphql(graphqlOperation(getVodAsset, { id: id })).then(
          (res) => {
            setSimilarFilms((prevState) => {
              return [...prevState, res.data.getVodAsset];
            });
          }
        );
      });

      const playlists = values[1].data.listPlaylists.items;

      playlists.forEach(async (playlist) => {
        await API.graphql(
          graphqlOperation(getPlaylist, { id: playlist.id })
        ).then((p) => {
          setPlaylists((prevState) => {
            return prevState.concat(p.data.getPlaylist);
          });
        });
      });

      const timeCode = values[2].data.listTimeCodes.items[0];

      if (timeCode) {
        setTimecode(timeCode);
      }

      setOpen(true);
    });
  }, [filmTitle]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  const hideMovie = () => {
    setFilmState({
      ...filmState,
      displayingMovie: false,
    });
  };

  const displayMovie = () => {
    Analytics.record({
      name: "movieClick",
      attributes: { id: filmState.item.video.id },
    });

    setFilmState({
      ...filmState,
      sources: [
        {
          src: `https://${awsvideo.awsOutputVideo}/${filmState.item.video.id}/${filmState.item.video.id}.m3u8`,
          type: "application/x-mpegURL",
        },
      ],
      displayingMovie: true,
    });
  };

  const overlayMovie = () => {
    const videoOptions = {
      controls: true,
      sources: filmState.sources,
      bigPlayButton: false,
      controlBar: {
        children: {
          playToggle: true,
          volumeControl: true,
          progressControl: true,
          fullscreenToggle: true,
        },
      },
      autoplay: true,
      fluid: true,
    };

    const handlePlayerReady = (player) => {
      playerRef.current = player;

      player.on("loadedmetadata", () => {
        timecode && player.currentTime(timecode.time);
      });

      player.on("dispose", async () => {
        if (player.currentTime() !== player.duration()) {
          if (timecode) {
            setTimecode((prevState) => ({
              ...prevState,
              time: player.currentTime(),
            }));

            const updatedTimeCode = {
              input: {
                id: timecode.id,
                time: player.currentTime(),
              },
            };

            await API.graphql(
              graphqlOperation(updateTimeCode, updatedTimeCode)
            );
          } else {
            const timeCodeObject = {
              input: {
                filmID: filmState.item.id,
                type: "TimeCode",
                time: player.currentTime(),
              },
            };

            await API.graphql(graphqlOperation(createTimeCode, timeCodeObject))
              .then((res) => {
                setTimecode(res.data.createTimeCode);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });

      player.on("ended", async () => {
        hideMovie();

        if (timecode) {
          setTimecode(null);
          const timeCodeDets = {
            input: {
              id: timecode.id,
            },
          };

          await API.graphql(graphqlOperation(deleteTimeCode, timeCodeDets));
        }
      });
    };

    return (
      <Modal
        size="xl"
        show={filmState.displayingMovie}
        onHide={hideMovie}
        centered
      >
        <VideoPlayer
          options={videoOptions}
          onReady={handlePlayerReady}
          timecode={timecode}
        />
      </Modal>
    );
  };

  const addToPlaylist = async (film, playlist) => {
    const newPlaylistFilms = {
      input: {
        vodAssetID: film.id,
        playlistID: playlist.id,
      },
    };

    try {
      await API.graphql(
        graphqlOperation(createPlaylistFilms, newPlaylistFilms)
      ).then((res) => {
        setPlaylists(
          playlists.map((list) => {
            if (list.id === playlist.id) {
              const { films, ...nofilms } = list;
              return {
                ...nofilms,
                films: {
                  items: films.items.concat(res.data.createPlaylistFilms),
                },
              };
            }
            return list;
          })
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showFilm = () => {
    if (filmState.item !== undefined && filmState.item.headerImage) {
      return (
        <Container>
          <Row className="my-5 d-flex flex-column-reverse flex-fill flex-sm-row">
            <Col className="my-auto text-start">
              <div className="d-inline-block w-auto">
                <h1>{caps(filmState.item.title)}</h1>
                <hr
                  className="mt-1 opacity-100 w-75 text-white"
                  style={{ borderTop: "8px solid" }}
                />
              </div>
              <p>
                <b>Directed by {caps(filmState.item.director)}</b> |{" "}
                {filmState.item.year} | {filmState.item.genre} |{" "}
                {filmState.item.runtime} minutes
              </p>
              <p>
                <i>{filmState.item.description}</i>
              </p>
              <p>
                {filmState.item.star1 && filmState.item.star2
                  ? `Starring ${filmState.item.star1}, ${filmState.item.star2}`
                  : filmState.item.star1
                  ? `Starring ${filmState.item.star1}`
                  : ""}
              </p>
              <div className="d-flex justify-content-start align-items-center">
                <Button
                  onClick={() => displayMovie()}
                  variant="header"
                  size="lg"
                >
                  <FontAwesomeIcon icon={faPlay} className="mx-2" />
                  {timecode ? "Resume" : "Play"}
                </Button>
                <div className="ms-4 ">
                  <p className="mb-0 text-center" style={{ opacity: "80%" }}>
                    Add to Playlist
                  </p>
                  <DropdownButton
                    variant="outline-light"
                    size="sm"
                    title="Select Playlist"
                    drop="up"
                  >
                    <Dropdown.Item
                      as={Link}
                      to={"/playlists"}
                      key={playlists.length + 1}
                      className="dropdown-dark"
                    >
                      Create Playlist
                    </Dropdown.Item>
                    {playlists.length > 0 &&
                      playlists.map((playlist, index) => {
                        if (
                          playlist.films.items.some(
                            (element) =>
                              element.vodAssetID === filmState.item.id
                          )
                        ) {
                          return null;
                        }
                        return (
                          <Dropdown.Item
                            as={Button}
                            variant="black"
                            key={index}
                            onClick={() => {
                              addToPlaylist(filmState.item, playlist);
                            }}
                          >
                            {playlist.name}
                          </Dropdown.Item>
                        );
                      })}
                  </DropdownButton>
                </div>
              </div>
            </Col>
            <Col className="mb-4">
              <div style={{ transform: "rotate(-5deg)" }}>
                <Image
                  src={`${s3url}${filmState.item.headerImage}`}
                  style={{ width: "70%" }}
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col className="text-start">
              <SpecialFeatures
                specialFeatureVideos={filmState.item.specialFeatureVideo}
                specialFeatureImages={filmState.item.specialFeaturePhoto}
                specialFeatureText={filmState.item.specialFeatureText.items}
                s3url={s3url}
              />
            </Col>
            <div className="vr px-0 mx-2" style={{ opacity: "100%" }}></div>
            <Col>
              <Tabs defaultActiveKey="users" fill>
                <Tab eventKey="users" title="Comments">
                  <Comments
                    height={height}
                    s3url={s3url}
                    user={user}
                    filmID={filmState.item.id}
                  />
                </Tab>
                <Tab eventKey="filmmaker" title="Ask the Filmmaker">
                  <AskTheFilmmaker
                    height={height}
                    s3url={s3url}
                    user={user}
                    filmID={filmState.item.id}
                    director={filmState.item.director}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  return (
    <div className="min-vh-100" ref={ref}>
      <Helmet>
        <title>{`Iris - ${caps(title)} - ${caps(name)}`}</title>
      </Helmet>
      <FilmBreadcrumbs title={title} director={name} directorId={directorId} />
      <Fade in={open}>
        <div>
          {showFilm()}
          {overlayMovie()}
          {similarFilms.length > 0 && (
            <div className="pt-3 pb-5" style={{ backgroundColor: "#151515" }}>
              <Container>
                <Row>
                  <div className="w-auto">
                    <h1 className="d-inline-block" style={{ color: "#9f1f63" }}>
                      Similar Films
                    </h1>
                    <hr
                      className="mt-1 opacity-100 w-75"
                      style={{ color: "#9f1f63", borderTop: "8px solid" }}
                    />
                  </div>
                </Row>
                <Row className="d-flex flex-column flex-fill flex-md-row">
                  {similarFilms.map((vodAsset, index) => {
                    return (
                      <Col key={index} className="col-md-4 my-4">
                        <Card className="h-100">
                          <Card.Img
                            variant="top"
                            src={`${s3url}${vodAsset.cardImage}`}
                            style={{ "--width": "100%" }}
                          />

                          <Card.Body>
                            <Card.Title style={{ fontSize: "30px" }}>
                              {caps(vodAsset.title)}
                            </Card.Title>
                            <Card.Text style={{ fontSize: "20px" }}>
                              <b>Genre:</b> {vodAsset.genre}
                            </Card.Text>
                          </Card.Body>
                          <Card.Footer>
                            <Button
                              as={Link}
                              state={{
                                id: vodAsset.id,
                                title: vodAsset.title,
                                name: vodAsset.director,
                                directorId: vodAsset.directorID,
                              }}
                              to={`/filmmaker/${vodAsset.director
                                .replaceAll("#", "")
                                .replaceAll(" ", "-")
                                .toLowerCase()}/film/${vodAsset.title
                                .replaceAll(" ", "-")
                                .toLowerCase()}`}
                              variant="grad"
                            >
                              Watch Film
                            </Button>
                            <p style={{ opacity: "80%" }} className="mt-3 mb-1">
                              Add to Playlist
                            </p>
                            <DropdownButton
                              size="sm"
                              variant="outline-dark"
                              title="Select Playlist"
                              drop="up"
                            >
                              <Dropdown.Item
                                as={Link}
                                to={"/playlists"}
                                key={playlists.length + 1}
                                className="dropdown-dark"
                              >
                                Create Playlist
                              </Dropdown.Item>
                              {playlists.length > 0 &&
                                playlists.map((playlist, index) => {
                                  if (
                                    playlist.films.items.some(
                                      (element) =>
                                        element.vodAssetID === vodAsset.id
                                    )
                                  ) {
                                    return null;
                                  }
                                  return (
                                    <Dropdown.Item
                                      as={Button}
                                      key={index}
                                      variant="black"
                                      onClick={() => {
                                        addToPlaylist(vodAsset, playlist);
                                      }}
                                    >
                                      {playlist.name}
                                    </Dropdown.Item>
                                  );
                                })}
                            </DropdownButton>
                          </Card.Footer>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
}

export default Film;
