import React, { useState, useEffect } from "react";
import MainCarousel from "./MainCarousel/MainCarousel";
import {
  Container,
  Col,
  Row,
  Button,
  Image,
  Fade,
  Card,
} from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import useCapitalize from "../../Hooks/useCapitalize";

function Home(props) {
  const { s3url, user } = props;

  const [state, setState] = useState({
    director: {},
    film: {},
    carouselImages: [],
  });
  const [open, setOpen] = useState(false);

  const caps = useCapitalize;

  const [continueWatchingFilms, setContinueWatchingFilms] = useState([]);

  useEffect(() => {
    const variables = {
      filter: {
        homePage: {
          eq: true,
        },
      },
    };

    Promise.all([
      API.graphql(graphqlOperation(queries.listDirectors, variables)),
      API.graphql(graphqlOperation(queries.listVodAssets, variables)),
      API.graphql(graphqlOperation(queries.listCarouselImages)),
      API.graphql(
        graphqlOperation(queries.timeCodesByDate, {
          type: "TimeCode",
          limit: 3,
          sortDirection: "DESC",
        })
      ),
    ]).then((values) => {
      setState({
        director: values[0].data.listDirectors.items[0],
        film: values[1].data.listVodAssets.items[0],
        carouselImages: values[2].data.listCarouselImages.items,
      });

      const timeCodes = values[3].data.timeCodesByDate.items;

      if (timeCodes.length > 0) {
        const getAssets = async () => {
          for await (const t of timeCodes) {
            const film = await API.graphql(
              graphqlOperation(queries.getVodAsset, {
                id: t.filmID,
              })
            );

            setContinueWatchingFilms((prevState) => {
              return prevState.concat(film.data.getVodAsset);
            });
          }
        };

        getAssets().then(() => {
          setOpen(true);
        });
      } else {
        setOpen(true);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showDirector = () => {
    if (state.director !== undefined && state.director.image) {
      return (
        <div className="pt-3 pb-5" style={{ backgroundColor: "#151515" }}>
          <Container>
            <Row className="mb-3">
              <div className="text-start w-auto">
                <h1 className="d-inline-block" style={{ color: "#9f1f63" }}>
                  Featuring This Week
                </h1>
                <hr
                  className="py-1 opacity-100 w-75 mt-1"
                  style={{ color: "#9f1f63", borderTop: "8px solid" }}
                />
              </div>
            </Row>
            <Row className="d-flex flex-column flex-fill flex-sm-row">
              <Col>
                <div className="my-auto mx-auto director-image">
                  <Image
                    src={`${s3url}${state.director.image}`}
                    style={{ width: "80%" }}
                  />
                </div>
              </Col>
              <Col className="my-auto text-start">
                <div className="d-inline-block w-auto text-start mt-2">
                  <h3>{caps(state.director.name)}</h3>
                  <hr
                    className="py-1 w-75 opacity-100 mt-1 mb-2"
                    style={{ borderTop: "8px solid" }}
                  />
                </div>
                <div className="text-white text-start">
                  <h5 className=" fw-light">{state.director.bio}</h5>
                </div>
                <div className="text-start">
                  <Button
                    className="mt-2"
                    as={Link}
                    state={{ id: state.director.id, name: state.director.name }}
                    to={`/filmmaker/${state.director.name
                      .replaceAll("#", "")
                      .replaceAll(" ", "-")
                      .toLowerCase()}`}
                    variant="grad"
                    size="lg"
                  >
                    See Profile
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  };

  const showFilm = () => {
    if (state.film !== undefined && state.film.poster) {
      return (
        <>
          <Container>
            <Row className="mt-5 d-flex flex-column-reverse flex-fill flex-sm-row">
              <Col className="my-auto text-start">
                <div className="d-inline-block w-auto">
                  <h3>{caps(state.film.title)}</h3>
                  <hr
                    className="w-75 opacity-100 mt-1 mb-3"
                    style={{ borderTop: "8px solid" }}
                  />
                </div>
                <div>
                  <h5>
                    Directed By {caps(state.film.director)} | Genre:{" "}
                    {state.film.genre}
                  </h5>
                  <h5 className="fw-light">{state.film.description}</h5>
                </div>
                <div className="text-start">
                  <Button
                    className="mt-2 mb-5"
                    as={Link}
                    state={{
                      id: state.film.id,
                      title: state.film.title,
                      name: state.film.director,
                      directorId: state.film.directorID,
                    }}
                    to={`/filmmaker/${state.film.director
                      .replaceAll("#", "")
                      .replaceAll(" ", "-")
                      .toLowerCase()}/film/${state.film.title
                      .replaceAll(" ", "-")
                      .toLowerCase()}`}
                    variant="grad"
                    size="lg"
                  >
                    Learn More
                  </Button>
                </div>
              </Col>
              <Col className="mb-5">
                <div
                  className="my-auto d-inline-block"
                  style={{
                    transform: "rotate(10deg)",
                  }}
                >
                  <Image
                    src={`${s3url}${state.film.poster}`}
                    style={{ width: "50%" }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  };

  const renderCarousel = () => {
    if (state.carouselImages.length !== 0) {
      return (
        <MainCarousel s3url={s3url} carouselImages={state.carouselImages} />
      );
    }
  };

  const renderGreeting = () => {
    if (user) {
      return (
        <div className="text-center text-white py-5 mx-1">
          <h4 className="font-weight-bold">Welcome {user.attributes.name}!</h4>
          <h4 className="font-weight-light">
            Check out what's new in the community!
          </h4>
        </div>
      );
    }
  };

  const renderContinueWatching = () => {
    if (continueWatchingFilms.length > 0) {
      return (
        <div className="pt-3 pb-5" style={{ backgroundColor: "#151515" }}>
          <Container>
            <Row>
              <div className="text-start d-inline-block w-auto">
                <h1 style={{ color: "#9f1f63" }}>Continue Watching</h1>
                <hr
                  className="mt-1 opacity-100 w-75"
                  style={{ color: "#9f1f63", borderTop: "8px solid" }}
                />
              </div>
            </Row>
            <Row className="d-flex flex-column flex-fill flex-md-row">
              {continueWatchingFilms.map((value) => {
                return (
                  <Col className="col-md-4 my-4" key={value.id}>
                    <Card className="h-100">
                      <Card.Img
                        variant="top"
                        src={`${s3url}${value.cardImage}`}
                        style={{ "--width": "100%" }}
                      />

                      <Card.Body>
                        <Card.Title style={{ fontSize: "30px" }}>
                          {caps(value.title)}
                        </Card.Title>
                        <Card.Text style={{ fontSize: "20px" }}>
                          <b>Genre:</b> {value.genre}
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <Button
                          as={Link}
                          state={{
                            id: value.id,
                            title: value.title,
                            name: value.director,
                            directorId: value.directorID,
                          }}
                          to={`/filmmaker/${value.director
                            .replaceAll("#", "")
                            .replaceAll(" ", "-")
                            .toLowerCase()}/film/${value.title
                            .replaceAll(" ", "-")
                            .toLowerCase()}`}
                          variant="grad"
                        >
                          Watch Film
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      );
    }
  };

  return (
    <div className="min-vh-100">
      <Helmet>
        <title>Iris</title>
      </Helmet>
      <Fade in={open}>
        <div>
          {renderCarousel()}
          {renderGreeting()}
          {showDirector()}
          {showFilm()}
          {renderContinueWatching()}
        </div>
      </Fade>
    </div>
  );
}

export default Home;
