import React, { useReducer, useEffect } from "react";
import "./FilmUpload.css";
import { v4 as uuidv4 } from "uuid";
import FilePicker from "../FilePicker/FilePicker";
import PopoverProgress from "../PopoverProgress/PopoverProgress";
import { Amplify, API, graphqlOperation, Storage } from "aws-amplify";
import awsvideoconfig from "../../../aws-video-exports";
import * as mutations from "../../../graphql/mutations";
import FilmPopover from "./FilmPopover/FilmPopover";
import FilmTable from "./FilmTable/FilmTable";
import { Form, Button, Modal } from "react-bootstrap";

const initialState = {
  titleVal: "",
  descVal: "",
  directorVal: "",
  directorBioVal: "",
  directorLocationVal: "",
  directorImage: null,
  yearVal: 0,
  runtimeVal: 0,
  genreVal: "",
  star1Val: "",
  star2Val: "",
  poster: null,
  headerImage: null,
  cardImage: null,
  progress: 0,
  file: null,
  fileName: null,
  chosenItem: null,
  displayingItem: false,
  displayingForm: false,
  action: "",
  directorType: "new",
  chosenDirector: {},
  showComponent: false,
  validated: false,
  submitted: false,
};

function filmUploadReducer(state, action) {
  switch (action.type) {
    case "field": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "setFile": {
      return {
        ...state,
        file: action.payload,
        fileName: action.fileName,
      };
    }
    case "setProgress": {
      return {
        ...state,
        progress: (action.loaded / action.total) * 100,
      };
    }
    case "setAction": {
      return {
        ...state,
        action: action.payload,
      };
    }
    case "setChosenItem": {
      return {
        ...state,
        chosenItem: action.payload,
        action: action.name,
      };
    }
    case "displayForm": {
      return {
        ...state,
        displayingForm: true,
      };
    }
    case "hideForm": {
      return {
        ...state,
        displayingForm: false,
      };
    }
    case "setDirectorType": {
      return {
        ...state,
        directorType: action.payload,
      };
    }
    case "setChosenDirector": {
      return {
        ...state,
        chosenDirector: action.payload,
      };
    }
    case "setImage": {
      return {
        ...state,
        [action.name]: {
          file: action.file,
          fileName: action.fileName,
        },
      };
    }
    case "showComponent": {
      return {
        ...state,
        showComponent: true,
        chosenItem: action.chosenItem,
        action: action.action,
      };
    }
    case "hideComponent": {
      return {
        ...state,
        showComponent: false,
      };
    }
    case "setValidated": {
      return {
        ...state,
        validated: action.payload,
      };
    }
    case "setSubmitted": {
      return {
        ...state,
        submitted: true,
      };
    }
    case "resetValues": {
      return {
        ...state,
        titleVal: "",
        descVal: "",
        directorVal: "",
        directorBioVal: "",
        directorLocationVal: "",
        directorImage: null,
        yearVal: 0,
        runtimeVal: 0,
        genreVal: "",
        star1Val: "",
        star2Val: "",
        poster: null,
        headerImage: null,
        cardImage: null,
        displayingForm: false,
        submitted: false,
        validated: false,
        directorType: "new",
        chosenDirector: action.payload,
      };
    }
    default:
      return state;
  }
}

function FilmUpload(props) {
  const { items, directors, s3url, tags } = props;

  const [state, dispatch] = useReducer(filmUploadReducer, initialState);
  const {
    titleVal,
    descVal,
    directorVal,
    directorBioVal,
    directorLocationVal,
    directorImage,
    yearVal,
    runtimeVal,
    genreVal,
    star1Val,
    star2Val,
    poster,
    headerImage,
    cardImage,
    file,
    fileName,
    progress,
    action,
    displayingForm,
    chosenItem,
    directorType,
    chosenDirector,
    showComponent,
    validated,
    submitted,
  } = state;

  useEffect(() => {
    dispatch({
      type: "setChosenDirector",
      payload: directors[0],
    });
  }, [directors]);

  const myCallback = (dataFromChild) => {
    dispatch({
      type: "setFile",
      payload: dataFromChild,
      fileName: dataFromChild.name,
    });
  };

  const imageCallback = (dataFromChild, imageType) => {
    dispatch({
      type: "setImage",
      name: imageType,
      file: dataFromChild,
      fileName: dataFromChild.name,
    });
  };

  const handleChange = (event) => {
    dispatch({
      type: "field",
      fieldName: event.target.name,
      payload: event.target.value,
    });
  };

  const submitFormHandler = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    dispatch({
      type: "setValidated",
      payload: true,
    });

    if (
      titleVal &&
      directorType === "new" &&
      directorVal &&
      directorBioVal &&
      directorLocationVal &&
      file
    ) {
      event.preventDefault();
      const uuid = uuidv4();
      const videoObject = {
        input: {
          id: uuid,
        },
      };
      const region = Amplify._config.aws_project_region;
      const newDirector = {
        input: {
          name: directorVal,
          bio: directorBioVal,
          location: directorLocationVal,
          ...(directorImage && { image: directorImage.fileName }),
        },
      };

      API.graphql(graphqlOperation(mutations.createDirector, newDirector)).then(
        (response, error) => {
          if (error === undefined) {
            if (directorImage) {
              Storage.put(directorImage.fileName, directorImage.file);
            }
            if (headerImage) {
              Storage.put(headerImage.fileName, headerImage.file);
            }

            if (cardImage) {
              Storage.put(cardImage.fileName, cardImage.file);
            }

            if (poster) {
              Storage.put(poster.fileName, poster.file);
            }
            API.graphql(
              graphqlOperation(mutations.createVideoObject, videoObject)
            ).then((res, err) => {
              if (err === undefined) {
                const fileExtension = fileName.toLowerCase().split(".");
                const videoAsset = {
                  input: {
                    title: titleVal,
                    director: directorVal,
                    directorID: response.data.createDirector.id,
                    year: yearVal,
                    runtime: runtimeVal,
                    genre: genreVal,
                    star1: star1Val,
                    star2: star2Val,
                    description: descVal,
                    vodAssetVideoId: uuid,
                    ...(headerImage && { headerImage: headerImage.fileName }),
                    ...(cardImage && { cardImage: cardImage.fileName }),
                    ...(poster && { poster: poster.fileName }),
                  },
                };
                API.graphql(
                  graphqlOperation(mutations.createVodAsset, videoAsset)
                );
                Storage.put(
                  `${uuid}.${fileExtension[fileExtension.length - 1]}`,
                  file,
                  {
                    progressCallback(progress) {
                      const { loaded, total } = progress;
                      dispatch({
                        type: "setProgress",
                        loaded: loaded,
                        total: total,
                      });
                    },
                    contentType: "video/*",
                    bucket: awsvideoconfig.awsInputVideo,
                    region,
                    customPrefix: {
                      public: "",
                    },
                  }
                )
                  .then(() => {
                    dispatch({
                      type: "setSubmitted",
                    });
                    console.log(`Successfully Uploaded: ${uuid}`);
                  })
                  .catch((err) => console.log(`Error: ${err}`));
              }
            });
          }
        }
      );
    } else if (titleVal && directorType === "existing" && file) {
      event.preventDefault();
      const uuid = uuidv4();
      const videoObject = {
        input: {
          id: uuid,
        },
      };
      const region = Amplify._config.aws_project_region;
      if (headerImage) {
        Storage.put(headerImage.fileName, headerImage.file);
      }

      if (cardImage) {
        Storage.put(cardImage.fileName, cardImage.file);
      }

      if (poster) {
        Storage.put(poster.fileName, poster.file);
      }
      API.graphql(
        graphqlOperation(mutations.createVideoObject, videoObject)
      ).then((res, err) => {
        if (err === undefined) {
          const fileExtension = fileName.toLowerCase().split(".");
          const videoAsset = {
            input: {
              title: titleVal,
              director: chosenDirector.name,
              directorID: chosenDirector.id,
              year: yearVal,
              runtime: runtimeVal,
              genre: genreVal,
              star1: star1Val,
              star2: star2Val,
              description: descVal,
              vodAssetVideoId: uuid,
              ...(headerImage && { headerImage: headerImage.fileName }),
              ...(cardImage && { cardImage: cardImage.fileName }),
              ...(poster && { poster: poster.fileName }),
            },
          };
          API.graphql(graphqlOperation(mutations.createVodAsset, videoAsset));
          Storage.put(
            `${uuid}.${fileExtension[fileExtension.length - 1]}`,
            file,
            {
              progressCallback(progress) {
                const { loaded, total } = progress;
                dispatch({
                  type: "setProgress",
                  loaded: loaded,
                  total: total,
                });
              },
              contentType: "video/*",
              bucket: awsvideoconfig.awsInputVideo,
              region,
              customPrefix: {
                public: "",
              },
            }
          )
            .then(() => {
              dispatch({
                type: "setSubmitted",
              });
              console.log(`Successfully Uploaded: ${uuid}`);
            })
            .catch((err) => console.log(`Error: ${err}`));
        }
      });
    }
  };

  const showItems = items.map((value, index) => {
    if (!value.specialFeature) {
      return (
        <tr key={value.id}>
          <td>{value.title}</td>
          <td>{value.director}</td>
          <td>{value.description}</td>
          <td>
            <div className="d-inline-flex justify-content-center">
              <Button
                onClick={(e) => onButtonClick(value, e)}
                name="read"
                variant="outline-light"
                size="sm"
                className="mx-1"
              >
                Read
              </Button>
              <Button
                variant="outline-light"
                name="update"
                size="sm"
                onClick={(e) => onButtonClick(value, e)}
                className="mx-1"
              >
                Update
              </Button>
              <Button
                onClick={(e) => onButtonClick(value, e)}
                name="delete"
                variant="outline-light"
                size="sm"
                className="mx-1"
              >
                Delete
              </Button>
            </div>
          </td>
        </tr>
      );
    }
    return null;
  });

  const displayForm = () => {
    dispatch({
      type: "displayForm",
    });
  };

  const hideForm = () => {
    if (submitted) {
      dispatch({
        type: "resetValues",
        payload: directors[0],
      });
    }

    dispatch({
      type: "hideForm",
    });
  };

  const setDirectorType = (event) => {
    dispatch({
      type: "setDirectorType",
      payload: event.target.value,
    });
  };

  const setExistingDirector = (event) => {
    dispatch({
      type: "setChosenDirector",
      payload: directors[event.target.value],
    });
  };

  const handleDirectorType = () => {
    if (directorType === "new") {
      return (
        <>
          <Form.Group>
            <Form.Label>Director</Form.Label>
            <Form.Control
              type="text"
              value={directorVal}
              name="directorVal"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please add a director name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Director Bio</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="directorBioVal"
              value={directorBioVal}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please add a director bio.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Director Location</Form.Label>
            <Form.Control
              type="text"
              value={directorLocationVal}
              name="directorLocationVal"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please add a director location.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Image</Form.Label>
            <FilePicker
              name="directorImage"
              type="image"
              callbackFromParent={imageCallback}
            />
          </Form.Group>
        </>
      );
    } else if (directorType === "existing") {
      return (
        <Form.Group>
          <Form.Label>Select a Director</Form.Label>
          <Form.Control as="select" custom onChange={setExistingDirector}>
            {directors.map((option, index) => {
              return (
                <option key={index} value={index}>
                  {option.name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      );
    }
  };

  const overlayForm = () => {
    return (
      <Modal show={displayingForm} onHide={hideForm}>
        <Modal.Header closeButton>Create New Film</Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={submitFormHandler}
            id="film-form"
          >
            <Form.Group controlId="formGroupTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={titleVal}
                name="titleVal"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please add a title.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Director Type</Form.Label>
              <Form.Control
                as="select"
                custom
                value={directorType}
                onChange={setDirectorType}
                noValidate
              >
                <option value="new">New Director</option>
                <option value="existing">Existing Director</option>
              </Form.Control>
            </Form.Group>
            {handleDirectorType()}
            <Form.Group>
              <Form.Label>Year</Form.Label>
              <Form.Control
                type="text"
                value={yearVal}
                name="yearVal"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Runtime (in minutes)</Form.Label>
              <Form.Control
                type="text"
                value={runtimeVal}
                name="runtimeVal"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Genre</Form.Label>
              <Form.Control
                type="text"
                value={genreVal}
                name="genreVal"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Star 1</Form.Label>
              <Form.Control
                type="text"
                value={star1Val}
                name="star1Val"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Star 2</Form.Label>
              <Form.Control
                type="text"
                value={star2Val}
                name="star2Val"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={descVal}
                name="descVal"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Video File</Form.Label>
              <FilePicker type="video" callbackFromParent={myCallback} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Header Image</Form.Label>
              <FilePicker
                name="headerImage"
                type="image"
                callbackFromParent={imageCallback}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Card Image</Form.Label>
              <FilePicker
                name="cardImage"
                type="image"
                callbackFromParent={imageCallback}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Poster</Form.Label>
              <FilePicker
                name="poster"
                type="image"
                callbackFromParent={imageCallback}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tags</Form.Label>
              <Form.Check label="Dark" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="flex-grow-1">
            <PopoverProgress progress={progress} />
          </div>
          <Button variant="dark" size="lg" type="submit" form="film-form">
            Create Asset
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const popoverOnHide = () => {
    dispatch({
      type: "hideComponent",
    });
  };

  const onButtonClick = (item, event) => {
    dispatch({
      type: "showComponent",
      chosenItem: item,
      action: event.target.name,
    });
  };

  return (
    <div className="pb-3">
      <FilmPopover
        chosenItem={chosenItem}
        action={action}
        popoverOnHide={popoverOnHide}
        showComponent={showComponent}
        directors={directors}
        s3url={s3url}
        tags={tags}
      />
      <FilmTable showItems={showItems} />
      {overlayForm()}
      <Button onClick={() => displayForm()} variant="dark" size="lg">
        Create New Film
      </Button>
    </div>
  );
}

export default FilmUpload;
