import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { API, Auth } from "aws-amplify";

function CancelSubscription(props) {
  const { hideModal } = props;

  const [disabled, setDisabled] = useState(false);

  const handleCancel = async () => {
    setDisabled(true);
    const user = await Auth.currentAuthenticatedUser();

    const body = {
      subscriptionId: user.attributes["custom:SubscriptionId"],
    };

    await API.post("apic5c1ed0b", "/cancel-subscription", { body }).then(
      async () => {
        await Auth.updateUserAttributes(user, {
          "custom:subscriptionStatus": "pendingInactive",
        });
      }
    );
  };

  return (
    <div className="text-white">
      <>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Are you sure you want to cancel?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            You can resume your subscription at any time.
          </p>
          <div className="d-flex justify-content-around">
            <Button
              variant="grad"
              onClick={handleCancel}
              size="lg"
              disabled={disabled}
            >
              Yes
            </Button>
            <Button
              variant="grad"
              onClick={hideModal}
              size="lg"
              disabled={disabled}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </>
    </div>
  );
}

export default CancelSubscription;
