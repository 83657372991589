import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Container,
  Row,
  Col,
  Button,
  Card,
  DropdownButton,
  Dropdown,
  Collapse,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { API, graphqlOperation } from "aws-amplify";
import {
  listDirectors,
  listVodAssets,
  listPlaylists,
  getPlaylist,
} from "../../../graphql/queries";
import { createPlaylistFilms } from "../../../graphql/mutations";
import useCapitalize from "../../Hooks/useCapitalize";
import { useDebounce } from "@uidotdev/usehooks";

function SearchModal(props) {
  const { show, hideModal, s3url } = props;

  const caps = useCapitalize;

  const [formState, setFormState] = useState("");
  const debouncedSearchTerm = useDebounce(formState.toLowerCase(), 300);

  const [searchResults, setSearchResults] = useState([]);

  const [open, setOpen] = useState(false);

  const [searching, setSearching] = useState(false);

  const [playlists, setPlaylists] = useState(null);

  useEffect(() => {
    setOpen(false);
    setSearching(true);
    const search = () => {
      setSearchResults([]);
      const directorVariables = {
        filter: {
          name: {
            contains: debouncedSearchTerm,
          },
        },
      };

      const filmVariables = {
        filter: {
          title: {
            contains: debouncedSearchTerm,
          },
        },
      };

      if (!debouncedSearchTerm?.trim()) {
        setSearching(false);
      } else {
        Promise.all([
          API.graphql(graphqlOperation(listDirectors, directorVariables)),
          API.graphql(graphqlOperation(listVodAssets, filmVariables)),
        ]).then((values) => {
          const directorItems = values[0].data.listDirectors.items;
          const filmItems = values[1].data.listVodAssets.items.filter(
            (film) => {
              return !film.specialFeature;
            }
          );
          setSearching(false);
          setOpen(true);
          setSearchResults([directorItems, filmItems]);
        });
      }
    };

    search();
  }, [debouncedSearchTerm]);

  const onEnter = async () => {
    await API.graphql(graphqlOperation(listPlaylists)).then((res) => {
      Promise.all(
        res.data.listPlaylists.items.map(async (p) => {
          return await API.graphql(graphqlOperation(getPlaylist, { id: p.id }));
        })
      )
        .then((res) => {
          const playlistData = res.map((item) => item.data.getPlaylist);
          setPlaylists(playlistData);
        })
        .catch((err) => console.log(err));
    });
  };

  const clearData = () => {
    setFormState("");
    setSearchResults([]);
  };

  const handleChange = (event) => {
    setFormState(event.target.value);
  };

  const addToPlaylist = async (film, playlist) => {
    const newPlaylistFilms = {
      input: {
        vodAssetID: film.id,
        playlistID: playlist.id,
      },
    };

    try {
      await API.graphql(
        graphqlOperation(createPlaylistFilms, newPlaylistFilms)
      ).then((res) => {
        setPlaylists(
          playlists.map((list) => {
            if (list.id === playlist.id) {
              const { films, ...nofilms } = list;
              return {
                ...nofilms,
                films: {
                  items: films.items.concat(res.data.createPlaylistFilms),
                },
              };
            }
            return list;
          })
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={show}
      onEnter={onEnter}
      onHide={hideModal}
      onExit={clearData}
      contentClassName="bg-dark"
      size="xl"
      scrollable
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title className="text-white">Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Form>
                <Form.Floating id="floatingInputCustom">
                  <Form.Control value={formState} onChange={handleChange} />
                  <label htmlFor="floatingInputCustom">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </label>
                </Form.Floating>
              </Form>
            </Col>
          </Row>
          {searchResults.length > 0 ? (
            <>
              {searchResults[0].length === 0 &&
              searchResults[1].length === 0 ? (
                <h4 className="mt-3 text-center text-white">
                  No Results Found For {debouncedSearchTerm}
                </h4>
              ) : (
                <Collapse in={open}>
                  <>
                    {searchResults[0].length > 0 && (
                      <>
                        <Row className="mt-2">
                          <div className="w-auto text-white text-start">
                            <h3 className="d-inline-block mb-0">Filmmakers</h3>
                            <hr
                              className="w-75 mt-1 opacity-100 text-white"
                              style={{ borderTop: "8px solid" }}
                            />
                          </div>
                        </Row>
                        <Row className="d-flex flex-column flex-fill flex-md-row">
                          {searchResults[0].map((value) => {
                            return (
                              <Col key={value.id} className="col-md-4 my-4">
                                <Card className="h-100 text-center" text="dark">
                                  <Card.Img
                                    variant="top"
                                    src={`${s3url}${value.image}`}
                                    style={{ "--width": "100%" }}
                                  />
                                  <Card.Body>
                                    <Card.Title style={{ fontSize: "30px" }}>
                                      {caps(value.name)}
                                    </Card.Title>
                                    <Card.Subtitle>
                                      {value.location}
                                    </Card.Subtitle>
                                    <Card.Text className="mt-4">
                                      {value.bio}
                                    </Card.Text>
                                  </Card.Body>
                                  <Card.Footer>
                                    <Button
                                      as={Link}
                                      state={{
                                        name: value.name,
                                        id: value.id,
                                      }}
                                      to={`/filmmaker/${value.name
                                        .replaceAll("#", "")
                                        .replaceAll(" ", "-")
                                        .toLowerCase()}`}
                                      variant="grad"
                                      onClick={() => hideModal()}
                                    >
                                      Learn More
                                    </Button>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    )}
                    {searchResults[1].length > 0 && (
                      <>
                        <Row className="mt-2">
                          <div className="w-auto text-white text-start">
                            <h3 className="d-inline-block mb-0">Films</h3>
                            <hr
                              className="w-75 mt-1 opacity-100 text-white"
                              style={{ borderTop: "8px solid" }}
                            />
                          </div>
                        </Row>
                        <Row className="d-flex flex-column flex-fill flex-md-row">
                          {searchResults[1].length > 0 &&
                            searchResults[1].map((value) => {
                              return (
                                <Col className="col-md-4 my-4" key={value.id}>
                                  <Card className="h-100 text-center">
                                    <Card.Img
                                      variant="top"
                                      src={`${s3url}${value.cardImage}`}
                                      style={{ "--width": "100%" }}
                                    />

                                    <Card.Body>
                                      <Card.Title style={{ fontSize: "30px" }}>
                                        {caps(value.title)}
                                      </Card.Title>
                                      <Card.Text style={{ fontSize: "20px" }}>
                                        <b>Genre:</b> {value.genre}
                                      </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                      <Button
                                        as={Link}
                                        state={{
                                          id: value.id,
                                          title: value.title,
                                          name: value.director,
                                          directorId: value.directorID,
                                        }}
                                        to={`/filmmaker/${value.director
                                          .replaceAll("#", "")
                                          .replaceAll(" ", "-")
                                          .toLowerCase()}/film/${value.title
                                          .replaceAll(" ", "-")
                                          .toLowerCase()}`}
                                        variant="grad"
                                        onClick={() => hideModal()}
                                      >
                                        Watch Film
                                      </Button>
                                      <p
                                        style={{ opacity: "80%" }}
                                        className="mt-3 mb-1"
                                      >
                                        Add to Playlist
                                      </p>
                                      <DropdownButton
                                        size="sm"
                                        variant="outline-dark"
                                        title="Select Playlist"
                                        drop="up"
                                      >
                                        {playlists && (
                                          <Dropdown.Item
                                            as={Link}
                                            to={"/playlists"}
                                            key={playlists.length + 1}
                                            className="dropdown-dark"
                                            onClick={() => hideModal()}
                                          >
                                            Create Playlist
                                          </Dropdown.Item>
                                        )}
                                        {playlists &&
                                          playlists.map((playlist, index) => {
                                            if (
                                              playlist.films.items.some(
                                                (element) =>
                                                  element.vodAssetID ===
                                                  value.id
                                              )
                                            ) {
                                              return null;
                                            }
                                            return (
                                              <Dropdown.Item
                                                as={Button}
                                                key={index}
                                                variant="black"
                                                onClick={() => {
                                                  addToPlaylist(
                                                    value,
                                                    playlist
                                                  );
                                                }}
                                              >
                                                {playlist.name}
                                              </Dropdown.Item>
                                            );
                                          })}
                                      </DropdownButton>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              );
                            })}
                        </Row>
                      </>
                    )}
                  </>
                </Collapse>
              )}
            </>
          ) : (
            <>
              {searching ? (
                <div className="text-center mt-3">
                  <Spinner animation="border" variant="light" />
                </div>
              ) : (
                <h4 className="mt-3 text-center text-white">
                  Start Typing to See Results
                </h4>
              )}
            </>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default SearchModal;
