import React, { useState, useEffect } from "react";
import { Tabs, Tab, Fade } from "react-bootstrap";
import "./Admin.css";
import FilmUpload from "./FilmUpload/FilmUpload";
import DirectorUpload from "./DirectorUpload/DirectorUpload";
import SpecialFeatureUpload from "./SpecialFeatureUpload/SpecialFeatureUpload";
import Tags from "./Tags/Tags";
import HomePage from "./HomePage/HomePage";
import { graphqlOperation, Auth, API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as subscriptions from "../../graphql/subscriptions";

function Admin(props) {
  const { s3url } = props;
  const [adminState, setAdminState] = useState({
    groups: [],
    items: [],
    directors: [],
    carouselImages: [],
    tags: [],
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getGroups = Auth.currentSession();
    const getAssets = API.graphql(graphqlOperation(queries.listVodAssets));
    const getDirectors = API.graphql(graphqlOperation(queries.listDirectors));
    const getImages = API.graphql(graphqlOperation(queries.listCarouselImages));
    const getTags = API.graphql(graphqlOperation(queries.listTags));

    Promise.all([getGroups, getAssets, getDirectors, getImages, getTags]).then(
      (values) => {
        const sessionGroups = values[0].idToken.payload["cognito:groups"];

        setAdminState({
          ...(sessionGroups && { groups: sessionGroups }),
          items: values[1].data.listVodAssets.items,
          directors: values[2].data.listDirectors.items,
          carouselImages: values[3].data.listCarouselImages.items,
          tags: values[4].data.listTags.items,
        });
        setOpen(true);
      }
    );

    const listenForNewAssets = async () => {
      await API.graphql(
        graphqlOperation(subscriptions.onCreateVodAsset)
      ).subscribe({
        next: (data) => {
          setAdminState((prevState) => ({
            ...prevState,
            items: [...prevState.items, data.value.data.onCreateVodAsset],
          }));
        },
      });
    };

    const listenForDeletedAssets = async () => {
      await API.graphql(
        graphqlOperation(subscriptions.onDeleteVodAsset)
      ).subscribe({
        next: (data) => {
          setAdminState((prevState) => ({
            ...prevState,
            items: prevState.items.filter(
              (element) => element.id !== data.value.data.onDeleteVodAsset.id
            ),
          }));
        },
      });
    };

    const listenForUpdatedAssets = async () => {
      await API.graphql(
        graphqlOperation(subscriptions.onUpdateVodAsset)
      ).subscribe({
        next: (data) => {
          setAdminState((prevState) => ({
            ...prevState,
            items: prevState.items.map((element) => {
              if (element.id === data.value.data.onUpdateVodAsset.id) {
                return data.value.data.onUpdateVodAsset;
              }
              return element;
            }),
          }));
        },
      });
    };

    listenForNewAssets();
    listenForDeletedAssets();
    listenForUpdatedAssets();

    const listenForNewDirectors = async () => {
      await API.graphql(
        graphqlOperation(subscriptions.onCreateDirector)
      ).subscribe({
        next: (data) => {
          setAdminState((prevState) => ({
            ...prevState,
            directors: [
              ...prevState.directors,
              data.value.data.onCreateDirector,
            ],
          }));
        },
      });
    };

    const listenForDeletedDirectors = async () => {
      await API.graphql(
        graphqlOperation(subscriptions.onDeleteDirector)
      ).subscribe({
        next: (data) => {
          setAdminState((prevState) => ({
            ...prevState,
            directors: prevState.directors.filter(
              (element) => element.id !== data.value.data.onDeleteDirector.id
            ),
          }));
        },
      });
    };

    const listenForUpdatedDirectors = async () => {
      await API.graphql(
        graphqlOperation(subscriptions.onUpdateDirector)
      ).subscribe({
        next: (data) => {
          setAdminState((prevState) => ({
            ...prevState,
            directors: prevState.directors.map((element) => {
              if (element.id === data.value.data.onUpdateDirector.id) {
                return data.value.data.onUpdateDirector;
              }
              return element;
            }),
          }));
        },
      });
    };

    listenForNewDirectors();
    listenForDeletedDirectors();
    listenForUpdatedDirectors();

    const listenForNewTags = async () => {
      await API.graphql(graphqlOperation(subscriptions.onCreateTag)).subscribe({
        next: (data) => {
          setAdminState((prevState) => ({
            ...prevState,
            tags: prevState.tags.concat(data.value.data.onCreateTag),
          }));
        },
      });
    };

    listenForNewTags();
  }, []);

  useEffect(() => {
    const listenForUpdatedImages = async () => {
      await API.graphql(
        graphqlOperation(subscriptions.onUpdateCarouselImage)
      ).subscribe({
        next: (data) => {
          if (adminState.carouselImages.length !== 0) {
            const index = adminState.carouselImages.findIndex(
              (element) =>
                element.order === data.value.data.onUpdateCarouselImage.order
            );
            const array = [...adminState.carouselImages];
            array[index] = data.value.data.onUpdateCarouselImage;
            setAdminState((prevState) => ({
              ...prevState,
              carouselImages: array,
            }));
          }
        },
      });
    };

    listenForUpdatedImages();
  }, [adminState.carouselImages]); // eslint-disable-line react-hooks/exhaustive-deps

  const createAdminPanel = () => {
    if (adminState.groups.includes("Admin")) {
      return (
        <>
          <h1 className="text-white">Admin Panel</h1>
          <Tabs defaultActiveKey="filmmakers">
            <Tab eventKey="filmmakers" title="Filmmakers">
              <DirectorUpload
                s3url={s3url}
                directors={adminState.directors}
                items={adminState.items}
                tags={adminState.tags}
              />
            </Tab>
            <Tab eventKey="films" title="Films">
              <FilmUpload
                s3url={s3url}
                items={adminState.items}
                directors={adminState.directors}
                tags={adminState.tags}
              />
            </Tab>
            <Tab eventKey="specialFeatures" title="Special Features">
              <SpecialFeatureUpload items={adminState.items} s3url={s3url} />
            </Tab>
            <Tab eventKey="tags" title="Tags">
              <Tags tags={adminState.tags} />
            </Tab>
            <Tab eventKey="homePage" title="Home Page">
              <HomePage
                carouselImages={adminState.carouselImages}
                items={adminState.items}
                directors={adminState.directors}
                s3url={s3url}
              />
            </Tab>
          </Tabs>
        </>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "65vh",
        }}
      >
        <h3 className="text-white">Not Authenticated</h3>
      </div>
    );
  };

  return <Fade in={open}>{createAdminPanel()}</Fade>;
}

export default Admin;
