import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { API, Auth } from "aws-amplify";
import Notification from "../../../Notification/Notification";
import NotificationContext from "../../../../context/NotificationContext";

function UpdatePaymentMethod(props) {
  const { handleBackClick, user, setModal, setToggle } = props;

  const subscriptionId = user.attributes["custom:SubscriptionId"];
  const customerId = user.attributes["custom:StripeCustomerId"];

  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [complete, setComplete] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const notificationCtx = useContext(NotificationContext);

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement("card");

      cardElement.on("change", (event) => {
        if (event.error) {
          setCardError(event.error.message);
        } else {
          setCardError("");
        }

        if (event.complete) {
          setComplete(true);
        }
      });
    }
  }, [cardError, complete, elements, name]);

  const cardOptions = {
    style: {
      base: {
        fontSize: "16.7px",
        fontFamily: "sans-serif",
      },
    },
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const updatePayment = async (event) => {
    event.preventDefault();

    const deletePromise = async (subId) => {
      const body = { subscriptionId: subId };

      return await API.post("apic5c1ed0b", "/delete-payment", { body });
    };

    const intentPromise = async (custId) => {
      const body = {
        customerId: custId,
      };
      return await API.post("apic5c1ed0b", "/create-intent", { body });
    };

    const updateSubscriptionPromise = async (payId, subId) => {
      const body = {
        paymentMethodId: payId,
        subscriptionId: subId,
      };

      return await API.post(
        "apic5c1ed0b",
        "/update-subscription-payment-method",
        {
          body,
        }
      );
    };

    const retrievePaymentPromise = async (payId) => {
      const body = {
        paymentMethodId: payId,
      };

      return await API.post(
        "apic5c1ed0b",
        "/retrieve-customer-payment-method",
        {
          body,
        }
      );
    };

    setLoading(true);

    await Promise.all([
      deletePromise(subscriptionId),
      intentPromise(customerId),
    ]).then(async (values) => {
      const cardElement = elements.getElement(CardElement);
      const clientSecret = values[1].client_secret;

      await stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name,
            },
          },
          return_url: "http://localhost:3000/account#link2",
        })
        .then(async (res) => {
          if (res.error) {
            notificationCtx.error(`${res.error.message} Please try again.`);
            setLoading(false);
          } else {
            const newPaymentMethodId = res.setupIntent.payment_method;

            await Promise.all([
              updateSubscriptionPromise(newPaymentMethodId, subscriptionId),
              retrievePaymentPromise(newPaymentMethodId),
            ])
              .then(async (values) => {
                const userAtts = await Auth.currentAuthenticatedUser();

                const paymentResponse = values[1];
                const capitalBrand =
                  paymentResponse.card.brand.charAt(0).toUpperCase() +
                  paymentResponse.card.brand.slice(1);

                await Auth.updateUserAttributes(userAtts, {
                  "custom:PaymentMethodId": paymentResponse.id,
                  "custom:brand": capitalBrand,
                  "custom:last4": paymentResponse.card.last4,
                });
              })
              .finally(() => {
                setToggle("off");
                setModal({
                  show: true,
                  type: "payment method",
                });
                setTimeout(() => {
                  setModal((prevState) => ({
                    ...prevState,
                    show: false,
                  }));
                }, 2000);
              });
          }
        });
    });
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-1">
        <Button variant="action" onClick={handleBackClick}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <p className="mb-0 ms-1 fw-bold">Update Payment Method</p>
      </div>
      <Notification />
      <Form onSubmit={updatePayment}>
        <Form.Group>
          <div className="d-flex justify-content-start align-items-center mb-1">
            <Form.Label className="mb-0 ms-1 fw-bold">Name on Card</Form.Label>
          </div>
          <Form.Control
            value={name}
            onChange={handleChange}
            placeholder="Enter Name on Card"
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label className="d-flex justify-content-start mb-0 ms-1 mb-1 fw-bold">
            Card Number
          </Form.Label>
          <Form.Control
            className="py-2 text-start"
            options={cardOptions}
            as={CardElement}
            isInvalid={cardError}
          />
          <Form.Control.Feedback type="invalid">
            {cardError}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button
            size="lg"
            type="submit"
            variant="grad"
            className="mt-2"
            disabled={(!complete && !name) || loading}
          >
            {loading ? "Loading..." : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default UpdatePaymentMethod;
