import React from "react";
import { Table } from "react-bootstrap";

function TagsTable(props) {
  const { tags } = props;

  const showItems = () => {
    return tags.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.label}</td>
        </tr>
      );
    });
  };
  return (
    <Table variant="dark" striped bordered hover>
      <thead>
        <tr>
          <th>Label</th>
        </tr>
      </thead>
      <tbody>{showItems()}</tbody>
    </Table>
  );
}

export default TagsTable;
