import React, { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { Form, Button } from "react-bootstrap";
import NotificationContext from "../../../../context/NotificationContext";
import Notification from "../../../Notification/Notification";

function ResetPassword(props) {
  const { username, setToggle, setModal } = props;

  const notificationCtx = useContext(NotificationContext);

  const [formState, setFormState] = useState({
    code: null,
    password: null,
  });

  const [checkState, setCheckState] = useState("password");

  const [invalid, setInvalid] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      notificationCtx.clear();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const regex =
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

    if (formState.password !== "") {
      if (formState.password.match(regex)) {
        setInvalid(false);
      } else {
        setInvalid(true);
      }
    } else {
      setInvalid(false);
    }
  }, [formState.password]);

  const handleCheck = (event) => {
    if (event.target.checked) {
      setCheckState("text");
    } else {
      setCheckState("password");
    }
  };

  const handleResetPasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    await Auth.forgotPasswordSubmit(
      username,
      formState.code,
      formState.password
    )
      .then(() => {
        setToggle("off");
        setModal({
          show: true,
          type: "password",
        });
        setTimeout(() => {
          setModal((prevState) => ({
            ...prevState,
            show: false,
          }));
        }, 2000);
      })
      .catch((e) => {
        setLoading(false);
        const str = e.toString();
        notificationCtx.error(str.substring(str.indexOf(":") + 2));
      });
  };

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Notification />
      <Form onSubmit={handleResetPasswordSubmit}>
        <div className="d-flex justify-content-start align-items-center mb-3">
          <Form.Label className="mb-0 fw-bold">Reset Password</Form.Label>
        </div>
        <Form.Group className="text-start">
          <Form.Label className="fw-bold">Verification Code</Form.Label>
          <Form.Control
            value={formState.code}
            onChange={handleChange}
            name="code"
          />
          <Form.Text className="text-white">
            Enter the code that was sent to the email you provided.
          </Form.Text>
        </Form.Group>
        <Form.Group className="text-start mt-2">
          <Form.Label className="fw-bold">New Password</Form.Label>
          <Form.Control
            name="password"
            value={formState.password}
            onChange={handleChange}
            placeholder="Enter Password"
            type={checkState}
            isInvalid={invalid}
          />
          <Form.Control.Feedback type="invalid">
            Invalid Password
          </Form.Control.Feedback>
          <Form.Check
            className="text-start iris-check"
            type="checkbox"
            label="Show Password"
            name="reset"
            onChange={(e) => handleCheck(e)}
          />
          <Form.Text className="text-secondary">
            Password must be at least 8 characters long, and contain at least
            one uppercase letter, one lowercase character, one number, and one
            special character.
          </Form.Text>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button
            size="lg"
            type="submit"
            variant="grad"
            className="mt-2"
            disabled={
              formState.code === "" ||
              formState.password === "" ||
              invalid ||
              loading
            }
          >
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default ResetPassword;
