import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Image,
  Nav,
  Container,
  Col,
  Row,
  Dropdown,
  Button,
  Fade,
} from "react-bootstrap";
import SearchModal from "./SearchModal/SearchModal";
import NavbarLogo from "./NavbarLogo.png";
import "./NavBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function NavBar(props) {
  const { s3url, signOut, user } = props;

  const [open, setOpen] = useState(false);

  const [show, setShow] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const renderAccount = () => {
    if (user) {
      return (
        <Col>
          <Dropdown drop="down-centered">
            <Dropdown.Toggle variant="navBar" id="dropdown-basic" size="lg">
              <Image
                className="me-2"
                style={{
                  objectFit: "cover",
                  borderRadius: "50%",
                  border: "3px solid #FFFFFF",
                  width: "3rem",
                  height: "3rem",
                }}
                src={`${s3url}${user.attributes.picture}`}
              />
              {user.attributes.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item as={Link} to="/account" className="dropdown-dark">
                Account Settings
              </Dropdown.Item> */}
              <Dropdown.Item as={Button} onClick={signOut} variant="black">
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      );
    }
  };

  return (
    <>
      <SearchModal
        show={show}
        hideModal={hideModal}
        s3url={s3url}
        user={user}
      />
      <Fade in={open}>
        <Container fluid>
          <Row className="d-flex flex-column-reverse flex-sm-row">
            <Col className="offset-md-4">
              <Link to="/">
                <Image
                  src={NavbarLogo}
                  className="my-4"
                  style={{ width: "150px" }}
                  alt="Logo"
                />
              </Link>
            </Col>
            {renderAccount()}
          </Row>
          <Row>
            <Col className="col-md-5 mx-auto">
              <Nav fill className="mb-4">
                <Nav.Link className="iris-nav" as={Link} to="/">
                  <p className="fs-4 mb-0">Home</p>
                </Nav.Link>
                <Nav.Link className="iris-nav" as={Link} to="/filmmakers">
                  <p className="fs-4 mb-0">Filmmakers</p>
                </Nav.Link>
                <Nav.Link className="iris-nav" as={Link} to="/playlists">
                  <p className="fs-4 mb-0">Playlists</p>
                </Nav.Link>
                <Nav.Link className="d-flex" onClick={showModal}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="iris-nav fs-4 mx-auto align-self-center"
                  />
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default NavBar;
