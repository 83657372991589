import React, { useState } from "react";
import TagsTable from "./TagsTable";
import { Button, Container, Row, Col } from "react-bootstrap";
import TagsPopover from "./TagsPopover";

function Tags(props) {
  const { tags } = props;
  const [show, setShow] = useState(false);

  const [label, setLabel] = useState("");

  const hidePopover = () => {
    setShow(false);
    setLabel("");
  };

  return (
    <div className="min-vh-100">
      <Container>
        <Row>
          <Col className="col-md-6 mx-auto">
            <TagsPopover
              show={show}
              label={label}
              setLabel={setLabel}
              hidePopover={hidePopover}
            />
            <TagsTable tags={tags} />
            <Button
              variant="dark"
              size="lg"
              onClick={() => {
                setShow(true);
              }}
            >
              Create New Tag
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Tags;
