import React from "react";
import { Modal, Row, Container, Button, Form } from "react-bootstrap";

function EditPlaylistPopover(props) {
  const {
    modalToggle,
    hideModalPopover,
    option,
    handleNameChange,
    renameList,
    playlistName,
    deleteList,
    newName,
  } = props;

  return (
    <>
      <Modal
        show={modalToggle}
        onHide={hideModalPopover}
        contentClassName="bg-dark"
        centered
      >
        {option === "rename" && (
          <>
            <Modal.Header
              className="text-white"
              closeButton
              closeVariant="white"
            >
              Rename Playlist - {playlistName}
            </Modal.Header>
            <Modal.Body>
              <Form className="mt-2" onSubmit={renameList}>
                <Form.Control
                  placeholder={playlistName}
                  onChange={handleNameChange}
                  value={newName}
                  maxLength={25}
                />
                <div className="text-center">
                  <Form.Text className="text-center text-white">
                    Max: 25 Characters
                  </Form.Text>
                </div>
                <div className="d-flex justify-content-between">
                  <Button
                    variant="grad"
                    onClick={hideModalPopover}
                    className="mt-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="grad"
                    className="mt-2"
                    type="submit"
                    disabled={newName === ""}
                  >
                    Rename Playlist
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </>
        )}
        {option === "delete" && (
          <>
            <Modal.Header
              closeButton
              className="text-white"
              closeVariant="white"
            >
              Delete Playlist - {playlistName}
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <div className="d-flex justify-content-center">
                    <p className="text-white">
                      Are you sure you want to delete this playlist?
                    </p>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-around">
                    <Button variant="grad" onClick={() => hideModalPopover()}>
                      No
                    </Button>
                    <Button onClick={deleteList} variant="grad">
                      Yes
                    </Button>
                  </div>
                </Row>
              </Container>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}

export default EditPlaylistPopover;
