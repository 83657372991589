import React from "react";
import { Table, Button } from "react-bootstrap";

function SpecialFeatureTable(props) {
  const { items, onButtonClick } = props;

  const renderOptions = (item, chosenType) => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
      <div className="d-flex justify-content-center">
        <p className="my-auto me-2">
          Count:{" "}
          {item[`specialFeature${capitalizeFirstLetter(chosenType)}`].length}
        </p>
        <Button
          variant="outline-light"
          size="sm"
          onClick={() => onButtonClick(item, chosenType)}
          className="mx-1"
        >
          View
        </Button>
      </div>
    );
  };

  const showItems = () => {
    const filteredArray = items.filter(
      (element) =>
        element.specialFeatureVideo ||
        element.specialFeatureText ||
        element.specialFeaturePhoto
    );

    return filteredArray.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.title}</td>
          <td>{item.director}</td>
          {item.specialFeatureVideo ? (
            <td>{renderOptions(item, "video")}</td>
          ) : (
            <td />
          )}
          {item.specialFeaturePhoto ? (
            <td>{renderOptions(item, "photo")}</td>
          ) : (
            <td />
          )}
          {item.specialFeatureText ? (
            <td>{renderOptions(item, "text")}</td>
          ) : (
            <td />
          )}
        </tr>
      );
    });
  };

  return (
    <div>
      <Table variant="dark" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Film</th>
            <th>Director</th>
            <th>Videos</th>
            <th>Photos</th>
            <th>Text</th>
          </tr>
        </thead>
        <tbody>{showItems()}</tbody>
      </Table>
    </div>
  );
}

export default SpecialFeatureTable;
