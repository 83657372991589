import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Amplify, API, graphqlOperation, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import * as mutations from "../../../../graphql/mutations";
import awsvideoconfig from "../../../../aws-video-exports";
import FilePicker from "../../FilePicker/FilePicker";

function SpecialFeatureVideo(props) {
  const { items, setProgress } = props;

  const [state, setState] = useState({
    file: null,
    fileName: null,
    titleVal: "",
    chosenFilm: null,
    validated: false,
  });

  useEffect(() => {
    const firstItem = items.find((element) => element.specialFeature !== true);

    setState((prevState) => ({
      ...prevState,
      chosenFilm: firstItem,
    }));
  }, [items]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const myCallback = (dataFromChild) => {
    setState({
      ...state,
      file: dataFromChild,
      fileName: dataFromChild.name,
    });
  };

  const submitFormHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setState({
      ...state,
      validated: true,
    });

    if (state.titleVal) {
      event.preventDefault();
      const uuid = uuidv4();
      const videoObject = {
        input: {
          id: uuid,
        },
      };
      const region = Amplify._config.aws_project_region;

      API.graphql(
        graphqlOperation(mutations.createVideoObject, videoObject)
      ).then((res, err) => {
        if (err === undefined) {
          const fileExtension = state.fileName.toLowerCase().split(".");
          const videoAsset = {
            input: {
              title: state.titleVal,
              vodAssetVideoId: uuid,
              director: state.chosenFilm.director,
              directorID: state.chosenFilm.directorID,
              specialFeature: true,
            },
          };
          API.graphql(
            graphqlOperation(mutations.createVodAsset, videoAsset)
          ).then((response) => {
            const updatedVideo = {
              input: {
                id: state.chosenFilm.id,
                specialFeatureVideo: [
                  ...(state.chosenFilm.specialFeatureVideo !== null
                    ? state.chosenFilm.specialFeatureVideo
                    : []),
                  response.data.createVodAsset.id,
                ],
              },
            };
            API.graphql(
              graphqlOperation(mutations.updateVodAsset, updatedVideo)
            )
              .then(() =>
                console.log(
                  `Successfully Added ${response.data.createVodAsset.title} to ${state.chosenFilm.title}`
                )
              )
              .catch((err) => console.log(err));
          });
          Storage.put(
            `${uuid}.${fileExtension[fileExtension.length - 1]}`,
            state.file,
            {
              progressCallback(progress) {
                setProgress(progress);
              },
              contentType: "video/*",
              bucket: awsvideoconfig.awsInputVideo,
              region,
              customPrefix: {
                public: "",
              },
            }
          )
            .then(() => {
              console.log(`Successfully Uploaded: ${uuid}`);
            })
            .catch((err) => console.log(`Error: ${err}`));
        }
      });
    }
  };

  const setChosenFilm = (event) => {
    setState({
      ...state,
      chosenFilm: items[event.target.value],
    });
  };

  return (
    <div>
      <Form
        onSubmit={submitFormHandler}
        id="video"
        noValidate
        validated={state.validated}
      >
        <Form.Group controlId="formGroupTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={state.titleVal}
            name="titleVal"
            onChange={handleChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please add a title.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Video File</Form.Label>
          <FilePicker type="video" callbackFromParent={myCallback} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Select a Film</Form.Label>
          <Form.Control as="select" custom onChange={setChosenFilm}>
            {items.map((option, index) => {
              if (!option.specialFeature) {
                return (
                  <option key={index} value={index}>
                    {option.title} - {option.director}
                  </option>
                );
              }
              return null;
            })}
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
}

export default SpecialFeatureVideo;
