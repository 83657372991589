import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { updateVodAsset } from "../../../../graphql/mutations";

function SpecialFeatureText(props) {
  const { items, hideForm } = props;

  const [state, setState] = useState({
    chosenFilm: null,
    textID: "",
  });

  useEffect(() => {
    const firstItem = items.find((element) => element.specialFeature !== true);

    setState((prevState) => ({
      ...prevState,
      chosenFilm: firstItem,
    }));
  }, [items]);

  const setChosenFilm = (event) => {
    setState({
      ...state,
      chosenFilm: items[event.target.value],
    });
  };

  const submitTextHandler = (event) => {
    event.preventDefault();
    const updatedVideo = {
      input: {
        id: state.chosenFilm.id,
        specialFeatureText: [
          ...(state.chosenFilm.specialFeatureText !== null
            ? state.chosenFilm.specialFeatureText
            : []),
          state.textID,
        ],
      },
    };
    API.graphql(graphqlOperation(updateVodAsset, updatedVideo)).then(() =>
      hideForm()
    );
  };

  return (
    <div>
      <Form onSubmit={submitTextHandler} id="text">
        <Form.Group>
          <Form.Label>Select a Film</Form.Label>
          <Form.Control as="select" custom onChange={setChosenFilm}>
            {items.map((option, index) => {
              if (!option.specialFeature) {
                return (
                  <option key={index} value={index}>
                    {option.title} - {option.director}
                  </option>
                );
              }
              return null;
            })}
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
}

export default SpecialFeatureText;
