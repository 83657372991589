import React from "react";
import { Modal } from "react-bootstrap";
import PlayAllPlayer from "../PlayAllPlayer/PlayAllPlayer";
import awsvideo from "../../../../../aws-video-exports";
import useCapitalize from "../../../../Hooks/useCapitalize";

function PlayAllModal(props) {
  const { films, displayingFilms, hideFilms, s3url } = props;

  const caps = useCapitalize;

  const settings = {
    controls: true,
    fluid: true,
    bigPlayButton: false,
    controlBar: {
      children: {
        playToggle: true,
        volumeControl: true,
        progressControl: true,
        fullscreenToggle: true,
      },
    },
    autoplay: true,
  };

  const sources = films.map((film) => {
    return {
      name: caps(film.title),
      description: "This is the description",
      duration: film.runtime,
      sources: [
        {
          src: `https://${awsvideo.awsOutputVideo}/${film.video.id}/${film.video.id}.m3u8`,
          type: "application/x-mpegURL",
        },
      ],
      thumbnail: [
        {
          srcset: `${s3url}${film.cardImage}`,
          type: "image/png",
          media: "(min-width: 400px;)",
        },
        {
          src: `${s3url}${film.cardImage}`,
        },
      ],
    };
  });

  return (
    <>
      <Modal size="xl" show={displayingFilms} onHide={hideFilms} centered>
        <PlayAllPlayer settings={settings} sources={sources} />
      </Modal>
    </>
  );
}

export default PlayAllModal;
