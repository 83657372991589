import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ProgressBar, Spinner } from "react-bootstrap";

function PopoverProgress(props) {
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    if (props.progress > 0 && props.progress < 100) {
      setShowing(true);
    }
  }, [props.progress]);

  const progressInfo = () => {
    if (showing === true) {
      let returnValue = <div>Error!</div>;
      if (props.progress >= 100 || props.progress <= 0) {
        returnValue = <div>Upload Complete!</div>;
      } else if (props.progress > 0) {
        returnValue = (
          <ProgressBar
            now={props.progress}
            label={`${Math.floor(props.progress)}%`}
          />
        );
      } else if (props.progress <= 0) {
        returnValue = <Spinner animation="border" />;
      }
      return returnValue;
    }
  };

  return <div>{progressInfo()}</div>;
}

PopoverProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default PopoverProgress;
