import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCapitalize from "../../../Hooks/useCapitalize";

function FilmmakerBreadcrumbs(props) {
  const { name } = props;

  const caps = useCapitalize;

  return (
    <div>
      <Breadcrumb
        listProps={{ className: "d-flex justify-content-center mx-2" }}
      >
        <Breadcrumb.Item
          className="breadcrumb-color-muted"
          linkAs={Link}
          linkProps={{ to: "/" }}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="breadcrumb-color"
          linkAs={Link}
          linkProps={{ to: "/filmmakers" }}
        >
          Filmmakers
        </Breadcrumb.Item>
        <Breadcrumb.Item active style={{ color: "#9f1f63" }}>
          {caps(name)}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default FilmmakerBreadcrumbs;
