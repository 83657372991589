import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function UpdateName(props) {
  const { user, setModal, handleBackClick, setToggle } = props;

  const [saving, setSaving] = useState(false);

  const [newName, setNewName] = useState(user.attributes.name);

  const handleNameSubmit = async (event) => {
    event.preventDefault();
    setSaving(true);

    if (newName !== user.attributes.name) {
      const attributes = {
        name: newName,
      };

      await Auth.updateUserAttributes(user, attributes).then(() => {
        setToggle("off");
        setModal({
          show: true,
          type: "name",
        });
        setTimeout(() => {
          setModal((prevState) => ({
            ...prevState,
            show: false,
          }));
        }, 2000);
      });
    }
  };

  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  return (
    <Form onSubmit={handleNameSubmit}>
      <Form.Group>
        <div className="d-flex justify-content-start align-items-center mb-1">
          <Button variant="action" onClick={handleBackClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Form.Label className="mb-0 ms-1 fw-bold">Edit Name</Form.Label>
        </div>
        <Form.Control value={newName} onChange={handleChange} />
      </Form.Group>
      <div className="d-flex justify-content-end">
        <Button
          size="lg"
          type="submit"
          variant="grad"
          className="mt-2"
          disabled={newName === user.attributes.name || saving}
        >
          {saving ? "Saving..." : "Save"}
        </Button>
      </div>
    </Form>
  );
}

export default UpdateName;
