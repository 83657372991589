import React, { useReducer } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { createDirector } from "../../../graphql/mutations";
import FilePicker from "../FilePicker/FilePicker";
import DirectorPopover from "./DirectorPopover/DirectorPopover";
import DirectorTable from "./DirectorTable/DirectorTable";

const initialState = {
  directorNameVal: "",
  directorBioVal: "",
  directorLocationVal: "",
  chosenItem: {},
  nextToken: "",
  displayingForm: false,
  imageFile: null,
  imageFileName: null,
  showComponent: false,
  validated: false,
};

function directorUploadReducer(state, action) {
  switch (action.type) {
    case "field": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "setChosenItem": {
      return {
        ...state,
        chosenItem: action.payload,
        action: action.name,
      };
    }
    case "displayForm": {
      return {
        ...state,
        displayingForm: true,
      };
    }
    case "hideForm": {
      return {
        ...state,
        displayingForm: false,
      };
    }
    case "setImage": {
      return {
        ...state,
        imageFile: action.payload,
        imageFileName: action.fileName,
      };
    }
    case "setHeaderImage": {
      return {
        ...state,
        headerImageFile: action.payload,
        headerImageFileName: action.fileName,
      };
    }
    case "showComponent": {
      return {
        ...state,
        showComponent: true,
        chosenItem: action.chosenItem,
        action: action.action,
      };
    }
    case "hideComponent": {
      return {
        ...state,
        showComponent: false,
      };
    }
    case "setValidated": {
      return {
        ...state,
        validated: action.payload,
      };
    }
    case "resetValues": {
      return {
        ...state,
        directorNameVal: "",
        directorBioVal: "",
        directorLocationVal: "",
        validated: false,
      };
    }
    default:
      return state;
  }
}

function DirectorUpload(props) {
  const { directors, items, s3url, tags } = props;

  const [state, dispatch] = useReducer(directorUploadReducer, initialState);
  const {
    directorNameVal,
    directorBioVal,
    directorLocationVal,
    displayingForm,
    imageFile,
    imageFileName,
    chosenItem,
    action,
    showComponent,
    validated,
  } = state;

  const imageCallback = (dataFromChild) => {
    dispatch({
      type: "setImage",
      payload: dataFromChild,
      fileName: dataFromChild.name,
    });
  };

  const handleChange = (event) => {
    dispatch({
      type: "field",
      fieldName: event.target.name,
      payload: event.target.value,
    });
  };

  const onButtonClick = (item, event) => {
    dispatch({
      type: "showComponent",
      chosenItem: item,
      action: event.target.name,
    });
  };

  const showDirectors = directors.map((value, index) => {
    return (
      <tr key={value.id}>
        <td>{value.name}</td>
        <td>{value.bio}</td>
        <td>
          <div className="d-inline-flex justify-content-center">
            <Button
              name="read"
              variant="outline-light"
              size="sm"
              onClick={(e) => onButtonClick(value, e)}
              className="mx-1"
            >
              Read
            </Button>
            <Button
              name="update"
              variant="outline-light"
              size="sm"
              onClick={(e) => onButtonClick(value, e)}
              className="mx-1"
            >
              Update
            </Button>
            <Button
              name="delete"
              variant="outline-light"
              size="sm"
              onClick={(e) => onButtonClick(value, e)}
              className="mx-1"
            >
              Delete
            </Button>
          </div>
        </td>
      </tr>
    );
  });

  const submitDirectorFormHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    dispatch({
      type: "setValidated",
      payload: true,
    });

    if (directorNameVal && directorBioVal && directorLocationVal) {
      event.preventDefault();

      if (imageFile) {
        Storage.put(imageFileName, imageFile).then((response) => {
          console.log(`Successfully Uploaded: ${response.key}`);
        });
      }

      const directorAsset = {
        input: {
          name: directorNameVal,
          bio: directorBioVal,
          location: directorLocationVal,
          ...(imageFile && { image: imageFileName }),
        },
      };

      API.graphql(graphqlOperation(createDirector, directorAsset))
        .then(() => {
          hideDirectorForm();
          dispatch({
            type: "resetValues",
          });
        })
        .catch((err) => console.log(err));
    }
  };

  const displayDirectorForm = () => {
    dispatch({
      type: "displayForm",
    });
  };

  const hideDirectorForm = () => {
    dispatch({
      type: "hideForm",
    });
  };

  const overlayDirectorForm = () => {
    return (
      <Modal show={displayingForm} onHide={hideDirectorForm}>
        <Modal.Header closeButton onHide={hideDirectorForm}>
          Create New Director
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={submitDirectorFormHandler}
            id="director-form"
          >
            <Form.Group>
              <Form.Label>Director Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={directorNameVal}
                name="directorNameVal"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please add a director name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Director Bio</Form.Label>
              <Form.Control
                as="textarea"
                required
                rows={4}
                value={directorBioVal}
                name="directorBioVal"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please add a director bio.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Director Location</Form.Label>
              <Form.Control
                type="text"
                required
                value={directorLocationVal}
                name="directorLocationVal"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please add a director location.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Image</Form.Label>
              <FilePicker type="image" callbackFromParent={imageCallback} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" size="lg" type="submit" form="director-form">
            Create Director
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const popoverOnHide = () => {
    dispatch({
      type: "hideComponent",
    });
  };

  return (
    <div className="pb-3">
      <DirectorPopover
        chosenItem={chosenItem}
        action={action}
        popoverOnHide={popoverOnHide}
        showComponent={showComponent}
        items={items}
        s3url={s3url}
        tags={tags}
      />
      <DirectorTable showDirectors={showDirectors} />
      {overlayDirectorForm()}
      <Button onClick={() => displayDirectorForm()} variant="dark" size="lg">
        Create New Filmmaker
      </Button>
    </div>
  );
}

export default DirectorUpload;
