import React from "react";
import { Nav } from "react-bootstrap";

function MyPlaylists(props) {
  const { playlists, setSelected } = props;

  return (
    <div className="border pb-1 mb-4 mt-2">
      <div className="mt-1">
        <h4>My Playlists</h4>
        <hr className="my-0 mx-4" style={{ opacity: "100%" }} />
      </div>
      <div
        style={{
          overflowY: "auto",
          maxHeight: "700px",
        }}
      >
        <Nav
          defaultActiveKey={0}
          onSelect={(selectedKey) => setSelected(selectedKey)}
          className="flex-column"
        >
          {playlists.length > 0 &&
            playlists.map((element, index) => (
              <Nav.Item key={index}>
                <Nav.Link className="iris-nav" eventKey={index}>
                  {element.name}
                </Nav.Link>
              </Nav.Item>
            ))}
        </Nav>
      </div>
    </div>
  );
}

export default MyPlaylists;
