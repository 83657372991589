import React from "react";
import { Table } from "react-bootstrap";

function FilmTable(props) {
  const { showItems } = props;
  return (
    <div>
      <Table variant="dark" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Title</th>
            <th>Director</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{showItems}</tbody>
      </Table>
    </div>
  );
}

export default FilmTable;
