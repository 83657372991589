import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function ForgotPassword(props) {
  const { email, handleBackClick, setToggle, username } = props;

  const [sending, setSending] = useState(false);

  const handleClick = async () => {
    setSending(true);
    await Auth.forgotPassword(username).then(() => {
      setToggle("resetPassword");
    });
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-1">
        <Button onClick={handleBackClick} variant="action">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Form.Label className="mb-0 ms-1 fw-bold">Forgot Password</Form.Label>
      </div>
      <div>
        <p>
          To reset your password, click Send to receive a verification code to
          the email below:
        </p>
        <h5>{email}</h5>
        <Button
          disabled={sending}
          onClick={handleClick}
          variant="grad"
          size="lg"
          className="mt-2"
        >
          {sending ? "Sending..." : "Send"}
        </Button>
      </div>
    </>
  );
}

export default ForgotPassword;
