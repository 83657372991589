import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Popover, Form, Image } from "react-bootstrap";
import {
  createAnswer,
  deleteQuestion,
  updateAnswer,
  updateQuestion,
} from "../../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import useCapitalize from "../../../Hooks/useCapitalize";

function Question(props) {
  const { s3url, username, data, groups, director, fullName, filmID } = props;

  const { createdAt, content, picture, name, owner, id, answer } = data;

  const caps = useCapitalize;

  const [editable, setEditable] = useState({
    question: false,
    answer: false,
  });

  const [newFormState, setNewFormState] = useState({
    question: content,
    answer: "",
  });
  const [popoverToggle, setPopoverToggle] = useState({
    question: false,
    answer: false,
  });

  useEffect(() => {
    if (answer) {
      setNewFormState((prevState) => ({
        ...prevState,
        answer: answer.content,
      }));
    }
  }, [answer]);

  const createdDateQuestion = new Date(createdAt);

  const deleteItem = async () => {
    setPopoverToggle(false);
    await API.graphql(
      graphqlOperation(deleteQuestion, {
        input: {
          id: id,
        },
      })
    );
  };

  const handleChange = (event) => {
    setNewFormState({
      ...newFormState,
      [event.target.name]: event.target.value,
    });
  };

  const handleQuestionSubmit = async (event) => {
    event.preventDefault();
    const updatedQuestion = {
      input: {
        id: id,
        content: newFormState.question,
      },
    };
    await API.graphql(graphqlOperation(updateQuestion, updatedQuestion)).then(
      () => {
        setEditable({ ...editable, question: false });
      }
    );
  };

  const handleAnswerSubmit = async (event) => {
    event.preventDefault();
    const newAnswer = {
      input: {
        content: newFormState.answer,
        picture: picture,
        name: caps(fullName),
        vodAssetId: filmID,
      },
    };
    await API.graphql(graphqlOperation(createAnswer, newAnswer))
      .then(async (res) => {
        const variables = {
          input: {
            id: id,
            questionAnswerId: res.data.createAnswer.id,
          },
        };

        await API.graphql(graphqlOperation(updateQuestion, variables)).then(
          () => {
            setEditable({
              ...editable,
              answer: false,
            });
          }
        );
      })
      .catch((err) => console.log(err));
  };

  const toggleEdit = (type) => {
    setPopoverToggle(false);
    setEditable({ ...editable, [type]: !editable[type] });
  };

  const togglePopover = (type) => {
    setPopoverToggle({ ...popoverToggle, [type]: !popoverToggle[type] });
  };

  const handleUpdateAnswer = async (event) => {
    event.preventDefault();

    const variables = {
      input: {
        id: answer.id,
        content: newFormState.answer,
      },
    };

    await API.graphql(graphqlOperation(updateAnswer, variables)).then(() => {
      setEditable({ ...editable, answer: false });
    });
  };

  const showOptions = (type) => {
    if (
      (type === "question" && username === owner) ||
      fullName === caps(director) ||
      groups
    ) {
      return (
        <OverlayTrigger
          trigger="click"
          placement="left"
          show={popoverToggle[type]}
          overlay={
            <Popover>
              <Popover.Body
                as={Button}
                variant="link"
                onClick={() => toggleEdit(type)}
              >
                Edit
              </Popover.Body>
              {type === "question" && (
                <Popover.Body as={Button} variant="link" onClick={deleteItem}>
                  Delete
                </Popover.Body>
              )}
            </Popover>
          }
        >
          <Button
            onClick={() => togglePopover(type)}
            variant="outline-light"
            size="sm"
          >
            . . .
          </Button>
        </OverlayTrigger>
      );
    }
  };

  const showQuestion = () => {
    if (!editable.question) {
      return (
        <div className="text-start">
          <h5 className="fw-bold mt-2">{content}</h5>
        </div>
      );
    }
    return (
      <Form onSubmit={handleQuestionSubmit} className="mb-1">
        <Form.Control
          as="textarea"
          rows={4}
          onChange={handleChange}
          name="question"
          value={newFormState.question}
          className="mt-2"
        />
        <div className="pt-2 d-flex justify-content-between">
          <Button variant="grad" size="sm" type="submit">
            Repost
          </Button>
          <Button
            variant="grad"
            size="sm"
            onClick={() => setEditable({ ...editable, question: false })}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  };

  const showAnswer = () => {
    if (fullName === caps(director) && !answer) {
      return (
        <Form onSubmit={handleAnswerSubmit} className="mb-1">
          <Form.Control
            as="textarea"
            rows={4}
            onChange={handleChange}
            name="answer"
            className="mt-2"
          />
          <div className="pt-2 text-end">
            <Button variant="grad" type="submit">
              Post Answer
            </Button>
          </div>
        </Form>
      );
    } else if (editable.answer) {
      return (
        <Form onSubmit={handleUpdateAnswer} className="mb-1">
          <Form.Control
            as="textarea"
            rows={4}
            onChange={handleChange}
            name="answer"
            value={newFormState.answer}
            className="mt-2"
          />
          <div className="pt-2 d-flex justify-content-between">
            <Button variant="grad" size="sm" type="submit">
              Repost
            </Button>
            <Button
              variant="grad"
              size="sm"
              onClick={() => setEditable({ ...editable, answer: false })}
            >
              Cancel
            </Button>
          </div>
        </Form>
      );
    } else if (answer) {
      return (
        <div className="d-flex justify-content-between">
          <p className="mb-2">{answer.content}</p>
          <div>{showOptions("answer")}</div>
        </div>
      );
    }
  };

  const showResponse = () => {
    if (answer) {
      const createdDateAnswer = new Date(answer.createdAt);
      return (
        <p
          className="opacity-50 mb-0 text-start"
          style={{ fontSize: "0.8rem" }}
        >
          Answered by {caps(director)} on{" "}
          {createdDateAnswer.toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
            day: "2-digit",
          })}
        </p>
      );
    }
    return (
      <p className="opacity-50 mb-0 text-start" style={{ fontSize: "0.8rem" }}>
        Unanswered
      </p>
    );
  };

  return (
    <div>
      <hr />
      <div className="d-flex">
        <Image
          style={{
            objectFit: "cover",
            borderRadius: "50%",
            border: "3px solid #FFFFFF",
            width: "50px",
            height: "50px",
          }}
          src={`${s3url}${picture}`}
        />
        <div className="ms-2 my-auto text-start">
          <h5 className="mb-0">{name}</h5>
          <p className="opacity-50 mb-0" style={{ fontSize: "0.8rem" }}>
            {createdDateQuestion.toLocaleDateString("en-US", {
              month: "long",
              year: "numeric",
              day: "2-digit",
            })}
          </p>
        </div>
        <div className="ms-auto">{showOptions("question")}</div>
      </div>
      {showQuestion()}
      {showAnswer()}
      {showResponse()}
    </div>
  );
}

export default Question;
