import React from "react";
import { Carousel, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function MainCarousel(props) {
  const { carouselImages, s3url } = props;
  const image1 = carouselImages.find((element) => element.order === 1);
  const image2 = carouselImages.find((element) => element.order === 2);
  const image3 = carouselImages.find((element) => element.order === 3);

  const findLink = (image) => {
    const directorName = image.directorName
      .replaceAll("#", "")
      .replaceAll(" ", "-")
      .toLowerCase();

    if (image.type === "director") {
      return `/filmmaker/${directorName}`;
    } else if (image.type === "film") {
      const filmTitle = image.title.replaceAll(" ", "-").toLowerCase();
      return `/filmmaker/${directorName}/film/${filmTitle}`;
    }
  };

  return (
    <Carousel>
      <Carousel.Item
        as={Link}
        to={findLink(image1)}
        state={{
          ...(image1.type === "director"
            ? { id: image1.directorID }
            : { id: image1.filmID }),
          name: image1.directorName,
          ...(image1.title && { title: image1.title }),
          ...(image1.type === "film" && { directorId: image1.directorID }),
        }}
      >
        <Image fluid src={`${s3url}${image1.image}`} />
      </Carousel.Item>
      <Carousel.Item
        as={Link}
        to={findLink(image2)}
        state={{
          ...(image2.type === "director"
            ? { id: image2.directorID }
            : { id: image2.filmID }),
          name: image2.directorName,
          ...(image2.title && { title: image2.title }),
          ...(image2.type === "film" && { directorId: image2.directorID }),
        }}
      >
        <Image fluid src={`${s3url}${image2.image}`} />
      </Carousel.Item>
      <Carousel.Item
        as={Link}
        to={findLink(image3)}
        state={{
          ...(image3.type === "director"
            ? { id: image3.directorID }
            : { id: image3.filmID }),
          name: image3.directorName,
          ...(image3.title && { title: image3.title }),
          ...(image3.type === "film" && { directorId: image3.directorID }),
        }}
      >
        <Image fluid src={`${s3url}${image3.image}`} />
      </Carousel.Item>
    </Carousel>
  );
}

export default MainCarousel;
