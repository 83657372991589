import React, { useState, useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import NotificationContext from "../../../../context/NotificationContext";
import Notification from "../../../Notification/Notification";

function UpdateEmail(props) {
  const { user, handleBackClick, setToggle, newEmail, setNewEmail } = props;

  const notificationCtx = useContext(NotificationContext);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    return () => {
      notificationCtx.clear();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    setNewEmail(event.target.value);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();

    setSaving(true);

    if (newEmail !== user.attributes.email) {
      const newObject = {
        email: newEmail,
      };

      await Auth.updateUserAttributes(user, newObject)
        .then(() => {
          setToggle("verifyEmail");
        })
        .catch((e) => {
          setSaving(false);
          const str = e.toString();
          notificationCtx.error(str.substring(str.indexOf(":") + 2));
        });
    }
  };

  return (
    <>
      <Notification />
      <Form onSubmit={handleEmailSubmit}>
        <Form.Group>
          <div className="d-flex justify-content-start align-items-center mb-1">
            <Button onClick={handleBackClick} variant="action">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Form.Label className="mb-0 ms-1 fw-bold">Edit Email</Form.Label>
          </div>
          <Form.Control
            value={newEmail}
            onChange={handleChange}
            name="email"
            type="email"
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button
            size="lg"
            type="submit"
            variant="grad"
            className="mt-2"
            disabled={saving || newEmail === user.attributes.email}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default UpdateEmail;
