import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ManageSubscriptionModal from "./ManageSubscriptionModal/ManageSubscriptionModal";

function ManageSubscription(props) {
  const { handleBackClick, user, setToggle } = props;

  const interval = user.attributes["custom:interval"];
  const subscriptionStatus = user.attributes["custom:subscriptionStatus"];

  const [modalOptions, setModalOptions] = useState({
    type: null,
    show: false,
  });

  const showCards = () => {
    const yearlyCard = (
      <>
        <Card bg="black" border="light">
          <Card.Body className="py-3">
            <Form>
              <Form.Group className="d-flex justify-content-between">
                <p className="mb-0 text-white">Yearly - $49.99 / year</p>
                <Form.Check
                  name="group1"
                  id="selection-1"
                  type="radio"
                  className="mt-0 iris-radio"
                  checked={interval === "Yearly" || modalOptions.show}
                  disabled={interval === "Yearly"}
                  onChange={() => {
                    interval === "Monthly" && showModal("changePlan");
                  }}
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </>
    );

    const monthlyCard = (
      <>
        <Card bg="black" border="light">
          <Card.Body className="py-3">
            <Form>
              <Form.Group className="d-flex justify-content-between">
                <p className="mb-0 text-white">Monthly - $5.99 / month</p>
                <Form.Check
                  name="group1"
                  id="selection-2"
                  type="radio"
                  className="mt-0 iris-radio"
                  checked={interval === "Monthly" || modalOptions.show}
                  disabled={interval === "Monthly"}
                  onChange={() => {
                    interval === "Yearly" && showModal("changePlan");
                  }}
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </>
    );

    return (
      <div key="selection">
        <p className=" text-start mb-1 ms-1 fw-bold">Current Plan</p>
        {interval === "Yearly" ? yearlyCard : monthlyCard}
        <p className=" text-start mb-1 ms-1 fw-bold mt-2">Available Plan</p>
        {interval === "Yearly" ? monthlyCard : yearlyCard}
      </div>
    );
  };

  const showModal = (option) => {
    setModalOptions({
      type: option,
      show: true,
    });
  };

  const hideModal = () => {
    setModalOptions({
      type: null,
      show: false,
    });
  };

  const showOptions = () => {
    if (interval === "Monthly") {
      if (subscriptionStatus === "active") {
        return (
          <div className="d-flex justify-content-between">
            <Button size="lg" variant="grad" onClick={() => showModal("pause")}>
              Pause Subscription
            </Button>
            <Button
              size="lg"
              variant="grad"
              onClick={() => showModal("cancel")}
            >
              Cancel Subscription
            </Button>
          </div>
        );
      } else if (subscriptionStatus === "paused") {
        return (
          <div className="d-flex justify-content-center">
            <Button
              size="lg"
              variant="grad"
              onClick={() => showModal("cancel")}
            >
              Cancel Subscription
            </Button>
          </div>
        );
      }
    } else if (interval === "Yearly") {
      return (
        <div className="d-flex justify-content-center">
          <Button size="lg" variant="grad" onClick={() => showModal("cancel")}>
            Cancel Subscription
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <ManageSubscriptionModal
        type={modalOptions.type}
        show={modalOptions.show}
        hideModal={hideModal}
        user={user}
        setToggle={setToggle}
      />
      <div className="d-flex justify-content-start align-items-center mb-1">
        <Button variant="action" onClick={handleBackClick}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <p className="mb-0 ms-1 fw-bold">Manage Subscription</p>
      </div>
      {showCards()}
      <hr />
      {showOptions()}
    </>
  );
}

export default ManageSubscription;
