import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Button,
  Popover,
  Image,
  Fade,
} from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { Helmet } from "react-helmet";
import {
  createPlaylist,
  deletePlaylist,
  updatePlaylist,
  createPlaylistFilms,
  deletePlaylistFilms,
} from "../../../graphql/mutations";
import { listPlaylists, getPlaylist } from "../../../graphql/queries";
import CreatePlaylist from "./CreatePlaylist/CreatePlaylist";
import EditPlaylistPopover from "./EditPlaylistPopover/EditPlaylistPopover";
import MyPlaylists from "./MyPlaylists/MyPlaylists";
import DisplayPlaylist from "./DisplayPlaylist/DisplayPlaylist";
import AddPlaylistPopover from "./AddPlaylistPopover/AddPlaylistPopover";
import PlaylistBreadcrumbs from "./PlaylistBreadcrumbs/PlaylistBreadcrumbs";

function Playlists(props) {
  const { s3url, user } = props;

  const [option, setOption] = useState(null);

  const [playlists, setPlaylists] = useState({
    items: [],
    selectedIndex: 0,
  });

  const [toggle, setToggle] = useState({
    form: false,
    modal: false,
    popover: false,
    addPlaylist: false,
  });

  const [formState, setFormState] = useState({
    name: "",
    newName: "",
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getPlaylists = async () => {
      return await API.graphql(graphqlOperation(listPlaylists));
    };

    getPlaylists().then((response) => {
      response.data.listPlaylists.items.forEach(async (playlist) => {
        await API.graphql(
          graphqlOperation(getPlaylist, { id: playlist.id })
        ).then((p) => {
          setPlaylists((prevState) => ({
            ...playlists,
            items: prevState.items.concat(p.data.getPlaylist),
          }));
        });
      });
      setOpen(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let selectedPlaylist = playlists.items[playlists.selectedIndex];

  // Playlist Mutations

  const saveList = async (event) => {
    event.preventDefault();

    const newPlaylist = {
      input: {
        name: formState.name,
      },
    };

    await API.graphql(graphqlOperation(createPlaylist, newPlaylist)).then(
      (res) => {
        setPlaylists((prevState) => ({
          ...playlists,
          items: prevState.items.concat(res.data.createPlaylist),
          selectedIndex: prevState.items.length,
        }));

        toggleForm();
      }
    );
  };

  const deleteList = async () => {
    await Promise.all(
      selectedPlaylist.films.items.map((item) => {
        return API.graphql(
          graphqlOperation(deletePlaylistFilms, { input: { id: item.id } })
        );
      })
    );

    await API.graphql(
      graphqlOperation(deletePlaylist, {
        input: {
          id: selectedPlaylist.id,
        },
      })
    ).then((res) => {
      setPlaylists((prevState) => ({
        ...prevState,
        items: prevState.items.filter(
          (playlist) => playlist.id !== res.data.deletePlaylist.id
        ),
        ...(Number(prevState.selectedIndex) === prevState.items.length - 1 && {
          selectedIndex: Number(prevState.selectedIndex) - 1,
        }),
      }));
      hideModalPopover();
    });
  };

  const renameList = async (event) => {
    event.preventDefault();
    const updatedName = {
      input: {
        id: selectedPlaylist.id,
        name: formState.newName,
      },
    };
    await API.graphql(graphqlOperation(updatePlaylist, updatedName)).then(
      (res) => {
        setPlaylists((prevState) => ({
          ...prevState,
          items: prevState.items.map((item) => {
            return item.id === res.data.updatePlaylist.id
              ? res.data.updatePlaylist
              : item;
          }),
        }));

        setFormState({
          ...formState,
          newName: "",
        });

        hideModalPopover();
      }
    );
  };

  // Film Mutations

  const addFilm = async (value) => {
    const newPlaylistFilms = {
      input: {
        vodAssetID: value.id,
        playlistID: selectedPlaylist.id,
      },
    };

    await API.graphql(
      graphqlOperation(createPlaylistFilms, newPlaylistFilms)
    ).then((res) => {
      const { vodAsset, playlist, ...rest } = res.data.createPlaylistFilms;

      const updatedPlaylist = {
        ...selectedPlaylist,
        films: {
          items: selectedPlaylist.films.items.concat(rest),
        },
      };

      setPlaylists((prevState) => ({
        ...playlists,
        items: prevState.items.map((playlist) =>
          playlist.id === updatedPlaylist.id ? updatedPlaylist : playlist
        ),
      }));
    });
  };

  const deleteFilm = async (id) => {
    const item = selectedPlaylist.films.items.find(
      (item) => item.vodAssetID === id
    );
    await API.graphql(
      graphqlOperation(deletePlaylistFilms, {
        input: {
          id: item.id,
        },
      })
    ).then((res) => {
      const updatedPlaylist = {
        ...selectedPlaylist,
        films: {
          items: selectedPlaylist.films.items.filter(
            (item) =>
              item.vodAssetID !== res.data.deletePlaylistFilms.vodAssetID
          ),
        },
      };

      setPlaylists((prevState) => ({
        ...playlists,
        items: prevState.items.map((playlist) =>
          playlist.id === updatedPlaylist.id ? updatedPlaylist : playlist
        ),
      }));
    });
  };

  // Form Handlers

  const handleChange = (event) => {
    setFormState({
      ...formState,
      name: event.target.value,
    });
  };

  const handleNameChange = (event) => {
    setFormState({
      ...formState,
      newName: event.target.value,
    });
  };

  const setSelected = (index) => {
    setPlaylists({
      ...playlists,
      selectedIndex: index,
    });
  };

  // Toggles

  const toggleForm = () => {
    setToggle({
      ...toggle,
      form: !toggle.form,
    });

    setFormState({
      ...formState,
      name: "",
    });
  };

  const togglePopover = () => {
    setToggle({
      ...toggle,
      popover: !toggle.popover,
    });
  };

  const showModalPopover = (event) => {
    setOption(event.target.name);

    setToggle({
      ...toggle,
      modal: true,
      popover: false,
    });
  };

  const hideModalPopover = () => {
    setOption(null);

    setFormState({
      ...formState,
      newName: "",
    });

    setToggle({
      ...toggle,
      modal: false,
    });
  };

  const showPlaylistModal = () => {
    setToggle({
      ...toggle,
      addPlaylist: true,
    });
  };

  const hidePlaylistModal = () => {
    setToggle({
      ...toggle,
      addPlaylist: false,
    });
  };

  return (
    <div className="min-vh-100">
      <Helmet>
        <title>Iris - Playlists</title>
      </Helmet>
      <PlaylistBreadcrumbs />
      <Fade in={open}>
        <div className="text-white pb-4">
          <Container>
            <Row>
              <Col>
                <Image
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                    border: "10px solid #FFFFFF",
                    width: "300px",
                    height: "300px",
                  }}
                  src={`${s3url}${user.attributes.picture}`}
                />
                <h4 className="mt-2 text-white">{user.attributes.name}</h4>
              </Col>
              <Col className="my-auto">
                {playlists.items.length >= 1 ? (
                  <div>
                    <div className="text-start">
                      <p className="mb-0">My Playlist</p>
                      <p
                        style={{ fontSize: "50px" }}
                        className="mb-2 font-weight-bold"
                      >
                        {selectedPlaylist && selectedPlaylist.name}
                      </p>
                      <OverlayTrigger
                        trigger="click"
                        placement="bottom-start"
                        show={toggle.popover}
                        overlay={
                          <Popover>
                            <Popover.Body
                              name="rename"
                              as={Button}
                              variant="link"
                              onClick={(e) => showModalPopover(e)}
                            >
                              Rename
                            </Popover.Body>
                            <Popover.Body
                              name="delete"
                              as={Button}
                              variant="link"
                              onClick={(e) => showModalPopover(e)}
                            >
                              Delete
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                          onClick={togglePopover}
                          variant="outline-light"
                          size="sm"
                        >
                          . . .
                        </Button>
                      </OverlayTrigger>
                      {selectedPlaylist && (
                        <EditPlaylistPopover
                          modalToggle={toggle.modal}
                          closeButton
                          hideModalPopover={hideModalPopover}
                          option={option}
                          playlistID={selectedPlaylist.id}
                          playlistName={selectedPlaylist.name}
                          deleteList={deleteList}
                          handleNameChange={handleNameChange}
                          renameList={renameList}
                          newName={formState.newName}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="text-start">
                    <p style={{ fontSize: "50px" }}>Playlists</p>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="mt-4 d-flex flex-column flex-fill flex-md-row">
              <Col className="col-sm-3">
                <CreatePlaylist
                  handleChange={handleChange}
                  showForm={toggle.form}
                  toggleForm={toggleForm}
                  saveList={saveList}
                />
                <MyPlaylists
                  playlists={playlists.items}
                  setSelected={setSelected}
                />
              </Col>
              <Col>
                {selectedPlaylist && (
                  <>
                    <DisplayPlaylist
                      s3url={s3url}
                      showPlaylistModal={showPlaylistModal}
                      films={selectedPlaylist.films.items}
                      deleteFilm={deleteFilm}
                    />
                    <AddPlaylistPopover
                      s3url={s3url}
                      addFilm={addFilm}
                      addPlaylistToggle={toggle.addPlaylist}
                      hidePlaylistModal={hidePlaylistModal}
                      selectedPlaylist={selectedPlaylist}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Fade>
    </div>
  );
}

export default Playlists;
