import React from "react";
import { Pagination } from "react-bootstrap";
import "./Paginate.css";

function Paginate(props) {
  const { nPages, currentPage, setCurrentPage, nextToken, handleNext } = props;

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const prevPage = () => {
    if (currentPage === 1) {
    }
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (nPages === 1 && nextToken) {
      return (
        <Pagination.Next
          linkClassName="bg-dark iris-nav"
          onClick={handleNext}
        />
      );
    } else if (currentPage === nPages && nextToken) {
      return (
        <Pagination.Next
          linkClassName="bg-dark iris-nav"
          onClick={handleNext}
        />
      );
    } else if (currentPage !== nPages) {
      return (
        <Pagination.Next
          linkClassName="bg-dark iris-nav"
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      );
    }
  };

  return (
    <Pagination className="mb-0">
      {currentPage !== 1 && (
        <Pagination.Prev onClick={prevPage} linkClassName="bg-dark iris-nav" />
      )}
      {pageNumbers.map((pgNumber, index) => {
        return (
          <Pagination.Item
            key={index}
            onClick={() => setCurrentPage(pgNumber)}
            active={currentPage === pgNumber}
            {...(currentPage === pgNumber
              ? { linkClassName: "activePage" }
              : { linkClassName: "bg-dark iris-nav" })}
          >
            {pgNumber}
          </Pagination.Item>
        );
      })}
      {nextPage()}
    </Pagination>
  );
}

export default Paginate;
