import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { API, Auth } from "aws-amplify";

function ResubscribeModal(props) {
  const {
    subscriptionId,
    date,
    show,
    hideResubModal,
    interval,
    last4,
    brand,
    handleToggle,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleResubscribe = async () => {
    setLoading(true);
    const body = {
      subscriptionId: subscriptionId,
    };

    await API.post("apic5c1ed0b", "/reactivate-subscription", { body }).then(
      async () => {
        const user = await Auth.currentAuthenticatedUser();

        await Auth.updateUserAttributes(user, {
          "custom:subscriptionStatus": "active",
        }).then(() => {
          hideResubModal();
          setLoading(false);
          handleToggle("off");
        });
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={hideResubModal}
      contentClassName="bg-dark"
      centered
      className="text-white"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Resubscribe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>{`Your subscription will resume ${interval.toLowerCase()} payments using your ${brand} ending in ${last4} on ${date}.`}</p>
          <Button
            variant="grad"
            size="lg"
            onClick={handleResubscribe}
            disabled={loading}
          >
            {loading ? "Loading..." : "Confirm Resubscribe"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ResubscribeModal;
