import React from "react";
import { Navbar, Image, Nav } from "react-bootstrap";
import FooterLogo from "./FooterLogo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand className="ms-3" href="/">
        <Image src={FooterLogo} height={"50rem"} />
      </Navbar.Brand>
      <Nav>
        <Nav.Link as={Link} to="/about">
          About
        </Nav.Link>
        <Nav.Link as={Link} to="/contact">
          Contact
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}

export default Footer;
