import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import ManageSubscription from "./ManageSubscription/ManageSubscription";
import ResubscribeModal from "./ResubscribeModal";

import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function BillingActions(props) {
  const { user } = props;

  const [toggle, setToggle] = useState("off");

  const [modal, setModal] = useState({
    show: false,
    type: null,
  });

  const [showResubscribeModal, setShowResubscribeModal] = useState(false);

  const brand = user.attributes["custom:brand"];
  const last4 = user.attributes["custom:last4"];
  const interval = user.attributes["custom:interval"];
  const subscriptionStatus = user.attributes["custom:subscriptionStatus"];
  const subscriptionId = user.attributes["custom:SubscriptionId"];

  const opts = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const expireDate = new Date(
    user.attributes["custom:expireTime"]
  ).toLocaleDateString("en-us", opts);

  const resumeDate = new Date(
    user.attributes["custom:resumeTime"]
  ).toLocaleDateString("en-us", opts);

  const handleBackClick = () => {
    setToggle("off");
  };

  const renderModal = () => {
    return (
      <Modal show={modal.show} backdrop={false} contentClassName="bg-dark">
        <Modal.Body className="text-white text-center">
          You have successfully updated your {modal.type}.
        </Modal.Body>
      </Modal>
    );
  };

  const options = {
    mode: "setup",
    currency: "usd",
  };

  const showResubModal = () => {
    setShowResubscribeModal(true);
  };

  const hideResubModal = () => {
    setShowResubscribeModal(false);
  };

  const showToggle = () => {
    switch (toggle) {
      case "payment":
        return (
          <Elements stripe={stripe} options={options}>
            <UpdatePaymentMethod
              setModal={setModal}
              handleBackClick={handleBackClick}
              user={user}
              setToggle={setToggle}
            />
          </Elements>
        );
      case "subscription":
        return (
          <ManageSubscription
            handleBackClick={handleBackClick}
            user={user}
            setToggle={setToggle}
          />
        );
      default:
        break;
    }
  };

  const handleToggle = (type) => {
    setToggle(type);
  };

  const showDetails = () => {
    if (subscriptionStatus === "active") {
      return (
        <>
          <div className="d-flex justify-content-between align-items-between">
            <p className="fw-bold">Subscription Price</p>
            <p>{interval === "Yearly" ? "$49.99" : "$5.99"}</p>
          </div>
          <div className="d-flex justify-content-between align-items-between">
            <p className="fw-bold">Upcoming Charge</p>
            <p>{expireDate}</p>
          </div>
          <p className="text-secondary text-start">
            {`Your subscription will automatically renew at ${
              interval === "Yearly" ? "$49.99" : "$5.99"
            } 
    each ${
      interval === "Yearly" ? "year" : "month"
    }  until canceled. You can cancel automatic renewal in your
    account settings anytime before ${expireDate}.`}
          </p>
        </>
      );
    } else if (subscriptionStatus === "paused") {
      return (
        <div className="text-start">
          <div className="d-flex justify-content-between align-items-between">
            <p className="fw-bold">Subscription Price</p>
            <p>{interval === "Yearly" ? "$49.99" : "$5.99"}</p>
          </div>
          <div className="d-flex justify-content-between align-items-between">
            <p className="fw-bold">Pause Date</p>
            <p>{expireDate}</p>
          </div>
          <div className="d-flex justify-content-between align-items-between">
            <p className="fw-bold">Resume Date</p>
            <p>{resumeDate}</p>
          </div>
          <p className="text-secondary">
            {`Your subscription will pause on ${expireDate}, and automatic monthly payments of $5.99 will resume on ${resumeDate}.`}
          </p>
        </div>
      );
    }
  };

  const showPendingInactive = () => {
    return (
      <>
        <ResubscribeModal
          date={expireDate}
          subscriptionId={subscriptionId}
          show={showResubscribeModal}
          hideResubModal={hideResubModal}
          interval={interval}
          last4={last4}
          brand={brand}
          handleToggle={handleToggle}
        />
        <div className="text-center mt-4">
          <p>Your subscription will cancel on:</p>
          <h5>{expireDate}</h5>
          <Button
            size="lg"
            variant="grad"
            className="mt-2"
            onClick={showResubModal}
          >
            Resubscribe
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {renderModal()}
      {subscriptionStatus === "pendingInactive" ? (
        showPendingInactive()
      ) : toggle === "off" ? (
        <>
          <div className="d-grid">
            <Button variant="action" onClick={() => handleToggle("payment")}>
              <div className="d-flex justify-content-between align-items-center py-2">
                <div className="text-start">
                  <p className="mb-0 fw-bold">Payment Method</p>
                  <p className="mb-0">{`${brand} ending in ${last4}`}</p>
                </div>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </Button>
            <hr />
            <Button
              variant="action"
              onClick={() => handleToggle("subscription")}
            >
              <div className="d-flex justify-content-between align-items-center py-2">
                <div className="text-start">
                  <p className="mb-0 fw-bold">Manage Subscription</p>
                  <p className="mb-0">{interval}</p>
                </div>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </Button>
            <hr />
            {showDetails()}
          </div>
        </>
      ) : (
        showToggle()
      )}
    </>
  );
}

export default BillingActions;
