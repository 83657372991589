import React from "react";
import { Modal } from "react-bootstrap";
import VideoPlayer from "../../../Video/VideoPlayer/VideoPlayer";

function SinglePlayModal(props) {
  const { displayingSingleFilm, hideSingleFilm, singleFilmSources } = props;

  const options = {
    controls: true,
    sources: singleFilmSources,
    fluid: true,
    bigPlayButton: false,
    controlBar: {
      children: {
        playToggle: true,
        volumeControl: true,
        progressControl: true,
        fullscreenToggle: true,
      },
    },
    autoplay: true,
  };

  return (
    <Modal
      size="xl"
      show={displayingSingleFilm}
      onHide={hideSingleFilm}
      centered
    >
      <VideoPlayer options={options} />
    </Modal>
  );
}

export default SinglePlayModal;
