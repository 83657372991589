import React from "react";
import PropTypes from "prop-types";
import "./FilePicker.css";
import { Form } from "react-bootstrap";

function FilePicker(props) {
  const { callbackFromParent, name, type } = props;

  const onChange = (e) => {
    if (type === "video" || type === "image") {
      e.preventDefault();
      const file = e.target.files[0];
      callbackFromParent(file, name);
    } else if (type === "specialFeatureImage") {
      e.preventDefault();
      const files = [...e.target.files];
      callbackFromParent(files);
    }
  };

  const showFilePicker = () => {
    if (type === "video") {
      return (
        <>
          <Form.Group>
            <Form.Control
              type="file"
              id="FilePicker"
              accept="video/*"
              onChange={onChange}
            />
          </Form.Group>
        </>
      );
    } else if (type === "image") {
      return (
        <>
          <Form.Group>
            <Form.Control
              type="file"
              id="FilePicker"
              accept="image/*"
              onChange={onChange}
            />
          </Form.Group>
        </>
      );
    } else if (type === "specialFeatureImage") {
      return (
        <>
          <Form.Group>
            <Form.Control
              type="file"
              id="FilePicker"
              accept="image/*"
              onChange={onChange}
              multiple
            />
          </Form.Group>
        </>
      );
    }
  };

  return <>{showFilePicker()}</>;
}

FilePicker.propTypes = {
  callbackFromParent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default FilePicker;
