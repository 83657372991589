import React from "react";
import { Table } from "react-bootstrap";

function DirectorTable(props) {
  const { showDirectors } = props;
  return (
    <div>
      <Table variant="dark" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Bio</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{showDirectors}</tbody>
      </Table>
    </div>
  );
}

export default DirectorTable;
