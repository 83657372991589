import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { createComment } from "../../../../graphql/mutations";
import {
  onCreateComment,
  onDeleteComment,
  onUpdateComment,
} from "../../../../graphql/subscriptions";
import { commentsByDate } from "../../../../graphql/queries";
import Comment from "./Comment";
import Paginate from "../Paginate/Paginate";

function Comments(props) {
  const { filmID, height, user, s3url } = props;

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [groups, setGroups] = useState(null);

  const recordsPerPage = Math.ceil(height / 145);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = comments.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(comments.length / recordsPerPage);

  useEffect(() => {
    const getComments = async () => {
      return await API.graphql({
        query: commentsByDate,
        variables: {
          limit: recordsPerPage,
          vodAssetId: filmID,
          sortDirection: "ASC",
        },
      });
    };

    getComments().then((result) => {
      setComments(result.data.commentsByDate.items);
      if (result.data.commentsByDate.nextToken) {
        setNextToken(result.data.commentsByDate.nextToken);
      }
    });

    const getGroups = async () => {
      return await Auth.currentSession();
    };

    getGroups().then((data) => {
      const sessionGroups = data.idToken.payload["cognito:groups"];
      if (sessionGroups) {
        setGroups(sessionGroups);
      }
    });

    const listenForNewComments = async () => {
      const variables = {
        filter: {
          vodAssetId: { eq: filmID },
        },
      };

      await API.graphql(graphqlOperation(onCreateComment, variables)).subscribe(
        {
          next: (data) => {
            if (!nextToken) {
              setComments((prevState) => {
                return prevState.concat(data.value.data.onCreateComment);
              });
            }
          },
        }
      );
    };

    listenForNewComments();

    const listenForUpdatedComments = async () => {
      await API.graphql(
        graphqlOperation(onUpdateComment, { owner: user.username })
      ).subscribe({
        next: (data) => {
          const newObject = data.value.data.onUpdateComment;

          setComments((prevState) => {
            if (prevState.some(({ id }) => id === newObject.id)) {
              return prevState.map((item) =>
                item.id === newObject.id ? newObject : item
              );
            } else {
              return prevState;
            }
          });
        },
      });
    };

    listenForUpdatedComments();

    const listenForDeletedComments = async () => {
      const variables = {
        filter: {
          vodAssetId: { eq: filmID },
        },
      };

      await API.graphql(graphqlOperation(onDeleteComment, variables)).subscribe(
        {
          next: (data) => {
            setComments((prevState) => {
              const dataObject = data.value.data.onDeleteComment;

              if (prevState.some(({ id }) => id === dataObject.id)) {
                return prevState.filter(({ id }) => id !== dataObject.id);
              } else {
                return prevState;
              }
            });
          },
        }
      );
    };

    listenForDeletedComments();
  }, [filmID]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (comments.length > 0 && currentRecords.length === 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentRecords, currentPage, comments]);

  const handleNext = async () => {
    await API.graphql({
      query: commentsByDate,
      variables: {
        limit: recordsPerPage,
        vodAssetId: filmID,
        sortDirection: "ASC",
        nextToken: nextToken,
      },
    }).then((result) => {
      setComments((prevState) =>
        prevState.concat(result.data.commentsByDate.items)
      );
      if (nextToken) {
        setNextToken(result.data.commentsByDate.nextToken);
      } else {
        setNextToken(null);
      }
      setCurrentPage(currentPage + 1);
    });
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault();

    if (comment) {
      const commentAsset = {
        input: {
          vodAssetId: filmID,
          content: comment,
          name: user.attributes.name,
          picture: user.attributes.picture,
        },
      };

      await API.graphql(graphqlOperation(createComment, commentAsset))
        .then(() => {
          setComment("");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const showComments = () => {
    if (comments.length !== 0) {
      return (
        <div className="mt-4">
          <div className="d-flex justify-content-between">
            <Paginate
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              nextToken={nextToken}
              handleNext={handleNext}
            />
            <p className="text-start mb-0 align-self-center">
              {currentRecords.length === 1
                ? `${currentRecords.length} Comment`
                : `${currentRecords.length} Comments`}
            </p>
          </div>
          {currentRecords.map((value, index) => {
            return (
              <Comment
                s3url={s3url}
                key={index}
                data={value}
                username={user.username}
                groups={groups}
              />
            );
          })}
        </div>
      );
    }
    return <p className="mt-4">No Comments Yet!</p>;
  };

  return (
    <div className="mt-2">
      <h3 className="text-start">Leave a Comment</h3>
      <Form onSubmit={handleCommentSubmit} id="comment-submit">
        <Form.Control
          as="textarea"
          rows={4}
          value={comment}
          onChange={handleChange}
          name="comment"
          placeholder="Add Comment"
          className="mt-2"
        />
        <div className="text-end pt-3">
          <Button
            variant="grad"
            form="comment-submit"
            type="submit"
            disabled={comment === ""}
          >
            Post Comment
          </Button>
        </div>
      </Form>
      {showComments()}
    </div>
  );
}

export default Comments;
