import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import SignUp from "../SignUp/SignUp";
import Main from "./Main/Main";
import EmailVerify from "./EmailVerify/EmailVerify";
import PlaceholderPage from "../PlaceholderPage/PlaceholderPage";
import ScrollToTop from "./ScrollToTop";

import { Amplify, Auth, Hub } from "aws-amplify";

import awsExports from "../../aws-exports";
import NotificationContext from "../../context/NotificationContext";
import AccessPage from "./AccessPage";

Amplify.configure(awsExports);

function App() {
  const notificationCtx = useContext(NotificationContext);

  const [signInLoading, setSignInLoading] = useState(false);
  const [formState, setFormState] = useState("placeholderPage");
  const [userState, setUserState] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const onAppLoad = async () => {
      const user = await Auth.currentAuthenticatedUser().catch((err) => {
        console.log(err);
      });

      if (user) {
        setUserState(user);
        setFormState("signedIn");
      } else {
        setFormState("signUp");
      }
    };

    onAppLoad();

    const getUser = async () => {
      return await Auth.currentAuthenticatedUser();
    };

    Hub.listen("auth", ({ payload }) => {
      if (payload.event === "updateUserAttributes") {
        getUser().then((res) => {
          setUserState(res);
        });
      }
    });
  }, []);

  const signIn = async (email, password) => {
    setSignInLoading(true);
    try {
      await Auth.signIn(email, password).then((res) => {
        setUserState(res);
        setFormState("signedIn");
        setSignInLoading(false);
      });
    } catch (err) {
      const str = err.toString();

      if (str.includes("UserNotConfirmedException")) {
        await Auth.resendSignUp(email).then(() => {
          setFormState("confirmSignUp");
          setUserState(email);
        });
      } else {
        notificationCtx.error(str.substring(str.indexOf(":") + 2));
        setSignInLoading(false);
      }
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut().then(() => {
        setUserState(null);
        setFormState("signUp");
        navigate("/");
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const showApp = () => {
    switch (formState) {
      case "placeholderPage":
        return <PlaceholderPage />;
      case "signUp":
        return (
          <AccessPage signIn={signIn} signInLoading={signInLoading} />
          // <SignUp
          //   signIn={signIn}
          //   signInLoading={signInLoading}
          //   setFormState={setFormState}
          //   setUserState={setUserState}
          // />
        );
      case "confirmSignUp":
        return <EmailVerify email={userState} signIn={signIn} />;
      case "signedIn":
        return (
          <>
            {userState && (
              <Main
                signOut={signOut}
                user={userState}
                setUserState={setUserState}
              />
            )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className="App">
      <ScrollToTop />
      {showApp()}
    </div>
  );
}

export default App;
