import React, { useEffect, useState } from "react";
import {
  Modal,
  Col,
  Card,
  Row,
  Button,
  Container,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import useCapitalize from "../../../Hooks/useCapitalize";
import { listVodAssets } from "../../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { useDebounce } from "@uidotdev/usehooks";

function AddPlaylistPopover(props) {
  const {
    addPlaylistToggle,
    hidePlaylistModal,
    addFilm,
    selectedPlaylist,
    s3url,
  } = props;

  const caps = useCapitalize;

  const [films, setFilms] = useState([]);

  const [recFilms, setRecFilms] = useState([]);

  const [formState, setFormState] = useState("");
  const debouncedSearchTerm = useDebounce(formState.toLowerCase(), 300);

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const getFilms = async () => {
      const variables = {
        filter: {
          specialFeature: {
            ne: true,
          },
        },
      };

      return await API.graphql(graphqlOperation(listVodAssets, variables));
    };

    getFilms().then((res) => {
      setFilms(res.data.listVodAssets.items);
    });
  }, []);

  const availableFilms = films.flatMap((film) =>
    selectedPlaylist.films.items.some((item) => item.vodAssetID === film.id)
      ? []
      : film
  );

  useEffect(() => {
    const search = () => {
      setSearchResults([]);

      if (debouncedSearchTerm?.trim()) {
        setSearchResults(
          availableFilms.filter((item) =>
            item.title.includes(debouncedSearchTerm)
          )
        );
      }
    };

    search();
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    recFilms.forEach((film) => {
      if (
        selectedPlaylist.films.items.some((item) => item.vodAssetID === film.id)
      ) {
        setRecFilms(shuffle(availableFilms).slice(0, 3));
      }
    });
    searchResults.forEach((film) => {
      if (
        selectedPlaylist.films.items.some((item) => item.vodAssetID === film.id)
      ) {
        setSearchResults((prevState) => {
          return prevState.filter((item) => item.id !== film.id);
        });
      }
    });
  }, [selectedPlaylist]); // eslint-disable-line react-hooks/exhaustive-deps

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const onEnter = async () => {
    setRecFilms(shuffle(availableFilms).slice(0, 3));
  };

  const handleClick = (value) => {
    addFilm(value);
  };

  const handleChange = (event) => {
    setFormState(event.target.value);
  };

  return (
    <>
      <Modal
        contentClassName="bg-dark"
        show={addPlaylistToggle}
        onHide={hidePlaylistModal}
        scrollable
        size={"xl"}
        onEnter={onEnter}
      >
        <Modal.Header closeButton className="text-white" closeVariant="white">
          <Modal.Title>Add to Playlist - {selectedPlaylist.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form>
                  <Form.Floating id="floatingInputCustom">
                    <Form.Control value={formState} onChange={handleChange} />
                    <label htmlFor="floatingInputCustom">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </label>
                  </Form.Floating>
                </Form>
                <>
                  {searchResults.length === 0 ? (
                    <>
                      <h4 className="mt-3 text-center text-white">
                        Start Typing to See Results
                      </h4>
                      <Row>
                        <div className="w-auto text-white">
                          <h1 className="d-inline-block">Recommended Films</h1>
                          <hr
                            className="mt-1 opacity-100 w-75"
                            style={{ borderTop: "8px solid" }}
                          />
                        </div>
                      </Row>
                      <Row className="d-flex flex-column flex-fill flex-md-row">
                        {recFilms.map((vodAsset, index) => {
                          return (
                            <Col key={index} className="col-md-4 my-4">
                              <Card className="h-100 text-center">
                                <Card.Img
                                  variant="top"
                                  src={`${s3url}${vodAsset.cardImage}`}
                                  style={{ "--width": "100%" }}
                                />

                                <Card.Body>
                                  <Card.Title style={{ fontSize: "30px" }}>
                                    {caps(vodAsset.title)}
                                  </Card.Title>
                                  <Card.Text style={{ fontSize: "20px" }}>
                                    <b>Genre:</b> {vodAsset.genre}
                                  </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                  <Button
                                    variant="grad"
                                    onClick={() => handleClick(vodAsset)}
                                  >
                                    Add Film
                                  </Button>
                                </Card.Footer>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  ) : (
                    <Row className="d-flex flex-column flex-fill flex-md-row">
                      {searchResults.map((vodAsset, index) => {
                        return (
                          <Col key={index} className="col-md-4 my-4">
                            <Card className="h-100 text-center">
                              <Card.Img
                                variant="top"
                                src={`${s3url}${vodAsset.cardImage}`}
                                style={{ "--width": "100%" }}
                              />

                              <Card.Body>
                                <Card.Title style={{ fontSize: "30px" }}>
                                  {caps(vodAsset.title)}
                                </Card.Title>
                                <Card.Text style={{ fontSize: "20px" }}>
                                  <b>Genre:</b> {vodAsset.genre}
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer>
                                <Button
                                  variant="grad"
                                  onClick={() => handleClick(vodAsset)}
                                >
                                  Add Film
                                </Button>
                              </Card.Footer>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddPlaylistPopover;
