function useCapitalize(string) {
  const excludeWords = ["of", "the", "and"];
  return string
    .toLowerCase()
    .split(" ")
    .map((word, i) =>
      excludeWords.includes(word) && i !== 0
        ? [word]
        : word.includes("#")
        ? word.charAt(0).toUpperCase() +
          word.substring(1, word.indexOf("#") - 1) +
          word.charAt(word.indexOf("#") - 1).toUpperCase() +
          word.substring(word.indexOf("#") + 1)
        : word.charAt(0).toUpperCase() + word.substring(1)
    )
    .join(" ");
}

export default useCapitalize;
