/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDirector = /* GraphQL */ `
  mutation CreateDirector(
    $input: CreateDirectorInput!
    $condition: ModelDirectorConditionInput
  ) {
    createDirector(input: $input, condition: $condition) {
      id
      name
      bio
      location
      image
      homePage
      videos {
        items {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDirector = /* GraphQL */ `
  mutation UpdateDirector(
    $input: UpdateDirectorInput!
    $condition: ModelDirectorConditionInput
  ) {
    updateDirector(input: $input, condition: $condition) {
      id
      name
      bio
      location
      image
      homePage
      videos {
        items {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDirector = /* GraphQL */ `
  mutation DeleteDirector(
    $input: DeleteDirectorInput!
    $condition: ModelDirectorConditionInput
  ) {
    deleteDirector(input: $input, condition: $condition) {
      id
      name
      bio
      location
      image
      homePage
      videos {
        items {
          id
          title
          director
          directorID
          description
          year
          runtime
          genre
          star1
          star2
          headerImage
          cardImage
          poster
          specialFeature
          specialFeatureVideo
          specialFeaturePhoto
          homePage
          createdAt
          updatedAt
          vodAssetVideoId
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVodAsset = /* GraphQL */ `
  mutation CreateVodAsset(
    $input: CreateVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    createVodAsset(input: $input, condition: $condition) {
      id
      title
      director
      directorID
      description
      year
      runtime
      genre
      star1
      star2
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      headerImage
      cardImage
      poster
      specialFeature
      specialFeatureVideo
      specialFeatureText {
        items {
          id
          filmID
          filmTitle
          title
          author
          body
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      specialFeaturePhoto
      homePage
      playlists {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vodAssetVideoId
      __typename
    }
  }
`;
export const updateVodAsset = /* GraphQL */ `
  mutation UpdateVodAsset(
    $input: UpdateVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    updateVodAsset(input: $input, condition: $condition) {
      id
      title
      director
      directorID
      description
      year
      runtime
      genre
      star1
      star2
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      headerImage
      cardImage
      poster
      specialFeature
      specialFeatureVideo
      specialFeatureText {
        items {
          id
          filmID
          filmTitle
          title
          author
          body
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      specialFeaturePhoto
      homePage
      playlists {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vodAssetVideoId
      __typename
    }
  }
`;
export const deleteVodAsset = /* GraphQL */ `
  mutation DeleteVodAsset(
    $input: DeleteVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    deleteVodAsset(input: $input, condition: $condition) {
      id
      title
      director
      directorID
      description
      year
      runtime
      genre
      star1
      star2
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      headerImage
      cardImage
      poster
      specialFeature
      specialFeatureVideo
      specialFeatureText {
        items {
          id
          filmID
          filmTitle
          title
          author
          body
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      specialFeaturePhoto
      homePage
      playlists {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vodAssetVideoId
      __typename
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      label
      films {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      directors {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      label
      films {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      directors {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      label
      films {
        items {
          id
          vodAssetID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      directors {
        items {
          id
          directorID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFilmText = /* GraphQL */ `
  mutation CreateFilmText(
    $input: CreateFilmTextInput!
    $condition: ModelFilmTextConditionInput
  ) {
    createFilmText(input: $input, condition: $condition) {
      id
      filmID
      filmTitle
      title
      author
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFilmText = /* GraphQL */ `
  mutation UpdateFilmText(
    $input: UpdateFilmTextInput!
    $condition: ModelFilmTextConditionInput
  ) {
    updateFilmText(input: $input, condition: $condition) {
      id
      filmID
      filmTitle
      title
      author
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFilmText = /* GraphQL */ `
  mutation DeleteFilmText(
    $input: DeleteFilmTextInput!
    $condition: ModelFilmTextConditionInput
  ) {
    deleteFilmText(input: $input, condition: $condition) {
      id
      filmID
      filmTitle
      title
      author
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createVideoObject = /* GraphQL */ `
  mutation CreateVideoObject(
    $input: CreateVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    createVideoObject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVideoObject = /* GraphQL */ `
  mutation UpdateVideoObject(
    $input: UpdateVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    updateVideoObject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVideoObject = /* GraphQL */ `
  mutation DeleteVideoObject(
    $input: DeleteVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    deleteVideoObject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCarouselImage = /* GraphQL */ `
  mutation CreateCarouselImage(
    $input: CreateCarouselImageInput!
    $condition: ModelCarouselImageConditionInput
  ) {
    createCarouselImage(input: $input, condition: $condition) {
      id
      image
      directorID
      directorName
      filmID
      title
      type
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCarouselImage = /* GraphQL */ `
  mutation UpdateCarouselImage(
    $input: UpdateCarouselImageInput!
    $condition: ModelCarouselImageConditionInput
  ) {
    updateCarouselImage(input: $input, condition: $condition) {
      id
      image
      directorID
      directorName
      filmID
      title
      type
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCarouselImage = /* GraphQL */ `
  mutation DeleteCarouselImage(
    $input: DeleteCarouselImageInput!
    $condition: ModelCarouselImageConditionInput
  ) {
    deleteCarouselImage(input: $input, condition: $condition) {
      id
      image
      directorID
      directorName
      filmID
      title
      type
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlaylist = /* GraphQL */ `
  mutation CreatePlaylist(
    $input: CreatePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    createPlaylist(input: $input, condition: $condition) {
      id
      name
      films {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePlaylist = /* GraphQL */ `
  mutation UpdatePlaylist(
    $input: UpdatePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    updatePlaylist(input: $input, condition: $condition) {
      id
      name
      films {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePlaylist = /* GraphQL */ `
  mutation DeletePlaylist(
    $input: DeletePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    deletePlaylist(input: $input, condition: $condition) {
      id
      name
      films {
        items {
          id
          vodAssetID
          playlistID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTimeCode = /* GraphQL */ `
  mutation CreateTimeCode(
    $input: CreateTimeCodeInput!
    $condition: ModelTimeCodeConditionInput
  ) {
    createTimeCode(input: $input, condition: $condition) {
      id
      filmID
      time
      type
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const updateTimeCode = /* GraphQL */ `
  mutation UpdateTimeCode(
    $input: UpdateTimeCodeInput!
    $condition: ModelTimeCodeConditionInput
  ) {
    updateTimeCode(input: $input, condition: $condition) {
      id
      filmID
      time
      type
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const deleteTimeCode = /* GraphQL */ `
  mutation DeleteTimeCode(
    $input: DeleteTimeCodeInput!
    $condition: ModelTimeCodeConditionInput
  ) {
    deleteTimeCode(input: $input, condition: $condition) {
      id
      filmID
      time
      type
      updatedAt
      createdAt
      owner
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      answer {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      questionAnswerId
      owner
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      answer {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      questionAnswerId
      owner
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      vodAssetId
      content
      picture
      name
      createdAt
      answer {
        id
        content
        picture
        name
        vodAssetId
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      questionAnswerId
      owner
      __typename
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      content
      picture
      name
      vodAssetId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      content
      picture
      name
      vodAssetId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      content
      picture
      name
      vodAssetId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDirectorTags = /* GraphQL */ `
  mutation CreateDirectorTags(
    $input: CreateDirectorTagsInput!
    $condition: ModelDirectorTagsConditionInput
  ) {
    createDirectorTags(input: $input, condition: $condition) {
      id
      directorID
      tagID
      director {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDirectorTags = /* GraphQL */ `
  mutation UpdateDirectorTags(
    $input: UpdateDirectorTagsInput!
    $condition: ModelDirectorTagsConditionInput
  ) {
    updateDirectorTags(input: $input, condition: $condition) {
      id
      directorID
      tagID
      director {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDirectorTags = /* GraphQL */ `
  mutation DeleteDirectorTags(
    $input: DeleteDirectorTagsInput!
    $condition: ModelDirectorTagsConditionInput
  ) {
    deleteDirectorTags(input: $input, condition: $condition) {
      id
      directorID
      tagID
      director {
        id
        name
        bio
        location
        image
        homePage
        videos {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlaylistFilms = /* GraphQL */ `
  mutation CreatePlaylistFilms(
    $input: CreatePlaylistFilmsInput!
    $condition: ModelPlaylistFilmsConditionInput
  ) {
    createPlaylistFilms(input: $input, condition: $condition) {
      id
      vodAssetID
      playlistID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      playlist {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePlaylistFilms = /* GraphQL */ `
  mutation UpdatePlaylistFilms(
    $input: UpdatePlaylistFilmsInput!
    $condition: ModelPlaylistFilmsConditionInput
  ) {
    updatePlaylistFilms(input: $input, condition: $condition) {
      id
      vodAssetID
      playlistID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      playlist {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePlaylistFilms = /* GraphQL */ `
  mutation DeletePlaylistFilms(
    $input: DeletePlaylistFilmsInput!
    $condition: ModelPlaylistFilmsConditionInput
  ) {
    deletePlaylistFilms(input: $input, condition: $condition) {
      id
      vodAssetID
      playlistID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      playlist {
        id
        name
        films {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFilmTags = /* GraphQL */ `
  mutation CreateFilmTags(
    $input: CreateFilmTagsInput!
    $condition: ModelFilmTagsConditionInput
  ) {
    createFilmTags(input: $input, condition: $condition) {
      id
      vodAssetID
      tagID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFilmTags = /* GraphQL */ `
  mutation UpdateFilmTags(
    $input: UpdateFilmTagsInput!
    $condition: ModelFilmTagsConditionInput
  ) {
    updateFilmTags(input: $input, condition: $condition) {
      id
      vodAssetID
      tagID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFilmTags = /* GraphQL */ `
  mutation DeleteFilmTags(
    $input: DeleteFilmTagsInput!
    $condition: ModelFilmTagsConditionInput
  ) {
    deleteFilmTags(input: $input, condition: $condition) {
      id
      vodAssetID
      tagID
      vodAsset {
        id
        title
        director
        directorID
        description
        year
        runtime
        genre
        star1
        star2
        video {
          id
          createdAt
          updatedAt
          __typename
        }
        headerImage
        cardImage
        poster
        specialFeature
        specialFeatureVideo
        specialFeatureText {
          nextToken
          __typename
        }
        specialFeaturePhoto
        homePage
        playlists {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        vodAssetVideoId
        __typename
      }
      tag {
        id
        label
        films {
          nextToken
          __typename
        }
        directors {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
