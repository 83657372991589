import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

function PlaylistBreadcrumbs() {
  return (
    <div>
      <Breadcrumb className="d-flex justify-content-center breadcrumb-color">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active style={{ color: "#9f1f63" }}>
          Playlists
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default PlaylistBreadcrumbs;
