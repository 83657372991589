import React from "react";
import FilmPhotoCarousel from "./FilmPhotoCarousel";

function SpecialFeatureImage(props) {
  const { specialFeatureImages, s3url } = props;

  const showSpecialFeatureImage = () => {
    if (specialFeatureImages) {
      return <FilmPhotoCarousel s3url={s3url} photos={specialFeatureImages} />;
    }
  };

  return <>{showSpecialFeatureImage()}</>;
}

export default SpecialFeatureImage;
