import React from "react";
import NavBar from "../../NavBar/NavBar";
import Router from "../../Router/Router";
import Footer from "../../Footer/Footer";

function Main(props) {
  const { signOut, user, setUserState } = props;

  const s3url =
    "https://irisapp83dbb4e4b1244daa93ff44b308b16567124246-dev.s3.us-west-2.amazonaws.com/public/";

  return (
    <>
      <NavBar s3url={s3url} signOut={signOut} user={user} />
      <Router s3url={s3url} user={user} setUserState={setUserState} />
      <Footer />
    </>
  );
}

export default Main;
