import React, { useState } from "react";
import { Button, OverlayTrigger, Popover, Form, Image } from "react-bootstrap";
import { deleteComment, updateComment } from "../../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

function Comment(props) {
  const { s3url, username, data, groups } = props;

  const { createdAt, content, picture, name, owner, id } = data;

  const [editable, setEditable] = useState(false);
  const [newComment, setNewComment] = useState(content);
  const [popoverToggle, setPopoverToggle] = useState(false);

  const createdDate = new Date(createdAt);

  const deleteItem = async () => {
    setPopoverToggle(false);
    await API.graphql(
      graphqlOperation(deleteComment, {
        input: {
          id: id,
        },
      })
    );
  };

  const handleChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedComment = {
      input: {
        id: id,
        content: newComment,
      },
    };
    await API.graphql(graphqlOperation(updateComment, updatedComment)).then(
      () => {
        setEditable(false);
      }
    );
  };

  const showContent = () => {
    if (!editable) {
      return <p className="text-start mt-2">{content}</p>;
    }
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Control
          as="textarea"
          rows={4}
          value={newComment}
          onChange={handleChange}
          name="comment"
          className="mt-2"
        />
        <div className="pt-2 d-flex justify-content-between">
          <Button variant="grad" size="sm" type="submit">
            Repost
          </Button>
          <Button variant="grad" size="sm" onClick={() => setEditable(false)}>
            Cancel
          </Button>
        </div>
      </Form>
    );
  };

  const toggleEdit = () => {
    setPopoverToggle(false);
    setEditable(!editable);
  };

  const togglePopover = () => {
    setPopoverToggle(!popoverToggle);
  };

  const showOptions = () => {
    if (username === owner || groups) {
      return (
        <OverlayTrigger
          trigger="click"
          placement="left"
          show={popoverToggle}
          overlay={
            <Popover>
              <Popover.Body as={Button} variant="link" onClick={toggleEdit}>
                Edit
              </Popover.Body>
              <Popover.Body as={Button} variant="link" onClick={deleteItem}>
                Delete
              </Popover.Body>
            </Popover>
          }
        >
          <Button onClick={togglePopover} variant="outline-light" size="sm">
            . . .
          </Button>
        </OverlayTrigger>
      );
    }
  };

  return (
    <div>
      <hr />
      <div className="d-flex">
        <Image
          style={{
            objectFit: "cover",
            borderRadius: "50%",
            border: "3px solid #FFFFFF",
            width: "50px",
            height: "50px",
          }}
          src={`${s3url}${picture}`}
        />
        <div className="ms-2 my-auto text-start">
          <h5 className="mb-0 ">{name}</h5>
          <p className="opacity-50 mb-0 " style={{ fontSize: "0.8rem" }}>
            {createdDate.toLocaleDateString("en-US", {
              month: "long",
              year: "numeric",
              day: "2-digit",
            })}
          </p>
        </div>
        <div className="ms-auto">{showOptions()}</div>
      </div>
      {showContent()}
    </div>
  );
}

export default Comment;
