import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createTag } from "../../../graphql/mutations";
import { Modal, Form, Button } from "react-bootstrap";

function TagsPopover(props) {
  const { show, label, setLabel, hidePopover } = props;

  const [creating, setCreating] = useState(false);

  const handleChange = (event) => {
    setLabel(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const variables = {
      input: {
        label: label,
      },
    };

    setCreating(true);

    await API.graphql(graphqlOperation(createTag, variables)).then(() => {
      hidePopover();
      setCreating(false);
    });
  };

  return (
    <Modal centered show={show} onHide={hidePopover}>
      <Modal.Header closeButton>Create New Tag</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} id="tag-form">
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control onChange={handleChange} value={label} name="label" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="text-end">
        <Button
          type="submit"
          form="tag-form"
          variant="dark"
          size="lg"
          disabled={label === "" || creating}
        >
          {creating ? "Creating..." : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TagsPopover;
