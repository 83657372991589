import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, CloseButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import PlayAllModal from "./PlayAllModal/PlayAllModal";
import SinglePlayModal from "./SinglePlayModal/SinglePlayModal";
import awsvideo from "../../../../aws-video-exports";
import "./DisplayPlaylist.css";
import useCapitalize from "../../../Hooks/useCapitalize";
import { API, graphqlOperation } from "aws-amplify";
import { getVodAsset } from "../../../../graphql/queries";

function DisplayPlaylist(props) {
  const { showPlaylistModal, films, deleteFilm, s3url } = props;
  const [filmDetails, setFilmDetails] = useState([]);
  const [displayingFilms, setDisplayingFilms] = useState(false);
  const [displayingSingleFilm, setDisplayingSingleFilm] = useState(false);
  const [singleFilmSources, setSingleFilmSources] = useState([]);

  useEffect(() => {
    Promise.all(
      films.map(async (film) => {
        return await API.graphql(
          graphqlOperation(getVodAsset, { id: film.vodAssetID })
        );
      })
    )
      .then((values) => {
        const filmData = values.map((item) => item.data.getVodAsset);
        setFilmDetails(filmData);
      })
      .catch((err) => console.log(err));
  }, [films]);

  const caps = useCapitalize;

  const showFilms = () => {
    setDisplayingFilms(true);
  };

  const hideFilms = () => {
    setDisplayingFilms(false);
  };

  const showSingleFilm = (film) => {
    const sources = [
      {
        src: `https://${awsvideo.awsOutputVideo}/${film.video.id}/${film.video.id}.m3u8`,
        type: "application/x-mpegURL",
      },
    ];

    setSingleFilmSources(sources);
    setDisplayingSingleFilm(true);
  };

  const hideSingleFilm = () => {
    setDisplayingSingleFilm(false);
  };

  const renderCards = () => {
    return (
      <>
        {filmDetails.length > 0 &&
          filmDetails.map((film) => {
            return (
              <Col className="col-md-4 my-4" key={film.id}>
                <Card className="h-100">
                  <Card.Img variant="top" src={`${s3url}${film.cardImage}`} />
                  <div className="d-flex justify-content-end me-2 mt-1">
                    <CloseButton onClick={() => deleteFilm(film.id)} />
                  </div>
                  <Card.Body className="text-dark pt-0">
                    <Card.Title style={{ fontSize: "20px" }}>
                      {caps(film.title)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "13px" }}>
                      <b>Genre:</b> {film.genre}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-flex justify-content-around">
                      <Button
                        onClick={() => showSingleFilm(film)}
                        variant="grad"
                      >
                        Watch Now
                      </Button>
                      <Button
                        as={Link}
                        state={{
                          id: film.id,
                          title: film.title,
                          name: film.director,
                          directorId: film.directorID,
                        }}
                        to={`/filmmaker/${film.director
                          .replaceAll("#", "")
                          .replaceAll(" ", "-")
                          .toLowerCase()}/film/${film.title
                          .replaceAll(" ", "-")
                          .toLowerCase()}`}
                        variant="grad"
                      >
                        Film Page
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            );
          })}
      </>
    );
  };

  return (
    <>
      {filmDetails.length === 0 ? (
        <>
          <h5>No items yet! Click below to add to your playlist.</h5>
          <Button variant="outline-light" onClick={showPlaylistModal}>
            Add to Playlist
          </Button>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-start">
            <Button
              variant="outline-light"
              className="me-2"
              onClick={showFilms}
            >
              Play All
            </Button>
            <PlayAllModal
              films={filmDetails}
              displayingFilms={displayingFilms}
              hideFilms={hideFilms}
              s3url={s3url}
            />
            <Button variant="outline-light" onClick={showPlaylistModal}>
              Add to Playlist
            </Button>
          </div>
          <Row className="d-flex flex-column flex-fill flex-md-row">
            {renderCards()}
          </Row>
        </>
      )}
      <SinglePlayModal
        singleFilmSources={singleFilmSources}
        displayingSingleFilm={displayingSingleFilm}
        hideSingleFilm={hideSingleFilm}
      />
    </>
  );
}

export default DisplayPlaylist;
